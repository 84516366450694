import format from 'date-fns/format';

// yyyy-MM-dd-hh-mm-ss
// e.g. 2023-10-31-21-01-35
export const humanReadableDateAndTimeWithDashes = (): string => {
  return format(new Date(), 'yyyy-MM-dd-hh-mm-ss').toString();
};

// e.g. 2023/10/31
export const humanReadableDateWithSlashes = (): string => {
  return format(new Date(), 'yyyy/MM/dd').toString();
};
