import { useEffect, useMemo } from 'react';

import { Box, Select, SelectContent, SelectTrigger } from '@eluve/components';
import {
  PromptTemplateSelectItem,
  usePromptTemplates,
} from '@eluve/frontend-feature-user-settings';
import { isLlmOutputDynamicOutput } from '@eluve/graphql-types';
import { useNamedLogger } from '@eluve/logger';

interface TemplateSelectorProps {
  selectedPromptTemplateId?: string;
  selectedOutputTemplateId?: string;
  onSelectionChanged: (data: {
    initialPromptTemplateId: string;
    initialOutputTemplateId: string | null;
  }) => void;
}

export const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  selectedPromptTemplateId,
  selectedOutputTemplateId,
  onSelectionChanged,
}) => {
  const {
    activeOutputTemplates: outputTemplates,
    activeSOAPPromptTemplates: promptTemplates,
    defaultPromptTemplateId,
    defaultOutputTemplateId,
    userSettingsOutputTemplateId,
  } = usePromptTemplates();
  const logger = useNamedLogger('TemplateSelector');

  // On load, select the defaults
  useEffect(
    function setDefaultsWhenEmpty() {
      let areChangesRequired = false;
      const promptTemplate = promptTemplates.find(
        (o) => o.id === selectedPromptTemplateId,
      );
      let initialPromptTemplateId = promptTemplate?.id ?? null;
      const outputTemplate = outputTemplates.find(
        (o) => o.id === selectedOutputTemplateId,
      );
      let initialOutputTemplateId = outputTemplate?.id ?? null;

      if (defaultPromptTemplateId && !selectedPromptTemplateId) {
        areChangesRequired = true;
        initialPromptTemplateId = defaultPromptTemplateId;
      }
      const newTemplateOutputType = promptTemplates.find(
        (template) => template.id === initialPromptTemplateId,
      )?.outputType;

      const isDynamicOutput = isLlmOutputDynamicOutput(newTemplateOutputType);

      if (!isDynamicOutput && selectedOutputTemplateId) {
        areChangesRequired = true;
        initialOutputTemplateId = null;
      }
      if (
        isDynamicOutput &&
        !initialOutputTemplateId &&
        defaultOutputTemplateId
      ) {
        areChangesRequired = true;
        initialOutputTemplateId = defaultOutputTemplateId;
      }
      if (areChangesRequired && !initialPromptTemplateId) {
        logger.error('No prompt template selected');
      }

      if (areChangesRequired && initialPromptTemplateId) {
        onSelectionChanged({
          initialPromptTemplateId,
          initialOutputTemplateId,
        });
      }
    },
    [
      logger,
      promptTemplates,
      outputTemplates,
      defaultOutputTemplateId,
      defaultPromptTemplateId,
      selectedPromptTemplateId,
      userSettingsOutputTemplateId,
      selectedOutputTemplateId,
      onSelectionChanged,
    ],
  );

  const selectedPromptTemplate = useMemo(
    () =>
      promptTemplates.find(
        (template) => template.id === selectedPromptTemplateId,
      ),
    [selectedPromptTemplateId, promptTemplates],
  );

  const selectedOutputTemplate = useMemo(
    () =>
      outputTemplates.find(
        (template) => template.id === selectedOutputTemplateId,
      ),
    [outputTemplates, selectedOutputTemplateId],
  );

  const selectedTemplateOutputType = selectedPromptTemplate?.outputType ?? null;
  const fetchingTemplates = promptTemplates.length === 0;

  const handlePromptTemplateSelected = (promptTemplateId: string) => {
    const newTemplateOutputType = promptTemplates.find(
      (template) => template.id === promptTemplateId,
    )?.outputType;

    // auto select first output template if DYNAMIC prompt template is selected
    if (
      isLlmOutputDynamicOutput(newTemplateOutputType) &&
      outputTemplates.length > 0
    ) {
      const outputTemplateId =
        userSettingsOutputTemplateId ?? outputTemplates[0]!.id;
      onSelectionChanged({
        initialPromptTemplateId: promptTemplateId,
        initialOutputTemplateId: outputTemplateId,
      });
    } else {
      onSelectionChanged({
        initialPromptTemplateId: promptTemplateId,
        initialOutputTemplateId: null,
      });
    }
  };

  return (
    <Box vStack className="w-full">
      <Select
        value={selectedPromptTemplateId}
        onValueChange={handlePromptTemplateSelected}
      >
        <SelectTrigger>
          {fetchingTemplates
            ? 'Loading Templates...'
            : selectedPromptTemplate?.name ?? 'Select Prompt Template'}
        </SelectTrigger>
        <SelectContent>
          {promptTemplates?.map((template) => (
            <PromptTemplateSelectItem
              key={template.id}
              templateId={template.id ?? ''}
              templateName={template.name ?? ''}
              templateDescription={template.description}
            />
          ))}
        </SelectContent>
      </Select>

      {isLlmOutputDynamicOutput(selectedTemplateOutputType) &&
        outputTemplates.length > 1 && (
          <Select
            value={selectedOutputTemplateId}
            onValueChange={(outputTemplateId) => {
              onSelectionChanged({
                initialPromptTemplateId: selectedPromptTemplateId!,
                initialOutputTemplateId: outputTemplateId,
              });
            }}
          >
            <SelectTrigger>
              {(() => {
                if (fetchingTemplates) {
                  return 'Loading Output Templates...';
                }
                return selectedOutputTemplate?.name ?? 'Select Output Template';
              })()}
            </SelectTrigger>
            <SelectContent>
              {outputTemplates?.map((template) => (
                <PromptTemplateSelectItem
                  key={template.id}
                  templateId={template.id ?? ''}
                  templateName={template.name ?? ''}
                  templateDescription={null}
                />
              ))}
            </SelectContent>
          </Select>
        )}
    </Box>
  );
};
