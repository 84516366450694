import {
  Divider,
  HStack,
  Label,
  SectionCard,
  SectionCardContent,
  SectionCardHeader,
  VStack,
} from '@eluve/components';
import { useAppointmentId } from '@eluve/frontend-appointment-hooks';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { UploadAudioFileButton } from '../UploadAudioFileButton';

import { AddToTranscript } from './AddToTranscript';
import { LatestAppointmentTemplate } from './LatestAppointmentTemplate';
import { OpenInAdmin } from './OpenInAdmin';
import { RegenerateSummary } from './RegenerateSummary';
import { SimulateDegradedTranscription } from './SimulateDegradedTranscription';

export const EluveAdminPanel: React.FC = () => {
  const appointmentId = useAppointmentId();
  const tenantId = useTenantIdFromParams();

  return (
    <SectionCard>
      <SectionCardHeader>Eluve Admin Panel</SectionCardHeader>
      <SectionCardContent>
        <HStack wFull align="start" gap={6}>
          <VStack align="stretch" className="flex-1">
            <AddToTranscript appointmentId={appointmentId} />
            <Divider />
            <VStack>
              <Label className="px-1">Upload Audio File</Label>
              <UploadAudioFileButton appointmentId={appointmentId} />
            </VStack>
            <Divider />
            <LatestAppointmentTemplate appointmentId={appointmentId} />
          </VStack>
          <VStack align="stretch" className="flex-1">
            <Label>Misc. Actions</Label>
            <OpenInAdmin appointmentId={appointmentId} tenantId={tenantId!} />
            <RegenerateSummary
              appointmentId={appointmentId}
              tenantId={tenantId!}
            />
            <Divider />
            <SimulateDegradedTranscription />
          </VStack>
        </HStack>
      </SectionCardContent>
    </SectionCard>
  );
};
