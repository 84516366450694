import { CheckCircle2Icon } from 'lucide-react';
import React, { useState } from 'react';
import { toast } from 'sonner';

import {
  Button,
  Dialog,
  DialogContent,
  H2,
  NewButton,
  P,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useToast,
} from '@eluve/components';
import {
  useAppointmentId,
  useAppointmentPatient,
  useExternalAppointmentId,
  useSignNoteOnEluve,
} from '@eluve/frontend-appointment-hooks';
import { useCaptureEvent } from '@eluve/posthog-react';
import { useTenantIdFromParams } from '@eluve/session-helpers';

export const SignNoteOnEluveButton: React.FC = () => {
  const { toast: toaster } = useToast();
  const signNoteOnEluve = useSignNoteOnEluve();

  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);

  const tenantId = useTenantIdFromParams() ?? '';

  const appointmentId = useAppointmentId();
  const appointmentExternalId = useExternalAppointmentId();

  const captureEvent = useCaptureEvent({
    tenantId,
    appointmentId,
    externalAppointmentId: appointmentExternalId,
  });

  const patient = useAppointmentPatient();
  const patientId = patient?.id;

  const showSuccessToast = (text: string) => {
    toaster({
      duration: 4 * 1000, // 4 seconds
      variant: 'success',

      title: (
        <div className="flex w-full items-center">
          <CheckCircle2Icon className="mr-2 h-10 w-10" />
          <div className="flex flex-col gap-0.5">
            <span className="text-base font-semibold first-letter:capitalize">
              Note Signed Successfully
            </span>
            <span className="font-normal">{text}</span>
          </div>
        </div>
      ),
    });
  };

  const onButtonClick = async () => {
    // Open the dialog with the confirmation content
    setConfirmationDialogOpen(true);
  };

  const signNoteOnEluveOnly = async (): Promise<void> => {
    setConfirmationDialogOpen(false);
    const isSubmitted = await signNoteOnEluve();

    if (isSubmitted) {
      showSuccessToast(`Your note has been signed.`);
      captureEvent('signed_note_on_eluve_only');
    } else {
      captureEvent('failed_to_sign_note_on_eluve_only');
      toast.error('Failed to sign note.');
    }
  };

  const buttonDisabled = !patientId;

  return (
    <div className="w-full">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <NewButton
              disabled={buttonDisabled}
              onClick={onButtonClick}
              text="Sign Note"
              wFull
            />
          </TooltipTrigger>
          {buttonDisabled && (
            <TooltipContent>
              Select a patient before signing the note
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>

      <Dialog
        open={confirmationDialogOpen}
        onOpenChange={setConfirmationDialogOpen}
      >
        <DialogContent className="flex flex-col items-center justify-center gap-5 p-0">
          <div className="flex flex-col items-center gap-5 p-10">
            <H2>Are you sure?</H2>

            <P className="text-center font-medium text-gray-10">
              You will no longer be able to edit this note in Eluve.
            </P>
            <div className="flex w-full items-center justify-center gap-3">
              <Button
                className="border border-gray-5 bg-white text-gray-10 hover:bg-white"
                onClick={() => setConfirmationDialogOpen(false)}
              >
                Cancel
              </Button>

              <NewButton
                type="primary"
                onClick={() => signNoteOnEluveOnly()}
                text="Yes, Sign Note"
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
