import { ClassicSummary, DynamicSummary } from '@eluve/llm-outputs';

export type BackwardsCompatibleClassicSummary = {
  SUBJECTIVE?: string | null;
  OBJECTIVE?: string | null;
  ASSESSMENT?: string | null;
  PLAN?: string | null;
  ADDITIONAL_NOTES?: string | null;
};

export type DynamicSummaryWithAdditionalNotes = DynamicSummary & {
  ADDITIONAL_NOTES?: string | null;
};
export type ClassicSummaryWithAdditionalNotes = ClassicSummary & {
  ADDITIONAL_NOTES?: string | null;
};

export const isClassicSummary = (
  summary:
    | BackwardsCompatibleClassicSummary
    | DynamicSummaryWithAdditionalNotes
    | ClassicSummaryWithAdditionalNotes,
) => 'type' in summary && summary?.type === 'SOAP';

export const isDynamicSummary = (
  summary:
    | BackwardsCompatibleClassicSummary
    | DynamicSummaryWithAdditionalNotes
    | ClassicSummaryWithAdditionalNotes,
) => 'type' in summary && summary?.type === 'DYNAMIC';

export const isBackwardsCompatibleClassicSummary = (
  summary:
    | BackwardsCompatibleClassicSummary
    | DynamicSummaryWithAdditionalNotes
    | ClassicSummaryWithAdditionalNotes,
) => Object.keys(summary).includes('SUBJECTIVE');
