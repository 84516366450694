import { useLocalStorage } from '@uidotdev/usehooks';
import merge from 'lodash/merge';
import { useCallback, useEffect, useState } from 'react';

import { useUserIdFromSession } from '@eluve/session-helpers';
import { ConfigContract } from '@eluve/vendor';

import {
  isPostMessengerConnected,
  messageClient,
} from '../../utils/post-messenger';

const EHR_STATUS_DETAILS = 'EHR_STATUS_DETAILS';

export const useEhrStatusDetails = () => {
  const userId = useUserIdFromSession();
  const [storedData, setStoredData] = useLocalStorage<
    ConfigContract[typeof EHR_STATUS_DETAILS]
  >(`${EHR_STATUS_DETAILS}_${userId}`, {
    eluveDomains: {},
  });

  const [data, setData] = useState<ConfigContract[typeof EHR_STATUS_DETAILS]>(
    storedData ?? { eluveDomains: {} },
  );

  const updateData = (
    additionalValues: ConfigContract[typeof EHR_STATUS_DETAILS],
  ) => {
    const merged = merge(storedData, additionalValues);
    setStoredData(merged);
    setData(merged);
  };

  const refetch = useCallback(async () => {
    try {
      if (isPostMessengerConnected) {
        const ehrStatusResponse = await messageClient.config({
          get: {
            key: EHR_STATUS_DETAILS,
          },
        });
        if (ehrStatusResponse) {
          const combined = merge(storedData, ehrStatusResponse);
          setData(combined);
          setStoredData(combined);
        }
      }
    } catch (_) {
      // post-messenger is not connected yet.
      // This will be retried in the next interval
    }
  }, [setStoredData]);

  useEffect(() => {
    const interval = setInterval(refetch, 1000 * 3);
    return () => clearInterval(interval);
  }, [refetch]);

  return { data, refetch, updateData };
};
