import { useSubscription } from '@apollo/client';

import { graphql } from '@eluve/graphql.tada';

import { useAppointmentContext } from './appointment.context';
import { appointmentTranscriptAndSegmentsFragment } from './operations';

export const TranscriptAndSegmentsSubscription = graphql(
  `
    subscription CurrentTranscriptSubscription(
      $tenantId: uuid!
      $appointmentId: uuid!
    ) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        ...appointmentTranscriptionAndSegments
      }
    }
  `,
  [appointmentTranscriptAndSegmentsFragment],
);

export const useListenForTranscript = () => {
  const { tenantId, appointmentId } = useAppointmentContext();

  return useSubscription(TranscriptAndSegmentsSubscription, {
    variables: { tenantId, appointmentId },
    // These could be empty strings if the appointment if no appointment is currently active
    // See `TranscriptionMachineProvider`
    skip: !tenantId || !appointmentId,
  });
};
