import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const medicineContract = c.router(
  {
    searchMedicineInventory: {
      method: 'GET',
      path: '/search',
      query: z.object({
        keyword: z.string(),
      }),
      responses: {
        200: z.array(
          z.object({
            id: z.string(),
            name: z.string(),
            ingredients: z.string().optional(),
            rawData: z.record(z.unknown()).optional(),
          }),
        ),
      },
      summary: 'Search medicine inventory',
    },
  },
  {
    pathPrefix: '/:tenantId/medicine',
  },
);
