import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import {
  ColDefBuilder,
  DataTable,
  LinkButton,
  PageTitle,
  VStack,
} from '@eluve/components';
import { P } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const getTenantReviewersQuery = graphql(`
  query getTenantReviewers {
    tenantReviewers {
      __typename
      userId
      tenantId
      tenant {
        name
      }
      user {
        email
        firstName
        lastName
      }
      createdAt
    }
  }
`);

type TenantReviewersRow = {
  tenantId: string;
  tenant: string;
  userId: string;
  userEmail: string;
  user: string;
  addedAt: string;
};

export const ReviewersListPage: React.FC = () => {
  const { data } = useQuery(getTenantReviewersQuery);

  const tenantReviewerColumns = new ColDefBuilder<TenantReviewersRow>()
    .linkSortable('tenant', (row) => `/admin/tenants/${row.tenantId}`)
    .linkSortable('user', (row) => `/admin/users/${row.userId}`)
    .defaultSortable('userEmail', 'User Email')
    .dateSortable('addedAt', 'Added At')
    .colDef({
      header: 'Actions',
      cell: ({ row }) => (
        <LinkButton
          to={`/admin/tenants/${row.original.tenantId}/manage/reviewers`}
          text="Modify"
        />
      ),
    })
    .build();

  const rows = useMemo(() => {
    return (data?.tenantReviewers ?? []).map<TenantReviewersRow>((r) => ({
      tenantId: r.tenantId,
      tenant: r.tenant.name,
      userId: r.userId,
      userEmail: r.user.email,
      user: `${r.user.firstName} ${r.user.lastName}`,
      addedAt: r.createdAt,
    }));
  }, [data]);

  return (
    <VStack>
      <PageTitle>All Reviewers</PageTitle>

      <P>
        An overview of all users that have reviewer access and to which tenants
      </P>
      <DataTable
        data={rows}
        columns={tenantReviewerColumns}
        isPaginated={false}
        enableGlobalSearch
        isCompact
        initialSortingState={[
          {
            id: 'user',
            desc: false,
          },
        ]}
      />
    </VStack>
  );
};
