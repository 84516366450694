import { AppointmentTask } from '../types';

const getTasksKey = (tenantId: string) =>
  `PERSIST_APPOINTMENT_TASKS_${tenantId}`;

export const persistAppointmentTasks = (
  tenantId: string,
  files: AppointmentTask[],
) => {
  localStorage.setItem(getTasksKey(tenantId), JSON.stringify(files));
};

export const getPersistedTasks = (tenantId: string): AppointmentTask[] => {
  const tasksJson = localStorage.getItem(getTasksKey(tenantId));
  const tasks: AppointmentTask[] = tasksJson ? JSON.parse(tasksJson) : [];

  // It's possible we have serialized data that needs to be upgraded to the latest format.
  // Ensure that all tasks have the `isBackgroundUpload` property
  return tasks.map((task) => {
    if (task.type === 'TASK.UPLOAD_FILE') {
      return {
        ...task,
        // Assume that if background upload wasn't set that it shouldn't disrupt the user
        // if this task is run again
        isBackgroundUpload: task.isBackgroundUpload ?? true,
      };
    }

    // MARK_APPOINTMENT_AS_COMPLETE was renamed to GENERATE_NOTE
    // we must migrate any existing tasks to new event type
    // PR: https://github.com/eluve-inc/eluve-nx/pull/1655/commits/f6c9a40a2457d62e9865a7800efc7b4b73ab43ed
    if ((task.type as any) === 'TASK.MARK_APPOINTMENT_AS_COMPLETE') {
      return {
        ...task,
        type: 'TASK.GENERATE_NOTE',
        hasExistingSummary: false,
      };
    }

    return task;
  });
};
