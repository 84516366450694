import React from 'react';
import { match } from 'ts-pattern';

import { Tag, TagProps } from '@eluve/components';
import { AppointmentStatusEnum } from '@eluve/graphql-types';

export interface AppointmentStatusLabelProps {
  isSigned: boolean;
  isSummarized: boolean;
  status: AppointmentStatusEnum | null;
  size?: TagProps['size'];
}

const matchProps = (props: AppointmentStatusLabelProps) => {
  return match<
    AppointmentStatusLabelProps,
    { label: string; color: TagProps['color'] }
  >(props)
    .with({ isSigned: true }, () => ({
      label: 'Signed',
      color: 'default',
    }))
    .with({ isSummarized: true, isSigned: false }, () => ({
      label: 'Summary Available',
      color: 'purple',
    }))
    .with({ status: 'COMPLETED' }, () => ({
      label: 'Completed',
      color: 'orange',
    }))
    .with({ status: 'ACTIVE' }, () => ({
      label: 'Active',
      color: 'green',
    }))
    .with({ status: 'CANCELLED' }, () => ({
      label: 'Cancelled',
      color: 'yellow',
    }))
    .otherwise(() => ({
      label: 'Not Started',
      color: 'teal',
    }));
};

export const appointmentStatusAccessorFn = (
  props: AppointmentStatusLabelProps,
) => {
  return matchProps(props).label;
};

export const AppointmentStatusLabel: React.FC<AppointmentStatusLabelProps> = (
  props,
) => {
  const { size = 's' } = props;
  const { label, color } = matchProps(props);
  return <Tag color={color} label={label} size={size} />;
};
