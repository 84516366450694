import * as SheetPrimitive from '@radix-ui/react-dialog';
import { Slot } from '@radix-ui/react-slot';
import { PanelLeftOpenIcon } from 'lucide-react';
import React from 'react';
import { HTMLProps } from 'react';
import { useMedia } from 'react-use';

import { Button } from './button';
import { tv } from './cn';
import { createSidebarControl } from './createSidebarControl';
import { Sheet, SheetOverlay, SheetPortal } from './sheet';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from './tooltip';

const sidebarVariants = tv({
  slots: {
    base: 'flex h-full w-full flex-col self-stretch bg-mainBackground pb-2',
    nav: 'flex flex-col',
    item: 'group flex w-full cursor-pointer items-center gap-3 p-3',
    itemIcon:
      'flex size-10 items-center justify-center text-gray-10 group-hover:text-gray-12 group-active:text-gray-11',
    itemLabel: 'w-48 overflow-hidden truncate',
  },
  variants: {
    size: {
      collapsed: {
        base: 'max-w-16',
        item: 'justify-center',
        itemLabel: 'hidden',
      },
      expanded: {
        base: [
          'fixed inset-y-0 left-0 z-50 max-w-64 border-r shadow-lg',
          'transition ease-in-out',
          'data-[state=open]:animate-in data-[state=closed]:animate-out',
          'data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left',
          'data-[state=closed]:duration-300 data-[state=open]:duration-500',
        ],
        itemLabel: 'block flex-1',
      },
    },
  },
  defaultVariants: {
    size: 'collapsed',
  },
});

const { base, nav, item, itemIcon, itemLabel } = sidebarVariants();

interface LayoutProps extends HTMLProps<HTMLDivElement> {
  asChild?: boolean;
}

const useSidebarSize = () =>
  useMedia('(min-width: 768px)') ? 'collapsed' : 'expanded';

export const { useSidebar, useSidebarTrigger } = createSidebarControl();

const Sidebar = React.forwardRef<HTMLDivElement, LayoutProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const size = useSidebarSize();
    const Comp = asChild ? Slot : 'aside';

    const { isOpen, setSidebarOpen } = useSidebar();

    const sidebarContent = (
      <Comp className={base({ className, size })} ref={ref} {...props} />
    );

    if (size === 'collapsed') {
      return sidebarContent;
    }

    return (
      <Sheet open={isOpen} onOpenChange={setSidebarOpen}>
        <SheetPortal className="p-0">
          <SheetOverlay />
          <SheetPrimitive.Content asChild>
            {sidebarContent}
          </SheetPrimitive.Content>
        </SheetPortal>
      </Sheet>
    );
  },
);

const SidebarNav = React.forwardRef<HTMLDivElement, LayoutProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'nav';
    return <Comp className={nav({ className })} ref={ref} {...props} />;
  },
);

export interface SidebarNavItemProps extends LayoutProps {
  icon: React.ReactNode;
}

const SidebarNavItem = React.forwardRef<HTMLDivElement, SidebarNavItemProps>(
  ({ className, icon, children, asChild = false, ...props }, ref) => {
    const label = typeof children === 'string' ? children : '';
    const size = useSidebarSize();

    const Comp = asChild ? Slot : 'div';

    if (size !== 'expanded') {
      const navItem = (
        <Comp className={item({ className, size })} ref={ref} {...props}>
          <div className={itemIcon()} aria-label={label} role="link">
            {icon}
          </div>
        </Comp>
      );

      if (!children) {
        return navItem;
      }

      return (
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger>{navItem}</TooltipTrigger>
            <TooltipPortal>
              <TooltipContent side="right" sideOffset={-4}>
                {children}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return (
      <Comp className={item({ className, size })} ref={ref} {...props}>
        <div className={itemIcon()}>{icon}</div>
        <div className={itemLabel({ size })}>{children}</div>
      </Comp>
    );
  },
);

interface SidebarTriggerProps extends HTMLProps<HTMLButtonElement> {
  asChild?: boolean;
}

const SidebarTrigger = React.forwardRef<HTMLButtonElement, SidebarTriggerProps>(
  ({ asChild = true, children, ...props }, ref) => {
    const size = useSidebarSize();
    const { isVisible, setSidebarOpen } = useSidebarTrigger();

    if (size === 'collapsed' || !isVisible) {
      return null;
    }

    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        {...props}
        ref={ref}
        type="button"
        onClick={() => {
          setSidebarOpen((isOpen) => !isOpen);
        }}
      >
        {children ?? (
          <Button size="icon" variant="ghost">
            <PanelLeftOpenIcon />
          </Button>
        )}
      </Comp>
    );
  },
);

SidebarTrigger.displayName = 'SidebarTrigger';

const SidebarNavSpacer = () => <div className="flex-1" />;

/**
 * A common button that can be used in headers to trigger our side drawer nav
 */
const SidebarDefaultTrigger = () => (
  <SidebarTrigger asChild>
    <Button size="icon" variant="ghost">
      <PanelLeftOpenIcon />
    </Button>
  </SidebarTrigger>
);

export {
  Sidebar,
  SidebarNav,
  SidebarNavItem,
  SidebarTrigger,
  SidebarDefaultTrigger,
  SidebarNavSpacer,
};
