export const convertMarkdownToPlainText = (markdown: string): string => {
  // Replace headers (atx-style)
  markdown = markdown.replace(/^#{1,6}\s*(.+)$/gm, '$1');

  // Replace bold (**text** or __text__)
  markdown = markdown.replace(/\*\*(.+?)\*\*/g, '$1');
  markdown = markdown.replace(/__(.+?)__/g, '$1');

  // Replace italics (*text* or _text_)
  markdown = markdown.replace(/\*(.+?)\*/g, '$1');
  markdown = markdown.replace(/_(.+?)_/g, '$1');

  // Replace strikethroughs (~~text~~)
  markdown = markdown.replace(/~~(.+?)~~/g, '$1');

  // Replace inline code (`code`)
  markdown = markdown.replace(/`(.+?)`/g, '$1');

  // Remove code blocks (fenced code blocks)
  markdown = markdown.replace(/```[\s\S]*?```/g, '');

  // Remove images (![alt text](image_url))
  markdown = markdown.replace(/!\[.*?\]\(.*?\)/g, '');

  // Replace links [text](url) with 'text (url)'
  markdown = markdown.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '$1 ($2)');

  // Replace unordered list items (-, *, +) with '- '
  markdown = markdown.replace(/^\s*[*\-+]\s+/gm, '- ');

  // Replace blockquotes (> )
  markdown = markdown.replace(/^\s*>\s+/gm, '');

  // Remove horizontal rules (---, ***, ___)
  markdown = markdown.replace(/^\s*(-{3,}|\*{3,}|_{3,})\s*$/gm, '');

  // Remove HTML tags
  markdown = markdown.replace(/<\/?[^>]+(>|$)/g, '');

  // Replace multiple newlines with a single newline
  markdown = markdown.replace(/\n{2,}/g, '\n\n');

  // Trim leading and trailing whitespace
  return markdown.trim();
};
