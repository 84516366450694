import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'sonner';

import {
  eluveAdminHasuraContext,
  useCompleteFragment,
} from '@eluve/apollo-client';
import { Switch } from '@eluve/components';
import { FeatureFlagsEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';

import { adminTenantFeatureFlagFragment } from './gql-operations';

const setTenantFeatureFlagMutation = graphql(
  `
    mutation setTenantFeatureFlag(
      $tenantId: uuid!
      $flag: FeatureFlagsEnum!
      $isEnabled: Boolean!
    ) {
      insertTenantFeatureFlagsOne(
        object: { tenantId: $tenantId, flag: $flag, isEnabled: $isEnabled }
        onConflict: {
          constraint: tenant_feature_flags_pkey
          updateColumns: [isEnabled]
        }
      ) {
        ...AdminTenantFeatureFlag
      }
    }
  `,
  [adminTenantFeatureFlagFragment],
);

export interface TenantFeatureFlagToggleProps {
  tenantId: string;
  flag: FeatureFlagsEnum;
}

export const TenantFeatureFlagToggle: React.FC<
  TenantFeatureFlagToggleProps
> = ({ tenantId, flag }) => {
  const [updateFeatureFlag] = useMutation(setTenantFeatureFlagMutation, {
    onError: () => toast.error(`Failed to update access to feature ${flag}`),
    optimisticResponse: (data) => ({
      insertTenantFeatureFlagsOne: {
        __typename: 'TenantFeatureFlags' as const,
        updatedAt: new Date().toISOString(),
        ...data,
        featureFlag: {
          __typename: 'FeatureFlags' as const,
          name: flag,
          description: null,
        },
      },
    }),
  });

  const submit = async (isEnabled: boolean) => {
    await updateFeatureFlag({
      context: eluveAdminHasuraContext,
      variables: {
        tenantId,
        flag,
        isEnabled,
      },
    });
  };

  const data = useCompleteFragment({
    fragment: adminTenantFeatureFlagFragment,
    key: {
      flag,
      tenantId,
    },
    strict: false,
  });

  return (
    <Switch
      isCompact
      onCheckedChange={(isChecked) => submit(isChecked)}
      checked={data?.isEnabled ?? false}
    />
  );
};
