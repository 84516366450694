import { useRef } from 'react';
import { toast } from 'sonner';

import { Input, NewButton, NewButtonProps } from '@eluve/components';
import { useAppointmentStatus } from '@eluve/frontend-appointment-hooks';
import { AppointmentStatusTypesLookup } from '@eluve/graphql-types';
import { useNamedLogger } from '@eluve/logger';
import { useSetAppointmentStartedAt } from '@eluve/smart-blocks';
import {
  supportedAudioFormats,
  useAppointmentTasksActor,
  useAppointmentTasksSelector,
  useUserFileSystemStore,
} from '@eluve/user-local-files';

interface UploadAudioFileButtonProps {
  appointmentId: string;
  buttonSize?: NewButtonProps['size'];
}

export const UploadAudioFileButton: React.FC<UploadAudioFileButtonProps> = ({
  appointmentId,
  buttonSize = 'l',
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const logger = useNamedLogger(UploadAudioFileButton.name);

  const userFileSystem = useUserFileSystemStore(
    (state) => state.userFileSystem,
  );
  const { send } = useAppointmentTasksActor();
  const { setAppointmentStartedAt } = useSetAppointmentStartedAt();
  const appointmentStatus = useAppointmentStatus();

  const hasPendingAppointmentTask = useAppointmentTasksSelector(({ context }) =>
    context.tasks.some((t) => t.appointmentId === appointmentId),
  );

  const handleUploadFileButtonClick = () => {
    // open file dialog
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const uploadFile = async (file: File): Promise<void> => {
    const fileExtension = file.name.split('.').pop();

    const audioFormat = supportedAudioFormats.find(
      (audioFormat) => audioFormat.extension === fileExtension,
    );

    if (!audioFormat) {
      toast.error('Unsupported file format');
      logger.warn('User tried to upload an unsupported file format', {
        fileExtension,
      });
      return;
    }

    if (userFileSystem.type !== 'available') {
      toast.error('File system not available');
      logger.error(
        'User tried to upload a file but the file system is not available',
        {
          userFileSystem: userFileSystem.type,
        },
      );
      return;
    }

    try {
      const appointmentDir =
        await userFileSystem.appointmentsDir.getDirectoryHandle(appointmentId, {
          create: true,
        });

      const localFile = await appointmentDir.getFileHandle(file.name, {
        create: true,
      });

      if ('createWritable' in localFile) {
        const writable = await localFile.createWritable();
        await writable.write(file);
        await writable.close();
      }
    } catch (error) {
      toast.error('Failed to upload file');
      logger.error('User encountered error while uploading file', {
        errorMessage: (error as Error).message,
      });
      return;
    }

    send({
      type: 'TASK.UPLOAD_FILE',
      appointmentId,
      fileName: file.name,
      format: audioFormat,
      wasDegraded: false,
      shouldBeTranscribed: true,
      recordingStartedAt: new Date().toISOString(),
      isBackgroundUpload: false,
      attemptNumber: 1,
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    if (appointmentStatus === AppointmentStatusTypesLookup.NOT_STARTED) {
      await setAppointmentStartedAt({
        variables: {
          id: appointmentId,
        },
      });
    }
  };

  return (
    <>
      <Input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileChange}
        accept="audio/*"
      />

      <NewButton
        type="subtle"
        size={buttonSize}
        onClick={handleUploadFileButtonClick}
        disabled={hasPendingAppointmentTask}
        text="Upload File"
      />
    </>
  );
};
