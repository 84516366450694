import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { createBrowserInspector } from '@statelyai/inspect';
import { createActorContext } from '@xstate/react';

import { useApiClient } from '@eluve/api-client-provider';
import { FCC } from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { GCSFileUploadFailedDialog } from './GCSFileUploadFailedDialog';
import { OverridingExistingSummaryDialog } from './OverridingExistingSummaryDialog';
import { appointmentTasksMachine } from './appointmentTasks.machine';
import { useDb } from './files-db';
import { useUserFileSystemStore } from './user-file-system.store';

// TODO(jesse)[ELU-2106]: refactor this so that its in a shared location and isn't duplicated
// from TranscriptionMachineProvider
const getInspect = () => {
  if (process.env.NODE_ENV !== 'development') {
    return undefined;
  }

  const IgnoredInspectorEvents: string[] = [];
  const { inspect } = createBrowserInspector({
    autoStart: false,
    filter: (inspEvent) => {
      if (inspEvent.type === '@xstate.event') {
        return !IgnoredInspectorEvents.includes(inspEvent.event.type);
      }
      if (inspEvent.type === '@xstate.snapshot') {
        return !IgnoredInspectorEvents.includes(inspEvent.event.type);
      }
      return true;
    },
  });

  return inspect;
};

const AppointmentTasksMachineContext = createActorContext(
  appointmentTasksMachine,
  { inspect: getInspect() },
);

export const AppointmentTasksMachineProvider: FCC = ({ children }) => {
  const apolloClient = useApolloClient();
  const apiClient = useApiClient();
  const tenantId = useTenantIdFromParams();
  const userFileSystem = useUserFileSystemStore(
    (state) => state.userFileSystem,
  );
  const db = useDb();

  const logger = useNamedLogger('AppointmentTasksMachine');

  return (
    <AppointmentTasksMachineContext.Provider
      options={{
        input: {
          userFileSystem,
          apolloClient,
          apiClient,
          tenantId: tenantId!,
          db,
          logger,
        },
      }}
    >
      {children}
      <GCSFileUploadFailedDialog />
      <OverridingExistingSummaryDialog />
    </AppointmentTasksMachineContext.Provider>
  );
};

type SelectorArgs = Parameters<
  typeof AppointmentTasksMachineContext.useSelector
>[0];

export const useAppointmentTasksSelector = <TSelector extends SelectorArgs>(
  selector: TSelector,
): ReturnType<TSelector> => {
  const result = AppointmentTasksMachineContext.useSelector((s) => {
    try {
      const result = selector(s);
      return result;
    } catch (e) {
      Sentry.configureScope((scope) => {
        if (s) {
          scope.setContext('appointmentTasksMachineSelectorSnapshot', {
            val: 'toJSON' in s ? s.toJSON() : { message: 'toJSON is missing' },
            objectKeys: Object.keys(s),
          });
        } else {
          scope.setContext('appointmentTasksMachineSelectorSnapshot', {
            message: 'Received null or undefined snapshot',
          });
        }
      });

      throw e;
    }
  });

  return result as ReturnType<TSelector>;
};

export const useAppointmentTasksActor =
  AppointmentTasksMachineContext.useActorRef;
