import uniqBy from 'lodash/uniqBy';

import { useCompleteFragment } from '@eluve/apollo-client';
import { tenantLocationsAndExternalEhrsFragment } from '@eluve/frontend-feature-tenants';
import {
  SupportedVendors,
  WebBasedSupportedVendors,
} from '@eluve/graphql-types';

export type ExternalEhr = {
  id: string;
  vendor: SupportedVendors;
  domain: string;
};

export type WebBasedExternalEhr = {
  id: string;
  vendor: WebBasedSupportedVendors;
  domain: string;
};

export const useExternalEhrs = (tenantId: string) => {
  const data = useCompleteFragment({
    fragment: tenantLocationsAndExternalEhrsFragment,
    key: {
      id: tenantId,
    },
    strict: false,
  });

  return uniqBy(data?.locations ?? [], (location) => location?.externalEhr?.id)
    .map((location) => location?.externalEhr)
    .filter(Boolean) as ExternalEhr[];
};
