import { JsonType, PostHogConfig } from 'posthog-js';
import { ZodSchema } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import { booleanFeatureFlag } from './boolean-feature-flag';
import { noteRegenerationExampleChips } from './regenerate-with-custom-instructions';
import { transcriptionConfigurations } from './transcription-configurations';
import { userPreferencesCustomInstructions } from './user-preferences-custom-instructions';
import { SchemaWithDefaults } from './utils';

export type FeatureFlagDefinitions = Record<
  string,
  SchemaWithDefaults<ZodSchema<JsonType>>
>;

export const featureFlagsWithPayloads = {
  NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS: noteRegenerationExampleChips,
  USER_PREFERENCES_CUSTOM_INSTRUCTIONS: userPreferencesCustomInstructions,
  USE_EXTERNAL_PATIENT_ARTIFACTS_FOR_SOAP_GENERATION: booleanFeatureFlag,
  TRANSCRIPTION_CONFIGURATIONS: transcriptionConfigurations,
} as const satisfies FeatureFlagDefinitions;

export type FeatureFlagKeys = keyof typeof featureFlagsWithPayloads;

export const FeatureFlagKeysLookup = {
  NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS:
    'NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS',
  USER_PREFERENCES_CUSTOM_INSTRUCTIONS: 'USER_PREFERENCES_CUSTOM_INSTRUCTIONS',
  USE_EXTERNAL_PATIENT_ARTIFACTS_FOR_SOAP_GENERATION:
    'USE_EXTERNAL_PATIENT_ARTIFACTS_FOR_SOAP_GENERATION',
  TRANSCRIPTION_CONFIGURATIONS: 'TRANSCRIPTION_CONFIGURATIONS',
} satisfies MatchingRecord<FeatureFlagKeys>;

type PostHogBootstrap = PostHogConfig['bootstrap'];

export const makeBootstrappedFlags = (
  input: Record<string, SchemaWithDefaults<ZodSchema<JsonType>>>,
): Pick<PostHogBootstrap, 'featureFlagPayloads' | 'featureFlags'> => {
  const featureFlags: PostHogBootstrap['featureFlags'] = {};
  const featureFlagPayloads: PostHogBootstrap['featureFlagPayloads'] = {};

  for (const [key, val] of Object.entries(input)) {
    featureFlags[key] = val.defaultEnabled;
    featureFlagPayloads[key] = val.defaultValue;
  }

  return {
    featureFlagPayloads,
    featureFlags,
  };
};

export const bootstrappedFlags = makeBootstrappedFlags(
  featureFlagsWithPayloads,
);
