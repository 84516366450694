import { useQuery } from '@apollo/client';
import { useReactTable } from '@tanstack/react-table';
import React from 'react';

import {
  ColDefBuilder,
  DataTableFilterField,
  ReskinContent,
  ReskinMain,
  ServerDataTable,
  useDataTableQueryState,
} from '@eluve/components';
import {
  AppointmentStatusEnum,
  AppointmentsOrderBy,
} from '@eluve/graphql-types';
import { ResultOf, graphql } from '@eluve/graphql.tada';

const searchAppointmentsQuery = graphql(`
  query searchAppointments(
    $filter: AppointmentsBoolExp
    $offset: Int
    $orderBy: [AppointmentsOrderBy!]
    $limit: Int
  ) {
    appointments(
      where: $filter
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      __typename
      id
      name
      status
      updatedAt
    }
    appointmentsAggregate(where: $filter) {
      __typename
      aggregate {
        __typename
        count
      }
    }
  }
`);

type Data = ResultOf<typeof searchAppointmentsQuery>['appointments'][number];

export interface TestPageProps {}

const columns = new ColDefBuilder<Data>()
  .defaultSortable('name')
  .defaultSortable('status')
  .dateSortable('updatedAt', 'Updated At')
  .build();

const filterFields: DataTableFilterField<Data>[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'status',
    label: 'Status',
    options: [
      { label: 'ACTIVE', value: 'ACTIVE' },
      { label: 'COMPLETED', value: 'COMPLETED' },
      { label: 'NOT_STARTED', value: 'NOT_STARTED' },
    ],
  },
];

export const ServerAppointmentsTablePage: React.FC<TestPageProps> = () => {
  const { page, perPage, sorting, filters, reactTableOptions } =
    useDataTableQueryState<Data>({
      filterFields,
    });

  const { data } = useQuery(searchAppointmentsQuery, {
    variables: {
      filter: {
        // we need to map existing filters to the correct format
        _and: [
          ...(filters.name
            ? [
                {
                  name: {
                    _ilike: `%${filters.name}%`,
                  },
                },
              ]
            : []),
          ...(filters.status && filters.status.length
            ? [
                {
                  status: {
                    _in: filters.status as AppointmentStatusEnum[],
                  },
                },
              ]
            : []),
        ],
      },
      offset: perPage * (page - 1),
      limit: perPage,
      orderBy:
        sorting && sorting.length
          ? sorting.map<AppointmentsOrderBy>(({ desc, id }) => ({
              [id]: desc ? 'DESC' : 'ASC',
            }))
          : null,
    },
  });

  const rowCount = data?.appointmentsAggregate?.aggregate?.count;

  const table = useReactTable({
    data: data?.appointments ?? [],
    columns,
    rowCount,
    ...reactTableOptions,
  });

  return (
    <ReskinMain>
      <ReskinContent variant="fullWidth">
        <ServerDataTable<Data> table={table} filterFields={filterFields} />
      </ReskinContent>
    </ReskinMain>
  );
};
