import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ArrowLeftIcon, ArrowRightIcon } from 'lucide-react';
import { useState } from 'react';

import { OnlyStringKeys } from '@eluve/utils';

import { Box } from './box';
import { Button } from './button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSearch,
} from './table';
import { textStyles } from './textStyles';
import { useTextHighlight } from './use-text-highlight';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isPaginated?: boolean;
  isCompact?: boolean;
  enableGlobalSearch?: boolean;
  initialSortingState?: { id: OnlyStringKeys<keyof TData>; desc: boolean }[];
  placeholder?: string;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  isPaginated = true,
  isCompact = false,
  enableGlobalSearch,
  initialSortingState = [],
  placeholder,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>(initialSortingState);
  const [globalSearch, setGlobalSearch] = useState<string>('');

  const tableBodyRef = useTextHighlight(globalSearch);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: isPaginated ? getPaginationRowModel() : undefined,
    getFilteredRowModel: enableGlobalSearch ? getFilteredRowModel() : undefined,
    state: {
      sorting,
      globalFilter: enableGlobalSearch ? globalSearch : undefined,
    },
  });

  return (
    <div className="relative w-full overflow-clip rounded-md border">
      {enableGlobalSearch && (
        <Box className="sticky top-0 z-10 border-b border-gray-5 bg-gray-1">
          <TableSearch value={globalSearch} onChange={setGlobalSearch} />
        </Box>
      )}
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header, headerIndex) => {
                return (
                  <TableHead
                    isCompact={headerIndex !== 0 && isCompact}
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody ref={tableBodyRef}>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell isCompact={isCompact} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {placeholder ?? 'No results.'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isPaginated && (
        <Box
          hStack
          className="sticky bottom-0 w-full justify-between gap-6 border-t border-gray-5 bg-gray-1 p-2 text-sm sm:justify-end"
        >
          <Box hStack asChild className="gap-3 whitespace-nowrap">
            <label>
              <span
                className={textStyles.body({ size: 's', weight: 'medium' })}
              >
                Page size
              </span>
              <Select
                onValueChange={(value) => {
                  table.setPageSize(Number(value));
                }}
                defaultValue={table.getState().pagination.pageSize.toString()}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select page size" className="w-max">
                    {table.getState().pagination.pageSize}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {[10, 20, 50].map((size) => (
                    <SelectItem key={size} value={size.toString()}>
                      {size} rows
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </label>
          </Box>
          <Box hStack className="gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ArrowLeftIcon className="size-4 md:mr-2" />
              <span
                className={textStyles.body({
                  size: 's',
                  weight: 'medium',
                  className: 'hidden md:block',
                })}
              >
                Previous
              </span>
            </Button>
            {table.getState().pagination.pageIndex + 1}
            <span className="text-gray-8"> / {table.getPageCount()}</span>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span
                className={textStyles.body({
                  size: 's',
                  weight: 'medium',
                  className: 'hidden md:block',
                })}
              >
                Next
              </span>
              <ArrowRightIcon className="size-4 md:ml-2" />
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
}
