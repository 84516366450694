import { z } from 'zod';

const appointmentServiceSchema = z
  .object({
    id: z.string().nullish(),
    duration: z.number().nullish(),
    endAt: z.string().nullish(),
    finishDuration: z.number().nullish(),
    price: z.number().nullish(),
    service: z
      .object({
        name: z.string().nullish(),
        category: z
          .object({
            name: z.string().nullish(),
          })
          .passthrough()
          .nullish(),
      })
      .passthrough()
      .nullish(),
    staffRequested: z.boolean().nullish(),
    staff: z
      .object({
        id: z.string().nullish(),
        firstName: z.string().nullish(),
        lastName: z.string().nullish(),
        email: z.string().nullish(),
        mobilePhone: z.string().nullable().nullish(),
        role: z
          .object({
            name: z.string().nullish(),
          })
          .passthrough()
          .nullish(),
      })
      .passthrough()
      .nullish(),
    startAt: z.string().nullish(),
  })
  .passthrough()
  .nullish();

export const clientSchema = z
  .object({
    id: z.string(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    email: z.string().nullish(),
    mobilePhone: z.string().nullable().nullish(),
    active: z.boolean().nullish(),
    createdAt: z.string().nullish(),
    updatedAt: z.string().nullish(),
    dob: z.string().nullable().nullish(),
    name: z.string().nullish(),
  })
  .passthrough();

export const appointmentSchema = z
  .object({
    id: z.string(),
    notes: z.string().nullable().nullish(),
    state: z.string().nullish(),
    startAt: z.string().nullish(),
    endAt: z.string().nullish(),
    duration: z.number().nullish(),
    createdAt: z.string().nullish(),
    cancelled: z.boolean().nullish(),
    orderId: z.string().nullable().nullish(),
    rating: z
      .object({
        rating: z.number().nullish(),
        text: z.string().nullish(),
        id: z.string().nullish(),
      })
      .passthrough()
      .nullable()
      .nullish(),
    client: clientSchema,
    appointmentServices: z.array(appointmentServiceSchema).nullish(),
  })
  .passthrough();

const appointmentEdgeSchema = z
  .object({
    node: appointmentSchema,
  })
  .passthrough()
  .nullish();

const appointmentPageInfoSchema = z
  .object({
    endCursor: z.string().nullable().nullish(),
    hasNextPage: z.boolean().nullish(),
  })
  .passthrough()
  .nullish();

const appointmentsResponseSchema = z
  .object({
    appointments: z
      .object({
        edges: z.array(appointmentEdgeSchema).nullish(),
        pageInfo: appointmentPageInfoSchema,
      })
      .nullish(),
  })
  .passthrough()
  .nullish();

export type AppointmentService = z.infer<typeof appointmentServiceSchema>;
export type Client = z.infer<typeof clientSchema>;
export type Appointment = z.infer<typeof appointmentSchema>;
export type AppointmentEdge = z.infer<typeof appointmentEdgeSchema>;
export type AppointmentPageInfo = z.infer<typeof appointmentPageInfoSchema>;
export type AppointmentsResponse = z.infer<typeof appointmentsResponseSchema>;
