import { z } from 'zod';

export const RolesSchema = z.enum([
  'user',
  'tenant-admin',
  'tenant-member',
  'eluve-admin',
  'eval-user',
  'reviewer',
]);

export const HasuraClaimsSchema = z.object({
  'https://hasura.io/jwt/claims': z.object({
    'x-hasura-user-id': z.string(),
    'x-hasura-tenant-id': z.string().optional(),
    'x-hasura-default-role': RolesSchema,
    'x-hasura-allowed-roles': z.array(RolesSchema),
  }),
});

export const SessionSchema = HasuraClaimsSchema.merge(
  z.object({
    isEluveAdmin: z.boolean().default(false),
    isPromptTemplateCreator: z.boolean().default(false),
    isEluveEvalUser: z.boolean().default(false),
    isReviewer: z.boolean().default(false),
    firstName: z
      .string()
      .nullish()
      .transform((t) => t ?? ''),
    lastName: z
      .string()
      .nullish()
      .transform((t) => t ?? ''),
    allowedTenantIds: z.array(z.string()).default([]),
    email: z.string().email(),
  }),
);

export type HasuraClaims = z.infer<typeof HasuraClaimsSchema>;

export type SessionData = z.infer<typeof SessionSchema>;

export type Role = z.infer<typeof RolesSchema>;
