import React from 'react';

import { H3, P } from '@eluve/components';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { TenantLlmOutputs } from './components/TenantLlmOutputs';

export const AdminTenantLlmOutputsPage: React.FC = () => {
  const tenantId = useTenantIdFromParams()!;

  return (
    <div className="grid gap-2">
      <div>
        <H3>LLM Outputs</H3>
        <P>Control which output types this tenant will be able to generate</P>
      </div>
      <TenantLlmOutputs tenantId={tenantId} />
    </div>
  );
};
