import { useMutation } from '@apollo/client';
import { Trash2 } from 'lucide-react';
import React from 'react';
import { toast } from 'sonner';

import { cacheUtils } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const datasetSourceArtifactsFragment = graphql(`
  fragment DatasetSourceArtifacts on EvalDatasets @_unmask {
    id
    datasetSourceArtifacts {
      id
    }
  }
`);

const deleteDatasetSourceArtifactMutation = graphql(`
  mutation deleteDatasetSourceArtifact($id: uuid!) {
    deleteEvalDatasetSourceArtifactsByPk(id: $id) {
      __typename
      id
    }
  }
`);

export interface RemoveArtifactFromDatasetActionProps {
  datasetSourceArtifactId: string;
  datasetId: string;
}

export const RemoveArtifactFromDatasetAction: React.FC<
  RemoveArtifactFromDatasetActionProps
> = ({ datasetSourceArtifactId, datasetId }) => {
  const [deleteDatasetSourceArtifact] = useMutation(
    deleteDatasetSourceArtifactMutation,
    {
      onError: () => {
        toast.error('Failed to remove artifact from dataset');
      },
      optimisticResponse: {
        deleteEvalDatasetSourceArtifactsByPk: {
          __typename: 'EvalDatasetSourceArtifacts' as const,
          id: datasetSourceArtifactId,
        },
      },
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: datasetSourceArtifactsFragment,
            key: {
              id: datasetId,
            },
          },
          (existing) => {
            return data?.deleteEvalDatasetSourceArtifactsByPk
              ? {
                  id: datasetId,
                  datasetSourceArtifacts: (
                    existing?.datasetSourceArtifacts ?? []
                  ).filter(
                    (dsa) =>
                      dsa.id !== data.deleteEvalDatasetSourceArtifactsByPk?.id,
                  ),
                }
              : existing;
          },
        );
      },
    },
  );

  const removeArtifactFromDataset = async () => {
    await deleteDatasetSourceArtifact({
      variables: {
        id: datasetSourceArtifactId,
      },
    });
  };

  return (
    <AlertDialog>
      <TooltipLabel label="Remove this artifact from the dataset">
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline">
            <Trash2 className="text-orange" />
          </Button>
        </AlertDialogTrigger>
      </TooltipLabel>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-gray-11">
            Are you sure?
          </AlertDialogTitle>
          <AlertDialogDescription>
            This will remove the artifact from the dataset.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel autoFocus>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            onClick={removeArtifactFromDataset}
          >
            Remove
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
