import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import {
  DraftContent,
  EhrDiffContent,
  SummaryContent,
  SummaryDiffContent,
} from './AppointmentArtifacts';
import { AppointmentTranscripts } from './AppointmentTranscripts';
import { ReviewAppointmentLayout } from './ReviewAppointmentLayout';
import { ReviewAppointmentsPage } from './ReviewAppointmentsPage';
import { ReviewDashGuard } from './ReviewDashGuard';
import { ReviewFeedbackPage } from './ReviewFeedbackPage';
import { ReviewLayout } from './ReviewLayout';

export const reviewAppointmentDetailsRoutes = [
  {
    index: true,
    element: <Navigate to="transcripts" replace />,
  },
  {
    path: 'transcripts',
    element: <AppointmentTranscripts />,
  },
  {
    path: 'summary',
    element: <SummaryContent />,
  },
  {
    path: 'draft',
    element: <DraftContent />,
  },
  {
    path: 'summary-diff',
    element: <SummaryDiffContent />,
  },
  {
    path: 'ehr-diff',
    element: <EhrDiffContent />,
  },
];

export const reviewAppointmentDetailsTabNav = [
  { name: 'Transcript', link: 'transcripts' },
  { name: 'Summary', link: 'summary' },
  {
    name: 'Doctor Draft',
    link: 'draft',
  },
  {
    name: 'Summary Diff',
    link: 'summary-diff',
  },
  {
    name: 'EHR Diff',
    link: 'ehr-diff',
  },
];

export const createReviewRoutes = (
  path: string,
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return [
    {
      path,
      element: (
        <ReviewDashGuard>
          <ApolloProvider client={client}>
            <Suspense>
              <ReviewLayout />
            </Suspense>
          </ApolloProvider>
        </ReviewDashGuard>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="appointments" replace />,
        },
        {
          path: 'appointments',
          element: <ReviewAppointmentsPage />,
        },
        {
          path: 'appointments/:tenantId/:appointmentId',
          element: <ReviewAppointmentLayout showTools />,
          children: reviewAppointmentDetailsRoutes,
        },
        {
          path: 'feedback',
          element: <ReviewFeedbackPage />,
        },
      ],
    },
  ];
};
