import { useMutation } from '@apollo/client';
import { Trash } from 'lucide-react';
import { toast } from 'sonner';

import { cacheUtils } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useTenantName } from '@eluve/smart-blocks';

const DeleteUserMutation = graphql(`
  mutation DeleteTenantUser($tenantId: uuid!, $userId: uuid!) {
    deleteTenantUsersByPk(tenantId: $tenantId, userId: $userId) {
      __typename
      tenantId
      userId
    }
  }
`);

interface UserRemoveButtonProps {
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  tenantId: string;
}

export const UserRemoveButton: React.FC<UserRemoveButtonProps> = ({
  user: { id: userId, ...user },
  tenantId,
}) => {
  const [deleteUser] = useMutation(DeleteUserMutation, {
    onError: () => {
      toast.error(`Failed to remove user ${user.firstName} ${user.lastName}`);
    },
  });

  const tenantName = useTenantName(tenantId);

  const handleRemove = () => {
    deleteUser({
      variables: { userId, tenantId },
      optimisticResponse: {
        deleteTenantUsersByPk: {
          __typename: 'TenantUsers',
          tenantId,
          userId,
        },
      },
      update: (cache) => {
        cacheUtils.evict({
          typeName: 'TenantUsers',
          key: { tenantId, userId },
        });
        cache.gc();
      },
    });
  };

  return (
    <AlertDialog>
      <TooltipLabel label="Remove user">
        <AlertDialogTrigger asChild>
          <Button variant="destructive" size="icon">
            <Trash />
          </Button>
        </AlertDialogTrigger>
      </TooltipLabel>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-gray-11">
            Are you sure?
          </AlertDialogTitle>
          <AlertDialogDescription>
            {'Do you want to remove '}
            <strong>
              {user.firstName} {user.lastName}
            </strong>
            {' from '}
            <strong>{tenantName}</strong>.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel autoFocus>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            onClick={handleRemove}
          >
            Remove
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
