import React from 'react';
import { VariantProps } from 'tailwind-variants';

import { HStack, Tag, textStyles } from '@eluve/components';

import { styles } from './MedicalCode';

type MedicineDetailsProps = {
  name: string;
  ingredients: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onClick?: () => void;
  type?: VariantProps<typeof styles>['type'];
};

export const MedicineDetails = React.forwardRef<
  HTMLDivElement,
  MedicineDetailsProps
>(({ name, ingredients, onClick, startAdornment, endAdornment, type }, ref) => {
  const { container, content, frame } = styles({
    clickable: Boolean(onClick),
    type,
  });

  return (
    <HStack
      align="center"
      className={container()}
      wFull={false}
      inline
      ref={ref}
      onClick={onClick}
      gap={3}
    >
      {startAdornment && startAdornment}

      <HStack gap={2}>
        {ingredients && <Tag label={ingredients} size="xs" color="yellow" />}
        <div className={textStyles.body({ size: 's', className: content() })}>
          <p>{name}</p>
        </div>
      </HStack>

      {endAdornment && endAdornment}

      <div className={frame()} />
    </HStack>
  );
});

MedicineDetails.displayName = 'MedicineDetails';
