export const linearIssueRegex = /^(ELU-|FB-)\d+$/;

/**
 * Checks to see if the provided string matches a Linear issue from the Eluve workspace
 */
export const isLinearIssue = (issue: string) => linearIssueRegex.test(issue);

/**
 * Checks to see if the provided string appears to be a link to a Linear issue from the
 * Eluve workspace
 */
export const isLinearIssueLink = (link: string) =>
  link.startsWith('https://linear.app/eluve/');
