import { z } from 'zod';

import { AppointmentModel } from '../../models/appointment';
import { ChartType } from '../../models/chart';
import { PatientType } from '../../models/patient';
import { PatientEhrUrlArgs, Vendor, VendorSyncConfig } from '../vendor';

import { logo } from './logo';
import { appointmentSchema, clientSchema } from './types';

export class BlvdVendor implements Vendor {
  getAppointments(data: unknown[]): AppointmentModel[] {
    const appointments: AppointmentModel[] = [];
    for (const rawAppointment of data) {
      const appointment = appointmentSchema.parse(rawAppointment);
      appointments.push({
        externalId: appointment.id,
        name:
          appointment.appointmentServices
            ?.map((s) => s?.service?.name)
            ?.join(', ') ?? 'New Session',
        description: '',
        externalPatientId: appointment.client?.id ?? undefined,
        firstName: appointment.client?.firstName ?? '',
        lastName: appointment.client?.lastName ?? '',
        startTime: appointment.startAt ?? new Date().toISOString(),
        endTime: appointment.endAt ?? '',
        rawEhrData: rawAppointment as Record<string, unknown>,
        isArchived: Boolean(appointment.cancelled),
        isImportable: true,
        status: undefined,
        sessionType: undefined,
      });
    }

    return appointments;
  }

  getPatient(data: unknown): PatientType | null {
    const patient = clientSchema.parse(data);
    return {
      externalPatientId: patient.id,
      firstName: patient.firstName ?? patient.name ?? '',
      lastName: patient.lastName ?? '',
      dateOfBirth: patient.dob ?? null,
      email: patient.email ?? null,
      homePhone: null,
      cellPhone: patient.mobilePhone ?? null,
      workPhone: null,
      gender: undefined,
      rawData: data as Record<string, unknown>,
    };
  }

  getPatientEhrUrl(data: PatientEhrUrlArgs): string {
    throw new Error('Not implemented');
  }

  getLogo = () => logo;

  getHomeUrl(domain: string): string {
    throw new Error('Not implemented');
  }

  getChart(
    data: unknown,
    additionalFields?: Record<string, unknown>,
  ): ChartType {
    throw new Error('Not implemented');
  }

  getSyncConfig(): VendorSyncConfig {
    return {
      canSignNoteInEhr: false,
      canSyncWithManualChartUrl: false,
      canSyncNoteToEhr: () => ({ canSync: false }),
    };
  }

  getVendorSchema = () =>
    z.object({
      'API Key': z.string(),
      'Key Secret': z.string(),
      'Business ID': z.string(),
    });
}
