export type ExtensionErrorName =
  | 'HOME_SCREEN_NOT_FOUND'
  | 'NOT_LOGGED_IN'
  | 'SOAP_TEMPLATE_NOT_FOUND'
  | 'NOTE_EDITOR_NOT_FOUND'
  | 'FAILED_TO_SIGN'
  | 'GENERIC_ERROR'
  | 'TIMEOUT'
  | 'EXTENSION_VERSION_OUTDATED'
  | 'FAILED_TO_IMPORT'
  | 'TEMPLATE_NOT_SUPPORTED';

export type HumanFriendlyError = {
  title: string;
  message: string;
};

export const SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS: Record<
  ExtensionErrorName,
  HumanFriendlyError
> = {
  EXTENSION_VERSION_OUTDATED: {
    title: 'Eluve Extension Outdated',
    message:
      'The Eluve extension is out of date. To update the extension to the latest version, refresh the page, and try again.',
  },
  HOME_SCREEN_NOT_FOUND: {
    title: 'Home Screen Unavailable',
    message:
      "We're sorry, but we couldn't load EHR home screen. Please try refreshing the page.",
  },
  NOT_LOGGED_IN: {
    title: 'Not Authorized',
    message: 'Please sign in to your EHR account in a new tab and try again.',
  },
  SOAP_TEMPLATE_NOT_FOUND: {
    title: 'Template Missing',
    message:
      'Template not found: "SOAP Template". Please check if the template exists and try again.',
  },
  NOTE_EDITOR_NOT_FOUND: {
    title: 'Note Editor Unavailable',
    message:
      "We couldn't locate the note editor within EHR. Please ensure it's present and try again.",
  },
  TEMPLATE_NOT_SUPPORTED: {
    title: 'Template Not Supported',
    message:
      'The selected template is not supported by the EHR. Please select a different template and try again.',
  },
  FAILED_TO_SIGN: {
    title: 'Chart Entry Signature Failed',
    message:
      'We encountered an issue while signing the chart entry. Please try again.',
  },
  GENERIC_ERROR: {
    title: 'Unexpected Error',
    message: 'An unexpected error occurred. Refresh the page and try again.',
  },
  TIMEOUT: {
    title: 'Request Timed Out',
    message:
      'Your request took too long to process. Please check your internet connection and try again.',
  },
  FAILED_TO_IMPORT: {
    title: 'Import Failed',
    message: 'Failed to import data. Please check the data and try again.',
  },
} as const;

export const ERRORS = Object.keys(SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS).reduce(
  (acc, key) => {
    acc[key as keyof typeof SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS] =
      key as keyof typeof SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS;
    return acc;
  },
  {} as Record<
    keyof typeof SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS,
    keyof typeof SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS
  >,
);

export const getHumanFriendlyError = (error?: ExtensionErrorName | string) => {
  if (error && error in ERRORS) {
    return SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS[error as ExtensionErrorName];
  }
  return SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS.GENERIC_ERROR;
};
