import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { supportedVendorsSchema } from '@eluve/graphql-types';

const c = initContract();

export const locationsContract = c.router(
  {
    getLocations: {
      method: 'GET',
      path: '/',
      responses: {
        200: z.object({
          locations: z.array(
            z.object({
              id: z.string(),
              name: z.string(),
            }),
          ),
        }),
      },
      summary: 'Get available tenant locations',
    },
    addUserToLocation: {
      method: 'POST',
      path: '/:locationId/users',
      responses: {
        201: z.object({}),
      },
      body: z.object({}),
      summary: 'Adds user to a location',
    },
    addEhr: {
      method: 'POST',
      path: '/:locationId/ehr',
      responses: {
        201: z.object({
          externalEhrId: z.string(),
        }),
      },
      body: z.object({
        vendor: supportedVendorsSchema,
        domain: z.string(),
        vendorConfiguration: z.unknown().optional(),
      }),
      summary: 'Add EHR to a location',
    },
  },
  {
    pathPrefix: '/:tenantId/locations',
  },
);
