import React from 'react';

import { H3, P } from '@eluve/components';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { TenantFeatureFlags } from './components/TenantFeatureFlags';

export const AdminTenantFeatureFlagsPage: React.FC = () => {
  const tenantId = useTenantIdFromParams()!;

  return (
    <div className="grid gap-2">
      <div>
        <H3>Feature Flags</H3>
        <P>Control which features this tenant has access to</P>
      </div>
      <TenantFeatureFlags tenantId={tenantId} />
    </div>
  );
};
