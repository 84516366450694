import { draftContentFragment } from '.';
import noop from 'lodash/noop';
import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { Box, H2, VStack } from '@eluve/components';
import {
  DynamicArtifactTemplate,
  dynamicArtifactTemplateSchema,
  flattenBlocks,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';
import { SummarySectionMarkdown } from '@eluve/smart-blocks';
import { removeSpecialCharacters } from '@eluve/utils';
import {
  AppointmentSummaryKeys,
  summaryKeyToSectionTitleMap,
} from '@eluve/utils';

export const SummaryDiffContent: React.FC = () => {
  const { appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };
  const appointment = useCompleteFragment({
    fragment: draftContentFragment,
    key: { id: appointmentId },
  });

  const humanSoapNote = appointment?.humanOutputs?.[0];
  const humanSoapNoteContent = humanSoapNote?.output?.content;
  const { additionalNotes } = appointment?.doctor_interaction ?? {};

  const hasDynamicOutputTemplate = Boolean(
    humanSoapNote?.output?.llm_output?.outputTemplate?.dynamicArtifactTemplate,
  );
  const llmSoapNoteContent = humanSoapNote?.output?.llm_output?.content;

  let hydratedLlmSummary: DynamicArtifactTemplate | undefined = undefined;
  let hydratedHumanOutput: DynamicArtifactTemplate | undefined = undefined;
  if (hasDynamicOutputTemplate) {
    const artifactTemplate = dynamicArtifactTemplateSchema.parse(
      humanSoapNote?.output?.llm_output?.outputTemplate
        ?.dynamicArtifactTemplate ?? {},
    );
    hydratedLlmSummary = hydrateDynamicArtifactTemplate(
      artifactTemplate,
      llmSoapNoteContent ?? {},
    );
    hydratedHumanOutput = hydrateDynamicArtifactTemplate(
      artifactTemplate,
      humanSoapNoteContent ?? {},
    );
  }

  return (
    <VStack>
      {hasDynamicOutputTemplate ? (
        <VStack>
          {(() => {
            const llmBlocks = flattenBlocks(hydratedLlmSummary!.blocks);
            const humanBlocks = flattenBlocks(hydratedHumanOutput!.blocks);
            return llmBlocks.map((llmBlock) => {
              const matchingHumanBlock = humanBlocks.find(
                (b) => b.key === llmBlock.key,
              );
              return (
                <Box key={llmBlock.label} gap={4}>
                  <H2>{llmBlock.label}</H2>
                  <Box gap={4}>
                    {matchingHumanBlock?.type === 'text' &&
                      llmBlock.type === 'text' && (
                        <ReactDiffViewer
                          oldValue={removeSpecialCharacters(llmBlock.text)}
                          newValue={removeSpecialCharacters(
                            matchingHumanBlock.text,
                          )}
                          splitView={true}
                          showDiffOnly={true}
                        />
                      )}
                    {matchingHumanBlock?.type === 'checkbox' &&
                      llmBlock.type === 'checkbox' && (
                        <ReactDiffViewer
                          oldValue={JSON.stringify(
                            llmBlock.options,
                            undefined,
                            2,
                          )}
                          newValue={JSON.stringify(
                            matchingHumanBlock.options,
                            undefined,
                            2,
                          )}
                          splitView={true}
                          showDiffOnly={true}
                        />
                      )}
                    {matchingHumanBlock?.type === 'range' &&
                      llmBlock.type === 'range' && (
                        <ReactDiffViewer
                          oldValue={`${llmBlock.value}`}
                          newValue={`${matchingHumanBlock.value}`}
                          splitView={true}
                          showDiffOnly={true}
                        />
                      )}
                  </Box>
                </Box>
              );
            });
          })()}
        </VStack>
      ) : (
        <Box>
          {Object.entries(summaryKeyToSectionTitleMap).map(
            ([key, sectionTitle]) => {
              const summaryKey = key as AppointmentSummaryKeys;
              return (
                <Box gap={2} key={summaryKey}>
                  <H2>{sectionTitle}</H2>
                  <Box>
                    <ReactDiffViewer
                      oldValue={removeSpecialCharacters(
                        llmSoapNoteContent?.[summaryKey],
                      )}
                      newValue={removeSpecialCharacters(
                        humanSoapNoteContent?.[summaryKey],
                      )}
                      splitView={true}
                      showDiffOnly={true}
                    />
                  </Box>
                </Box>
              );
            },
          )}
        </Box>
      )}
      {additionalNotes && (
        <SummarySectionMarkdown
          appointmentId={appointmentId}
          key="AdditionalNotes"
          // TODO(jesse): this should get cleaned up and use a different component [ELU-949]
          summaryKey={`AdditionalNotes` as any}
          sectionTitle="Additional Notes"
          content={additionalNotes}
          disabled={true}
          handleContentChange={noop}
        />
      )}
    </VStack>
  );
};
