import { Table as TanstackTable, flexRender } from '@tanstack/react-table';
import React from 'react';

import { DataTablePagination } from './datatable-pagination';
import { DataTableToolbar } from './datatable-toolbar';
import { DataTableFilterField } from './datatable-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './table';

export type ServerDataTableProps<TData extends Record<string, unknown>> = {
  table: TanstackTable<TData>;
  filterFields?: DataTableFilterField<TData>[];
};

export function ServerDataTable<TData extends Record<string, unknown>>({
  table,
  filterFields = [],
}: ServerDataTableProps<TData>) {
  return (
    <React.Fragment>
      <DataTableToolbar<TData> table={table} filterFields={filterFields} />
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell isCompact key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={table.getVisibleFlatColumns.length}
                className="h-24 text-center"
              >
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <DataTablePagination table={table} />
    </React.Fragment>
  );
}
