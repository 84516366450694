import debounce from 'lodash/debounce';
import React, { useCallback, useMemo } from 'react';
import {
  MultiValueGenericProps,
  OptionProps,
  PlaceholderProps,
  components,
} from 'react-select';
import AsyncSelect from 'react-select/async';

import { useApiClient } from '@eluve/api-client-provider';
import { MedicineDetails } from '@eluve/blocks';
import { HStack, Icon } from '@eluve/components';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import {
  EmotionCacheProvider,
  MultiValueRemove,
  searchableStyle,
} from '../Searchable.styles';

export type Medication = {
  id: string;
  name: string;
  ingredients?: string;
  externalMedicineId?: string;
  rawData?: Record<string, unknown>;
};

interface SearchableMedicinesProps {
  disabled?: boolean;
  onItemAdded?: (option: Medication) => void | Promise<void>;
  onItemRemoved?: (option: Medication) => void | Promise<void>;
  selectedItems?: Medication[];
  shouldRenderValue?: boolean;
}

const Placeholder = (props: PlaceholderProps<Medication>) => {
  return (
    <HStack className="col-start-1 col-end-3 row-start-1 row-end-2 inline-flex gap-3 pl-2">
      <Icon size="xs" name="Search" />
      <components.Placeholder {...props} />
    </HStack>
  );
};

const MultiValueLabel = (_props: MultiValueGenericProps<Medication>) => {
  return null;
};

const MultiValueContainer = (props: MultiValueGenericProps<Medication>) => {
  const { data, selectProps } = props;
  const medicine = data as Medication;

  return (
    <MedicineDetails
      name={medicine.name}
      ingredients={medicine.ingredients ?? ''}
      endAdornment={
        selectProps?.isDisabled ? null : (
          <components.MultiValueRemove {...props} />
        )
      }
    />
  );
};

const Option = (props: OptionProps<Medication>) => {
  const { data } = props;
  const medication = data as Medication;

  return (
    <components.Option {...props} className="flex items-center gap-3">
      <div>{medication.name}</div>
    </components.Option>
  );
};

export const SearchableMedicines: React.FC<SearchableMedicinesProps> = ({
  disabled,
  onItemAdded,
  onItemRemoved,
  selectedItems,
  shouldRenderValue,
}) => {
  const apiClient = useApiClient();
  const tenantId = useTenantIdFromParams()!;

  const _searchMedicineInventory = useCallback(
    (keyword: string, callback: (meds: Medication[]) => void) => {
      apiClient.medicine
        .searchMedicineInventory({
          query: {
            keyword,
          },
          params: {
            tenantId,
          },
        })
        .then((result) => {
          if (result.status === 200) {
            const newItems = [];
            for (const searchResult of result.body) {
              const existingSelectedItem = selectedItems?.find(
                (selected) => selected.externalMedicineId === searchResult.id,
              );
              if (!existingSelectedItem) {
                newItems.push(searchResult);
              }
            }
            callback(newItems);
          } else {
            callback([]);
          }
        });
    },
    [selectedItems, apiClient, tenantId],
  );

  const searchMedicineInventory = useMemo(
    () => debounce(_searchMedicineInventory, 500),
    [_searchMedicineInventory],
  );

  return (
    <EmotionCacheProvider>
      <AsyncSelect<Medication, true>
        isDisabled={disabled}
        controlShouldRenderValue={shouldRenderValue}
        backspaceRemovesValue={!shouldRenderValue}
        placeholder="Choose medicine you would like to add"
        noOptionsMessage={({ inputValue }) =>
          inputValue
            ? `No results for "${inputValue}"`
            : 'Search medicine inventory'
        }
        loadingMessage={() => 'Searching...'}
        isMulti
        value={selectedItems}
        isClearable={false}
        classNames={{
          container: () => searchableStyle.container(),
          control: ({ isDisabled }) =>
            searchableStyle.control({ disabled: isDisabled }),
          valueContainer: () => searchableStyle.valueContainer(),
          menu: () => searchableStyle.menu(),
          menuList: () => searchableStyle.menuList(),
          placeholder: () => searchableStyle.placeholder(),
          multiValue: () => searchableStyle.multiValue(),
          indicatorSeparator: () => searchableStyle.indicatorSeparator(),
          dropdownIndicator: () => searchableStyle.dropdownIndicator(),
          loadingIndicator: () => searchableStyle.loadingIndicator(),
          indicatorsContainer: () => searchableStyle.indicatorsContainer(),
          multiValueRemove: () => searchableStyle.multiValueRemove(),
          option: ({ isFocused }) =>
            searchableStyle.option({ optionFocused: isFocused }),
          noOptionsMessage: () => searchableStyle.noOptionsMessage(),
        }}
        onChange={async (input) => {
          const items = selectedItems ?? [];
          const removed = items.filter((f) => !input.includes(f));
          const added = input.filter((f) => !items.includes(f));

          if (removed.length) {
            const [toRemove] = removed;
            await onItemRemoved?.(toRemove!);
          }

          if (added.length) {
            const [toAdd] = added;
            await onItemAdded?.(toAdd!);
          }
        }}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        components={{
          MultiValueContainer,
          MultiValueLabel,
          MultiValueRemove,
          Option,
          Placeholder,
        }}
        loadOptions={searchMedicineInventory}
      />
    </EmotionCacheProvider>
  );
};
