import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  Modifier,
  UniqueIdentifier,
  closestCenter,
  defaultDropAnimation,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSelector } from '@xstate/store/react';
import capitalize from 'lodash/capitalize';
import { Braces, Copy, FoldVertical, Plus, UnfoldVertical } from 'lucide-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLatest } from 'react-use';

import {
  Box,
  Button,
  DebugJson,
  Drawer,
  DrawerContent,
  DrawerTrigger,
  FormItem,
  H4,
  Input,
  Label,
  P,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  Textarea,
  VStack,
} from '@eluve/components';
import { LlmOutputTypesEnum, LlmOutputTypesLookup } from '@eluve/graphql-types';
import {
  LLM_OUTPUT_TYPE_VARIABLES,
  convertDynamicArtifactToJsonSchema,
} from '@eluve/llm-outputs';

import { BlockPickerPopover } from './BlockPicker';
import { SortableTreeItem } from './SortableTreeItem';
import { templateBuilderStore } from './templateBuilderStore';
import { areAllBlocksValid, flattenBlocks, getProjection } from './utilities';

type ValidationError = { message: string; field?: 'name' | 'description' };

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

export const DynamicArtifactTemplateBuilder: React.FC<{
  onIsValidChange?: (isValid: boolean) => void;
  isReadonly?: boolean;
  showJsonSchema?: boolean;
}> = ({ onIsValidChange, isReadonly = false, showJsonSchema = false }) => {
  const indentationWidth = 24;
  const indicator = true;
  const isValid = useRef(false);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    [],
  );
  const onIsValidChangeRef = useLatest(onIsValidChange);

  useEffect(() => {
    templateBuilderStore.send({ type: 'setIsReadonly', isReadonly });
  }, [isReadonly]);

  const showTips = useSelector(
    templateBuilderStore,
    (state) => state.context.showTips,
  );
  const blocks = useSelector(
    templateBuilderStore,
    (state) => state.context.blocks,
  );

  const outputType = useSelector(
    templateBuilderStore,
    (state) => state.context.outputType,
  );

  const name = useSelector(
    templateBuilderStore,
    ({ context: { name } }) => name,
  );

  const description = useSelector(
    templateBuilderStore,
    ({ context: { description } }) => description,
  );

  const isImported = useSelector(
    templateBuilderStore,
    ({ context: { isImported } }) => isImported,
  );

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  };

  const flattenedBlocks = useMemo(() => flattenBlocks(blocks), [blocks]);

  useEffect(() => {
    const subscription = templateBuilderStore.subscribe((state) => {
      const { context } = state;
      const validationErrors: ValidationError[] = [];

      const allBlocksValid = areAllBlocksValid(context.blocks);
      const flatBlocks = flattenBlocks(context.blocks);
      if (!allBlocksValid) {
        validationErrors.push({
          message: 'All blocks must be valid',
        });
      }

      const hasAtLeastOneNonGroupBlock =
        flatBlocks.length > 0 && flatBlocks.some((b) => b.type !== 'group');

      if (!hasAtLeastOneNonGroupBlock) {
        validationErrors.push({
          message: 'At least one non-group block is required',
        });
      }

      if (!context.name) {
        validationErrors.push({
          message: 'Name is required',
          field: 'name',
        });
      }
      if (!context.description) {
        validationErrors.push({
          message: 'Description is required',
          field: 'description',
        });
      }

      const isTemplateValid = validationErrors.length === 0;

      setValidationErrors(validationErrors);
      if (isValid.current !== isTemplateValid) {
        isValid.current = isTemplateValid;
        onIsValidChangeRef.current?.(isTemplateValid);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [onIsValidChangeRef, setValidationErrors]);

  const projected =
    activeId && overId
      ? getProjection(
          flattenedBlocks,
          activeId,
          overId,
          offsetLeft,
          indentationWidth,
        )
      : null;

  const sortedIds = useMemo(
    () => flattenedBlocks.map((block) => block.id),
    [flattenedBlocks],
  );

  const activeBlock = activeId
    ? flattenedBlocks.find((b) => b.id === activeId)
    : null;

  const handleDragStart = (evt: DragStartEvent) => {
    const {
      active: { id },
    } = evt;
    setActiveId(id);
    setOverId(id);

    const activeBlock = flattenedBlocks.find((b) => b.id === id);
    if (activeBlock) {
      setCurrentPosition({
        parentId: activeBlock.parentId,
        overId: id,
      });
    }

    document.body.style.setProperty('cursor', 'grabbing');
  };

  const handleDragEnd = (evt: DragEndEvent) => {
    const { over, active } = evt;
    resetState();

    if (projected && over) {
      const { parentId } = projected;
      const movedBlock = flattenedBlocks.find((b) => b.id === active.id);
      if (!movedBlock) {
        return;
      }

      if (parentId === movedBlock.parentId) {
        templateBuilderStore.send({
          type: 'moveInsideGroup',
          parentId,
          activeId: active.id,
          overId: over.id,
        });
      } else {
        templateBuilderStore.send({
          type: 'moveBlockBetweenGroups',
          from: movedBlock.parentId,
          to: {
            id: parentId,
            activeId: active.id,
            overId: over.id,
          },
        });
      }
    }
  };

  const handleDragOver = (evt: DragOverEvent) => {
    setOverId(evt?.over?.id ?? null);
  };

  const handleDragMove = (evt: DragMoveEvent) => {
    setOffsetLeft(evt.delta.x);
  };

  const jsonSchema = convertDynamicArtifactToJsonSchema({
    name,
    description,
    blocks,
  }).valueOf();

  return (
    <Box vStack className="gap-4">
      <Box hStack className="w-full justify-end">
        {showJsonSchema && (
          <Drawer>
            <DrawerTrigger asChild>
              <Button>
                <Braces className="size-4" />
                View JSON Schema
              </Button>
            </DrawerTrigger>
            <DrawerContent>
              <VStack gap={2} className="container h-[80vh] overflow-scroll">
                <Button
                  onClick={async () =>
                    await navigator.clipboard.writeText(
                      JSON.stringify(jsonSchema),
                    )
                  }
                >
                  <Copy />
                  Copy
                </Button>
                <DebugJson value={jsonSchema} />
              </VStack>
            </DrawerContent>
          </Drawer>
        )}
        {!isReadonly && (
          <>
            <Switch
              checked={showTips}
              onCheckedChange={() =>
                templateBuilderStore.send({ type: 'toggleTips' })
              }
            />
            <Label>Show Tips</Label>
          </>
        )}
      </Box>
      <Box vStack className="w-full">
        <H4>Output Template Details</H4>
        <FormItem className="max-w-md">
          <Label>Output Type</Label>
          <Select
            value={outputType}
            disabled={isReadonly || isImported}
            onValueChange={(val) => {
              templateBuilderStore.send({
                type: 'setOutputType',
                outputType: val as LlmOutputTypesEnum,
              });
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Output Type" />
            </SelectTrigger>
            <SelectContent>
              {Object.keys(LLM_OUTPUT_TYPE_VARIABLES)
                .filter(
                  (k) =>
                    k !== LlmOutputTypesLookup.DYNAMIC_OUTPUT &&
                    k !== LlmOutputTypesLookup.TRANSLATION,
                )
                .sort()
                .map((type) => (
                  <SelectItem key={type} value={type}>
                    {type.split('_').map(capitalize).join(' ')}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </FormItem>
        <FormItem className="w-full">
          <Label>Name</Label>
          {validationErrors.find((e) => e.field === 'name') && !isReadonly && (
            <P className="text-red-500">
              {validationErrors.find((e) => e.field === 'name')?.message}
            </P>
          )}
          <Input
            value={name}
            disabled={isReadonly || isImported}
            onChange={(e) => {
              templateBuilderStore.send({
                type: 'setName',
                name: e.target.value,
              });
            }}
            placeholder="Name"
          />
        </FormItem>
        <FormItem className="w-full">
          <Label>Description</Label>
          {validationErrors.find((e) => e.field === 'description') &&
            !isReadonly && (
              <P className="text-red-500">
                {
                  validationErrors.find((e) => e.field === 'description')
                    ?.message
                }
              </P>
            )}
          <Textarea
            value={description}
            disabled={isReadonly}
            onChange={(e) => {
              templateBuilderStore.send({
                type: 'setDescription',
                description: e.target.value,
              });
            }}
            placeholder="Provide a description of what the artifact produced by this template should be."
          />
        </FormItem>
      </Box>
      <Box vStack className="w-full">
        <Box hStack className="w-full" spaceBetween>
          <H4>Blocks</H4>
          {Boolean(flattenedBlocks.length > 0) && (
            <Box hStack>
              <Button
                variant="outline"
                className="gap-2"
                onClick={() =>
                  templateBuilderStore.send({
                    type: 'toggleCollapseAll',
                    collapsed: true,
                  })
                }
              >
                <FoldVertical />
                Collapse All
              </Button>
              <Button
                variant="outline"
                className="gap-2"
                onClick={() =>
                  templateBuilderStore.send({
                    type: 'toggleCollapseAll',
                    collapsed: false,
                  })
                }
              >
                <UnfoldVertical />
                Expand All
              </Button>
            </Box>
          )}
        </Box>
        <DndContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDragMove={handleDragMove}
          onDragCancel={resetState}
          collisionDetection={closestCenter}
        >
          <SortableContext
            items={sortedIds}
            strategy={verticalListSortingStrategy}
          >
            {flattenedBlocks.map((block) => {
              const { id, collapsed, depth } = block;
              return (
                <SortableTreeItem
                  key={id}
                  id={id}
                  value={block}
                  depth={id === activeId && projected ? projected.depth : depth}
                  indentationWidth={indentationWidth}
                  indicator={indicator}
                  collapsed={Boolean(collapsed)}
                />
              );
            })}
            {createPortal(
              <DragOverlay
                modifiers={indicator ? [adjustTranslate] : undefined}
                dropAnimation={dropAnimationConfig}
              >
                {activeId && activeBlock ? (
                  <SortableTreeItem
                    id={activeId}
                    depth={activeBlock.depth}
                    value={activeBlock}
                    clone
                    indentationWidth={indentationWidth}
                    collapsed={true}
                  />
                ) : null}
              </DragOverlay>,
              document.body,
            )}
          </SortableContext>
        </DndContext>
        {!isReadonly && !isImported && (
          <BlockPickerPopover parentId={null}>
            <Box className="grid w-full cursor-pointer place-items-center rounded-lg border border-dashed border-gray-8 p-3 hover:border-gray-10">
              <Box hStack>
                <Plus />
                <P>Add a block</P>
              </Box>
            </Box>
          </BlockPickerPopover>
        )}
      </Box>
    </Box>
  );
};
