import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { produce } from 'immer';
import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { cacheUtils } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Form,
  FormField,
  FormItem,
  FormLabel,
  HStack,
  Input,
  LinearLogo,
  NewButton,
  VStack,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { isLinearIssue } from '@eluve/utils';

import { appointmentReviewLinksFragment } from './operations';
import { useAppointmentParams } from './useAppointmentParams';

const reviewLinkFormSchema = z.object({
  link: z.string().min(4),
  label: z.string().optional(),
});

const createAppointmentLinkMutation = graphql(`
  mutation createAppointmentLink($input: AppointmentReviewLinksInsertInput!) {
    insertAppointmentReviewLinksOne(object: $input) {
      __typename
      id
      link
      label
    }
  }
`);

type ReviewLinkForm = z.infer<typeof reviewLinkFormSchema>;
export const AddAppointmentReviewLinkDialogForm: React.FC<{
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
}> = ({ isDialogOpen, setIsDialogOpen }) => {
  const { tenantId, appointmentId } = useAppointmentParams();

  const form = useForm<ReviewLinkForm>({
    resolver: zodResolver(reviewLinkFormSchema),
    defaultValues: {
      link: '',
    },
  });

  const [createAppointmentLink] = useMutation(createAppointmentLinkMutation, {
    onError: () => toast.error('Failed to add link'),
    optimisticResponse: ({ input: { link, label } }) => ({
      insertAppointmentReviewLinksOne: {
        __typename: 'AppointmentReviewLinks' as const,
        id: 'temp-id',
        link: link ?? '',
        label: label ?? '',
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: appointmentReviewLinksFragment,
          key: { id: appointmentId },
        },
        (existing) => {
          const newLink = data?.insertAppointmentReviewLinksOne;

          if (!existing || !newLink) {
            return existing;
          }

          return produce(existing, (draft) => {
            draft.review_links.push(newLink);
          });
        },
      );
    },
  });

  const { control } = form;

  const link = form.watch('link');
  const isLinear = isLinearIssue(link);

  const onSubmit = (data: ReviewLinkForm) => {
    const link = isLinear
      ? `https://linear.app/eluve/issue/${data.link}`
      : data.link;

    const label = isLinear ? data.link : data.label;

    if (!link.startsWith('http')) {
      toast.error(
        'Link must start with http or https or be a valid Linear issue ID',
      );
      return;
    }

    createAppointmentLink({
      variables: {
        input: {
          appointmentId,
          tenantId,
          link,
          label,
        },
      },
    });

    form.reset();
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add a Link or Linear issue ID</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <VStack>
              <FormField
                control={control}
                name="link"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Link</FormLabel>
                    <HStack>
                      <Input
                        placeholder="Enter a hyperlink or Linear issue number"
                        {...field}
                      />
                      {isLinear && (
                        <TooltipLabel label="Linear issue detected">
                          <LinearLogo className="size-8" />
                        </TooltipLabel>
                      )}
                    </HStack>
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="label"
                render={({ field }) => {
                  if (isLinear) {
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    return <></>;
                  }

                  return (
                    <FormItem className="w-full">
                      <FormLabel>Label</FormLabel>
                      <Input placeholder="Label (Optional)" {...field} />
                    </FormItem>
                  );
                }}
              />
              <NewButton text="Save" submit />
            </VStack>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
