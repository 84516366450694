import { CardContent, CardHeader } from './card';
import { FCC } from './types';

export const EluveCardHeader: FCC = ({ children }) => {
  return (
    <CardHeader className="flex h-10 flex-row items-center justify-between justify-items-center space-y-0 rounded-t-xl border-b bg-gray-1 p-0 px-5 tracking-normal">
      {children}
    </CardHeader>
  );
};

export const EluveCardContent: FCC = ({ children }) => {
  return (
    <CardContent className="flex flex-col gap-5 rounded-b-xl bg-white p-5 md:flex-col">
      {children}
    </CardContent>
  );
};
