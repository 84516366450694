import React from 'react';

import {
  useAppointmentActivity,
  useSummary,
} from '@eluve/frontend-appointment-hooks';

import { useUserLocations } from '../../hooks/useUserLocation';

import { SignNoteOnEluveButton } from './SignNoteOnEluveButton';
import { SyncToEhrButton } from './SyncToEhrButton';

export interface SignAndSyncAppointmentButtonProps {}

export const SignAndSyncAppointmentButton: React.FC<
  SignAndSyncAppointmentButtonProps
> = () => {
  const { isSummaryAvailable } = useSummary();
  const { isSummarizationInProgress } = useAppointmentActivity();

  const { isAnyLocationEhrEnabled: userHasEhrEnabled } = useUserLocations();

  if (isSummarizationInProgress || !isSummaryAvailable) {
    return null;
  }

  return (
    <>
      {userHasEhrEnabled && <SyncToEhrButton />}
      {!userHasEhrEnabled && <SignNoteOnEluveButton />}
    </>
  );
};
