import {
  ColumnFiltersState,
  PaginationState,
  Row,
  TableOptions,
  Updater,
  getCoreRowModel,
} from '@tanstack/react-table';
import {
  Parser,
  createParser,
  parseAsArrayOf,
  parseAsInteger,
  parseAsString,
  useQueryState,
  useQueryStates,
} from 'nuqs';
import React, { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';

import { DataTableFilterField, ExtendedSortingState } from './datatable-types';

export const sortingItemSchema = z.object({
  id: z.string(),
  desc: z.boolean(),
});

/**
 * Creates a parser for TanStack Table sorting state.
 * @param originalRow The original row data to validate sorting keys against.
 * @returns A parser for TanStack Table sorting state.
 */
export const getSortingStateParser = <TData extends Record<string, unknown>>(
  originalRow?: Row<TData>['original'],
) => {
  const validKeys = originalRow ? new Set(Object.keys(originalRow)) : null;

  return createParser<ExtendedSortingState<TData>>({
    parse: (value) => {
      try {
        const parsed = JSON.parse(value);
        const result = z.array(sortingItemSchema).safeParse(parsed);

        if (!result.success) return null;

        if (validKeys && result.data.some((item) => !validKeys.has(item.id))) {
          return null;
        }

        return result.data as ExtendedSortingState<TData>;
      } catch {
        return null;
      }
    },
    serialize: (value) => JSON.stringify(value),
    eq: (a, b) =>
      a.length === b.length &&
      a.every(
        (item, index) =>
          item.id === b[index]?.id && item.desc === b[index]?.desc,
      ),
  });
};

type DataTableQueryStateArgs<TData extends Record<string, unknown>> = {
  filterFields?: DataTableFilterField<TData>[];
  defaultSort?: ExtendedSortingState<TData>;
};

export const useDataTableQueryState = <TData extends Record<string, unknown>>(
  args: DataTableQueryStateArgs<TData> = {},
) => {
  const { filterFields = [], defaultSort = [] } = args;

  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [perPage, setPerPage] = useQueryState(
    'perPage',
    parseAsInteger.withDefault(10),
  );

  const pagination: PaginationState = {
    pageIndex: page - 1, // zero-based index -> one-based index
    pageSize: perPage,
  };

  const [sorting, setSorting] = useQueryState(
    'sort',
    getSortingStateParser<TData>().withDefault(defaultSort),
  );

  // Create parsers for each filter field
  const filterParsers = useMemo(() => {
    return filterFields.reduce<
      Record<string, Parser<string> | Parser<string[]>>
    >((acc, field) => {
      if (field.options) {
        // Faceted filter
        acc[field.id] = parseAsArrayOf(parseAsString, ',');
      } else {
        // Search filter
        acc[field.id] = parseAsString;
      }
      return acc;
    }, {});
  }, [filterFields]);

  // TODO(jesse)[ELU-2882] Improve type safety
  const [filters, setFilters] = useQueryStates(filterParsers);
  const initialColumnFilters: ColumnFiltersState = useMemo(() => {
    return Object.entries(filters).reduce<ColumnFiltersState>(
      (filters, [key, value]) => {
        if (value !== null) {
          filters.push({
            id: key,
            value: Array.isArray(value) ? value : [value],
          });
        }
        return filters;
      },
      [],
    );
  }, [filters]);

  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>(initialColumnFilters);

  // Memoize computation of searchableColumns and filterableColumns
  const { searchableColumns, filterableColumns } = React.useMemo(() => {
    return {
      searchableColumns: filterFields.filter((field) => !field.options),
      filterableColumns: filterFields.filter((field) => field.options),
    };
  }, [filterFields]);

  const onColumnFiltersChange = useCallback(
    (updaterOrValue: Updater<ColumnFiltersState>) => {
      setColumnFilters((prev) => {
        const next =
          typeof updaterOrValue === 'function'
            ? updaterOrValue(prev)
            : updaterOrValue;

        const filterUpdates = next.reduce<
          Record<string, string | string[] | null>
        >((acc, filter) => {
          if (searchableColumns.find((col) => col.id === filter.id)) {
            // For search filters, use the value directly
            acc[filter.id] = filter.value as string;
          } else if (filterableColumns.find((col) => col.id === filter.id)) {
            // For faceted filters, use the array of values
            acc[filter.id] = filter.value as string[];
          }
          return acc;
        }, {});

        prev.forEach((prevFilter) => {
          if (!next.some((filter) => filter.id === prevFilter.id)) {
            filterUpdates[prevFilter.id] = null;
          }
        });

        setPage(1);
        setFilters(filterUpdates);
        return next;
      });
    },
    [filterableColumns, searchableColumns, setFilters, setPage],
  );

  const onPaginationChange = (updaterOrValue: Updater<PaginationState>) => {
    if (typeof updaterOrValue === 'function') {
      const newPagination = updaterOrValue(pagination);
      setPage(newPagination.pageIndex + 1);
      setPerPage(newPagination.pageSize);
    } else {
      setPage(updaterOrValue.pageIndex + 1);
      setPerPage(updaterOrValue.pageSize);
    }
  };

  const reactTableOptions: Omit<TableOptions<TData>, 'data' | 'columns'> = {
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange,
    onColumnFiltersChange,
    onSortingChange: (updaterOrValue) => {
      if (typeof updaterOrValue === 'function') {
        const newSorting = updaterOrValue(
          sorting,
        ) as ExtendedSortingState<TData>;
        setSorting(newSorting);
      }
    },
  };

  return {
    page,
    perPage,
    pagination,
    sorting,
    filters,
    reactTableOptions,
  };
};
