import { z } from 'zod';

const soapSchema = z
  .object({
    SubjectiveChiefComplaint: z.string().optional(),
    SubjectiveAnamnesis: z.string().optional(),
    Objective: z.string().optional(),
    Assessment: z.string().optional(),
    Planning: z.string().optional(),
  })
  .passthrough();

const drugRecommendationSchema = z.array(
  z
    .object({
      salesItemId: z.string().optional(),
      salesItemName: z.string().optional(),
    })
    .passthrough(),
);

export const chartSchema = z.object({
  EncounterId: z.string(),
  PatientId: z.string(),
  soap: soapSchema,
  drugRecommendation: drugRecommendationSchema,
});

export type Chart = z.infer<typeof chartSchema>;
