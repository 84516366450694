import React, { useState } from 'react';
import { useEffect, useRef } from 'react';

import { useNamedLogger } from '@eluve/logger';

export const AudioPlayer: React.FC<{ audioUrl?: string; seekTo?: number }> = ({
  audioUrl,
  seekTo = 0,
}) => {
  const logger = useNamedLogger('AudioPlayer');
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioSrc, setAudioSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    let blobAudioUrl: string | undefined = undefined;
    const audioElement = audioRef.current;
    if (!audioElement || !audioUrl) return;

    if ('MediaSource' in window) {
      const mediaSource = new MediaSource();
      const url = URL.createObjectURL(mediaSource);
      blobAudioUrl = url;
      setAudioSrc(url);

      let codecs: string;
      let format: string;
      if (audioUrl.includes('webm')) {
        codecs = 'opus';
        format = 'webm';
      } else {
        codecs = 'mp4a.40.2';
        format = 'mp4';
      }

      mediaSource.addEventListener('sourceopen', async () => {
        try {
          const sourceBuffer = mediaSource.addSourceBuffer(
            `audio/${format}; codecs="${codecs}"`,
          );
          const response = await fetch(audioUrl);
          const data = await response.arrayBuffer();
          sourceBuffer.appendBuffer(data);

          sourceBuffer.addEventListener('updateend', () => {
            mediaSource.endOfStream();
          });
        } catch {
          logger.warn(`Failed to open audio for URL ${audioUrl}`);
        }
      });
    }

    return () => {
      if (blobAudioUrl) {
        URL.revokeObjectURL(blobAudioUrl);
      }
    };
  }, [audioUrl, logger]);

  useEffect(() => {
    const seek = async () => {
      if (audioRef.current && audioRef.current.src && seekTo) {
        try {
          audioRef.current.currentTime = seekTo;
          audioRef.current.play();
        } catch (e) {
          logger.warn(`Failed to seek audio for URL ${audioUrl}`);
        }
      }
    };

    seek();
  }, [audioUrl, logger, seekTo]);

  return <audio ref={audioRef} src={audioSrc} controls preload="metadata" />;
};
