import { useCompleteFragment } from '@eluve/apollo-client';
import { useUserIdFromSession } from '@eluve/session-helpers';

import { useAppointmentId } from './appointment.context';
import { appointmentReadonlyDataFragment } from './operations';

export const useIsAppointmentReadonly = () => {
  const userId = useUserIdFromSession();
  const appointmentId = useAppointmentId();

  const { userId: appointmentUserId, is_closed_for_modification } =
    useCompleteFragment({
      fragment: appointmentReadonlyDataFragment,
      key: {
        id: appointmentId,
      },
    });

  if (userId !== appointmentUserId) {
    return true;
  }

  return is_closed_for_modification ?? false;
};
