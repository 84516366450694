import { graphql } from '@eluve/graphql.tada';

export const appointmentMedicineRecommendationFragment = graphql(`
  fragment MedicineRecommendation on AppointmentMedicineRecommendations
  @_unmask {
    __typename
    id
    name
    ingredients
    recommendationSourceId
    recommendationSource
    externalRawData
  }
`);

export const appointmentMedicineFragment = graphql(`
  fragment Medicine on AppointmentMedicines @_unmask {
    __typename
    id
    name
    ingredients
    externalMedicineId
    externalRawData
  }
`);

export const appointmentMedicinesFragment = graphql(
  `
    fragment Medicines on Appointments @_unmask {
      id
      __typename
      medicines {
        ...Medicine
      }
    }
  `,
  [appointmentMedicineFragment],
);

export const appointmentMedicineRecommendationsFragment = graphql(
  `
    fragment MedicineRecommendations on Appointments @_unmask {
      id
      __typename
      medicine_recommendations {
        ...MedicineRecommendation
      }
    }
  `,
  [appointmentMedicineRecommendationFragment],
);

export const getAppointmentMedicineRecommendations = graphql(
  `
    query getAppointmentMedicines($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        ...MedicineRecommendations
        ...Medicines
      }
    }
  `,
  [appointmentMedicineRecommendationsFragment, appointmentMedicinesFragment],
);

export const addAppointmentMedicineMutation = graphql(
  `
    mutation acceptMedicineRecommendation(
      $appointmentId: uuid!
      $name: String!
      $ingredients: String
      $externalMedicineId: String
      $rawData: jsonb
    ) {
      insertAppointmentMedicinesOne(
        object: {
          appointmentId: $appointmentId
          externalMedicineId: $externalMedicineId
          name: $name
          ingredients: $ingredients
          externalRawData: $rawData
        }
        onConflict: { constraint: uq_appointment_medicine, updateColumns: [] }
      ) {
        ...Medicine
      }
    }
  `,
  [appointmentMedicineFragment],
);

export const removeAppointmentMedicineMutation = graphql(`
  mutation removeMedicine(
    $appointmentId: uuid!
    $appointmentMedicineId: uuid!
  ) {
    deleteAppointmentMedicines(
      where: {
        appointmentId: { _eq: $appointmentId }
        id: { _eq: $appointmentMedicineId }
      }
    ) {
      returning {
        __typename
        id
      }
    }
  }
`);
