import { graphql } from '@eluve/graphql.tada';

export const getTenantOutputTemplatesQuery = graphql(`
  query getTenantOutputTemplates {
    externalEhr {
      __typename
      id
      vendor
      domain
    }
    llmOutputTemplates {
      __typename
      id
      name
      description
      tenantId
      createdAt
      isActive
      external_chart_templates {
        __typename
        id
        externalEhrId
        externalChartTemplateId
        externalChartTemplateOwnerId
      }
    }
  }
`);

export const updateTenantOutputTemplateMutation = graphql(`
  mutation updateTenantOutputTemplate($isActive: Boolean!, $templateId: uuid!) {
    updateLlmOutputTemplatesByPk(
      pkColumns: { id: $templateId }
      _set: { isActive: $isActive }
    ) {
      __typename
      id
      isActive
    }
  }
`);

export const llmModelPromptTemplatesDetailsFragment = graphql(`
  fragment LlmModelPromptTemplatesDetails on LlmModelPromptTemplates @_unmask {
    __typename
    id
    isDefault
    isActive
    modelArgsId
    promptTemplateId
    feedback_aggregates {
      __typename
      id
      averageRating
      negativeFeedbackCount
      positiveFeedbackCount
      totalFeedbackCount
    }
    model_args {
      __typename
      id
      modelType
      args
    }
  }
`);

export const promptTemplateModelsFragment = graphql(
  `
    fragment PromptTemplateModels on PromptTemplates @_unmask {
      __typename
      id
      prompt_models {
        ...LlmModelPromptTemplatesDetails
      }
    }
  `,
  [llmModelPromptTemplatesDetailsFragment],
);

export const promptTemplateSettingsFragment = graphql(`
  fragment PromptTemplateSettings on PromptTemplates @_unmask {
    __typename
    outputType
    isActive
    isCurrentDefault
    userSettingsAggregate {
      __typename
      aggregate {
        count
      }
    }
  }
`);

// TODO(david)[ELU-3004]: update this query to show all variants, not just current default
export const getPrompTemplatesQuery = graphql(
  `
    query getPromptTemplates {
      promptTemplates {
        __typename
        id
        name
        outputType
        createdAt
        prompt_template_variants(where: { isCurrentDefault: { _eq: true } }) {
          __typename
          promptTemplateId
          variantNumber
          template
        }
        prompt_models {
          __typename
          id
          llm_outputs {
            __typename
            id
            feedback {
              __typename
              id
              rating
            }
          }
        }
        ...PromptTemplateSettings
      }
    }
  `,
  [promptTemplateSettingsFragment],
);
