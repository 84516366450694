import { useCompleteFragment } from '@eluve/apollo-client';
import { useNamedLogger } from '@eluve/logger';
import { MessageClient } from '@eluve/message-client';
import {
  HumanFriendlyError,
  SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS,
  WebAppContract,
  getHumanFriendlyError,
} from '@eluve/vendor';

import { useAppointmentContext } from './appointment.context';
import { appointmentDoctorInteractionFragment } from './operations';
import { useAppointmentName } from './useAppointmentName';
import { useAppointmentPatient } from './useAppointmentPatient';
import { useExternalAppointmentId } from './useExternalAppointmentId';
import { useExternalTemplateId } from './useExternalTemplateId';
import { useSummary } from './useSummary';
import { SyncToEhrResponse, useSyncToEhr } from './useSyncToEhr';

type SyncNoteToEhrResponse = SyncToEhrResponse & {
  humanFriendlyError?: HumanFriendlyError;
};

export const useSyncNoteToEhr = ({
  messageClient,
}: {
  messageClient: MessageClient<WebAppContract>['proxy'];
}) => {
  const logger = useNamedLogger(useSyncNoteToEhr.name);

  const { tenantId, appointmentId } = useAppointmentContext();

  const syncToEhr = useSyncToEhr({ messageClient });
  const patient = useAppointmentPatient();
  const appointmentExternalId = useExternalAppointmentId();
  const appointmentName = useAppointmentName();
  const { summary } = useSummary();
  const templateExternalId = useExternalTemplateId();

  const data = useCompleteFragment({
    fragment: appointmentDoctorInteractionFragment,
    key: { id: appointmentId },
    strict: false,
  });

  const externalPatientInfo = patient?.external_patients_info?.[0];
  const externalEhr = externalPatientInfo?.external_ehr;

  const patientExternalId = externalPatientInfo?.externalPatientId;

  const interaction = data?.doctor_interaction;
  const additionalUserNotes = interaction?.additionalNotes ?? null;

  return async ({
    shouldSignExternalChart = false,
  }: {
    shouldSignExternalChart: boolean;
  }): Promise<SyncNoteToEhrResponse> => {
    try {
      const response = await syncToEhr({
        tenantId,
        shouldSignExternalChart,
        appointmentId,
        vendor: externalEhr!.vendor,
        domain: externalEhr!.domain,
        patientExternalId: patientExternalId!,
        appointmentName,
        additionalUserNotes,
        summary,
        appointmentExternalId,
        templateExternalId: templateExternalId ?? undefined,
      });
      logger.info('Sync to EHR response', response);

      if (!response.ok || !response.data) {
        logger.warn('SyncToEhr response was not successful', {
          response,
        });
        const humanFriendlyError = getHumanFriendlyError(response.error);
        return { ...response, humanFriendlyError };
      }

      return response;
    } catch (error) {
      logger.error('Error syncing to EHR', {
        error,
        errorMessage: (error as Error)?.message,
      });
      return {
        ok: false,
        humanFriendlyError: SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS.TIMEOUT,
      };
    }
  };
};
