import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { toast } from 'sonner';

import { cacheUtils } from '@eluve/apollo-client';
import { MedicineDetails } from '@eluve/blocks';
import { H4, HStack, Icon, VStack, textStyles } from '@eluve/components';

import {
  addAppointmentMedicineMutation,
  appointmentMedicinesFragment,
  getAppointmentMedicineRecommendations,
  removeAppointmentMedicineMutation,
} from './AppointmentMedicine.operations';
import { Medication, SearchableMedicines } from './SearchableMedicines';

export interface MedicineRecommendationProps {
  tenantId: string;
  appointmentId: string;
  shouldPollForResults?: boolean;
  isReadonly?: boolean;
}

export const MedicineRecommendations: React.FC<MedicineRecommendationProps> = ({
  tenantId,
  appointmentId,
  shouldPollForResults,
  isReadonly,
}) => {
  const { data, loading, startPolling, stopPolling } = useQuery(
    getAppointmentMedicineRecommendations,
    {
      variables: {
        tenantId,
        appointmentId,
      },
    },
  );

  useEffect(() => {
    if (shouldPollForResults && !isReadonly) {
      startPolling(2500);
      return () => {
        stopPolling();
      };
    }
  }, [shouldPollForResults, isReadonly, startPolling, stopPolling]);

  const selectedMedicines = data?.appointmentsByPk?.medicines ?? [];

  const medicineRecommendations = (
    data?.appointmentsByPk?.medicine_recommendations ?? []
  ).filter((m) => !selectedMedicines.find((s) => s.name === m.name));

  const [addMedication] = useMutation(addAppointmentMedicineMutation, {
    onError: () => toast.error('Failed to accept recommendation'),
    optimisticResponse: (data) => ({
      insertAppointmentMedicinesOne: {
        __typename: 'AppointmentMedicines' as const,
        id: 'optimistic-id',
        name: data.name,
        ingredients: data.ingredients ?? null,
        externalMedicineId: data.externalMedicineId ?? null,
        appointmentId,
        tenantId,
        externalRawData: null,
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: appointmentMedicinesFragment,
          key: { id: appointmentId },
        },
        (existing) => {
          if (!existing || !data?.insertAppointmentMedicinesOne) {
            return existing;
          }
          return {
            ...existing,
            medicines: [
              ...existing.medicines,
              data.insertAppointmentMedicinesOne,
            ],
          };
        },
      );
    },
  });

  const [removeMedication] = useMutation(removeAppointmentMedicineMutation, {
    onError: () => toast.error('Failed to remove medicine'),
    optimisticResponse: (data) => ({
      deleteAppointmentMedicines: {
        returning: [
          {
            __typename: 'AppointmentMedicines' as const,
            id: data.appointmentMedicineId,
          },
        ],
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: appointmentMedicinesFragment,
          key: { id: appointmentId },
        },
        (existing) => {
          if (!existing || !data?.deleteAppointmentMedicines) {
            return existing;
          }
          return {
            ...existing,
            medicines: [
              ...existing.medicines.filter(
                (m) =>
                  m.id !== data.deleteAppointmentMedicines?.returning?.[0]?.id,
              ),
            ],
          };
        },
      );
    },
  });

  const onAcceptRecommendation = (
    appointmentMedicineRecommendationId: string,
  ) => {
    if (isReadonly) {
      return;
    }
    const recommendation = medicineRecommendations.find(
      (r) => r.id === appointmentMedicineRecommendationId,
    );
    if (!recommendation) {
      return;
    }

    addMedication({
      variables: {
        appointmentId,
        name: recommendation.name,
        ingredients: recommendation.ingredients,
        externalMedicineId: recommendation.recommendationSourceId,
        rawData: recommendation.externalRawData,
      },
    });
  };

  const onItemAdded = async (medication: Medication) => {
    await addMedication({
      variables: {
        appointmentId,
        name: medication.name,
        ingredients: medication.ingredients,
        externalMedicineId: medication.id,
        rawData: medication.rawData,
      },
    });
  };

  const onItemRemoved = async (medication: Medication) => {
    removeMedication({
      variables: {
        appointmentId,
        appointmentMedicineId: medication.id,
      },
    });
  };

  if (loading) {
    return null;
  }

  if (isReadonly && !medicineRecommendations.length) {
    return null;
  }

  return (
    <VStack gap={4} className="mb-10">
      <HStack gap={3}>
        <H4>Medication</H4>
      </HStack>
      <VStack gap={6}>
        <HStack>
          <SearchableMedicines
            disabled={isReadonly}
            onItemAdded={onItemAdded}
            onItemRemoved={onItemRemoved}
            selectedItems={selectedMedicines.map((m) => ({
              id: m.id,
              name: m.name,
              ingredients: m.ingredients ?? undefined,
              externalMedicineId: m.externalMedicineId ?? undefined,
            }))}
          />
        </HStack>
        {Boolean(medicineRecommendations.length) && !isReadonly && (
          <VStack gap={3}>
            <h6
              className={textStyles.body({
                weight: 'semibold',
                size: 's',
                className: 'uppercase text-brandGray600',
              })}
            >
              Recommended Medication
            </h6>

            <HStack gap={3} wrap>
              {medicineRecommendations.map(({ name, ingredients, id }) => {
                return (
                  <MedicineDetails
                    onClick={() => {
                      onAcceptRecommendation(id);
                    }}
                    startAdornment={
                      <div className="pl-1">
                        <Icon
                          size="xs"
                          name="Plus"
                          className="text-brandGray600"
                        />
                      </div>
                    }
                    key={id}
                    name={name}
                    ingredients={ingredients ?? ''}
                    type="outlineDashed"
                  />
                );
              })}
            </HStack>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};
