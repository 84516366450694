import { useState } from 'react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Combobox,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxSelectButton,
  ComboboxSelectCheck,
  HStack,
  NewButton,
  P,
  VStack,
} from '@eluve/components';
import {
  DEFAULT_LANGUAGE,
  SupportedLanguagesByCode,
} from '@eluve/language-utils';

export const Translator: React.FC<{
  originalContent: string;
  setTranslatedContent: (c: string) => void;
}> = ({ originalContent, setTranslatedContent }) => {
  const apiClient = useApiClient();
  const [outputLanguageCode, setOutputLanguageCode] =
    useState(DEFAULT_LANGUAGE);
  const [isTranslating, setIsTranslating] = useState(false);

  const translate = async () => {
    try {
      setIsTranslating(true);
      const response = await apiClient.translation.translate({
        body: {
          outputLanguage: SupportedLanguagesByCode[outputLanguageCode]!,
          content: originalContent,
        },
      });
      if (response.status === 200) {
        setTranslatedContent(response.body.content as string);
      } else {
        toast.error(`Failed to translate: ${response.status}`);
      }
    } catch (e) {
      toast.error(`Translation failed. ${(e as Error)?.message}`);
    } finally {
      setIsTranslating(false);
    }
  };

  return (
    <VStack>
      <HStack>
        <Combobox>
          <ComboboxSelectButton className="w-full">
            <P>
              {SupportedLanguagesByCode[outputLanguageCode] ??
                'Select Language'}
            </P>
          </ComboboxSelectButton>
          <ComboboxDropdown searchPlaceholder="Search languages">
            {Object.keys(SupportedLanguagesByCode).map((languageCode) => (
              <ComboboxOption
                key={languageCode}
                onSelect={async () => {
                  setOutputLanguageCode(languageCode);
                }}
              >
                <ComboboxSelectCheck
                  className="mr-2"
                  selected={languageCode === outputLanguageCode}
                />
                {SupportedLanguagesByCode[languageCode]}
              </ComboboxOption>
            ))}
          </ComboboxDropdown>
        </Combobox>
      </HStack>
      <HStack>
        <NewButton
          size="s"
          disabled={isTranslating}
          type="outline"
          onClick={translate}
          text={isTranslating ? 'Translating...' : 'Translate'}
        />
      </HStack>
    </VStack>
  );
};
