import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import partition from 'lodash/partition';
import { FileWarning } from 'lucide-react';
import { useState } from 'react';
import { match } from 'ts-pattern';

import { useApiClient } from '@eluve/api-client-provider';
import { P, useToast } from '@eluve/components';
import {
  useEHRSyncStore,
  useEHRSyncStoreApi,
} from '@eluve/frontend-feature-ehr-sync';
import { useNamedLogger } from '@eluve/logger';
import { useUserIdFromSession } from '@eluve/session-helpers';
import { useEluveExtExists, useExternalEhrs } from '@eluve/smart-blocks';
import { getHumanFriendlyError, getVendorProvider } from '@eluve/vendor';

import { appConfig } from '../../../../config';
import { messageClient } from '../../utils/post-messenger';

import { useEhrStatusDetails } from './useEhrStatusDetails';

const errorToastDuration = 2000;
const successToastDuration = 5000;

export const useImportDataFromEhr = (tenantId: string) => {
  const logger = useNamedLogger('useImportDataFromEhr');
  const [importResults, setImportResults] = useState<
    { ok: boolean; error?: string } | undefined
  >(undefined);
  const { eluveExtExists, eluveExtVersionSatisfies } = useEluveExtExists();
  const externalEhrs = useExternalEhrs(tenantId);
  const userId = useUserIdFromSession();
  const { toast } = useToast();
  const isDataImporting = useEHRSyncStore((state) => state.isDataImporting);
  const setIsDataImporting = useEHRSyncStore(
    (state) => state.setIsDataImporting,
  );
  const store = useEHRSyncStoreApi();
  const apiClient = useApiClient();
  const { updateData } = useEhrStatusDetails();

  const sendLog = (
    level: 'info' | 'error' | 'warn' | 'debug',
    message: string,
    extra: Record<string, any> = {},
  ) => {
    logger[level](message, {
      userId,
      tenantId,
      ...extra,
    });
  };

  const isImportAllowed = eluveExtExists && eluveExtVersionSatisfies;
  const isMobileDevice = /Mobi|Android|iPhone|iPad|iPod/.test(
    navigator.userAgent,
  );
  const isSupportedBrowser =
    /Chrome|Edg/.test(navigator.userAgent) && !isMobileDevice;

  const importDataFromEhr = async ({
    showToast = true,
  }: {
    showToast?: boolean;
  } = {}) => {
    if (!externalEhrs.length) {
      if (showToast) {
        toast({
          duration: errorToastDuration,
          title: 'No EHR associations',
          description:
            'Locations are not associated with an external EHR. Please ask your admin to associate a location with an EHR.',
          variant: 'destructive',
        });
      }
      return;
    }

    setIsDataImporting(true);

    try {
      sendLog('info', 'Import EHR data - STARTED', {
        externalEhrs,
      });

      const importResults = await Promise.all(
        externalEhrs.map(async (externalEhr) => {
          const vendorProvider = getVendorProvider(externalEhr.vendor);
          const vendorSyncConfig = vendorProvider.getSyncConfig();

          const startDate =
            vendorSyncConfig.syncStartDate ?? subDays(new Date(), 3);
          const endDate =
            vendorSyncConfig.syncEndDate ?? addDays(new Date(), 7);

          return match(externalEhr.vendor)
            .with('JANE', 'TEBRA', 'MOVEMENT_X', async (matchedVendor) => {
              const importResponse = await messageClient.fetchEhrData({
                vendor: matchedVendor,
                domain: externalEhr.domain,
                tenantId,
                data: {
                  startDate: startDate.valueOf(),
                  endDate: endDate.valueOf(),
                },
              });

              return {
                ...importResponse,
                externalEhr,
              };
            })
            .with('EXPERITY', async () => {
              const importResponse = await messageClient.fetchEhrData({
                vendor: 'EXPERITY',
                domain: externalEhr.domain,
                tenantId,
                data: {
                  startDate: startDate.valueOf(),
                  tenantName: 'CRHGA',
                  locationName: 'BUFORD',
                  externalLocationId: '5C4F4F38-E150-E611-9401-38EAA73015B8',
                },
              });
              return {
                ...importResponse,
                externalEhr,
              };
            })
            .with('SILOAM', 'BLVD', async (vendor) => {
              const results =
                await apiClient.appointments.importAppointmentsFromApi({
                  body: {
                    vendor,
                    domain: externalEhr.domain,
                    data: {
                      startDate: startDate.valueOf(),
                      endDate: endDate.valueOf(),
                    },
                  },
                  params: { tenantId },
                });
              if (results.status === 201) {
                updateData({
                  eluveDomains: {
                    [appConfig.VITE_API_DOMAIN]: {
                      tenants: {
                        [tenantId]: {
                          vendors: {
                            [vendor]: {
                              domains: {
                                [externalEhr.domain]: {
                                  status: 'CONNECTED',
                                  syncedAt: Date.now(),
                                  error: null,
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                });
                return {
                  ok: true,
                  externalEhr,
                };
              }
              return {
                ok: false,
                error: `Failed to import data. Response from API: ${results.body}`,
                externalEhr,
              };
            })
            .exhaustive();
        }),
      );

      const [successfulImports, failedImports] = partition(
        importResults,
        (result) => result.ok,
      );

      const failedImportError = failedImports.find(
        (failedImport) => failedImport.error,
      )?.error;
      const failedImportErrorMessage = failedImportError
        ? getHumanFriendlyError(failedImportError).message
        : '';
      setImportResults({
        ok: Boolean(successfulImports.length),
        error: failedImportErrorMessage,
      });
      sendLog('info', 'Import EHR data - COMPLETED', {
        externalEhrs: JSON.stringify(externalEhrs),
        successfulImports: JSON.stringify(successfulImports),
        failedImports: JSON.stringify(failedImports),
      });

      if (successfulImports.length) {
        store.setState({
          lastSuccessfulEhrSyncAt: new Date().toISOString(),
        });

        if (showToast) {
          toast({
            duration: successToastDuration,
            variant: 'success',
            title: 'Success!',
            description: (
              <>
                <P>Imported patients and appointments from:</P>
                <ul>
                  {successfulImports.map((result) => (
                    <li key={result.externalEhr.id}>
                      {result.externalEhr.domain}
                    </li>
                  ))}
                </ul>
                {Boolean(failedImports.length) && (
                  <>
                    <div className="mt-4 flex items-center gap-2 text-deprecatedOrange-700">
                      <FileWarning className="h-4 w-4" />
                      <P className="text-deprecatedOrange-700">
                        Failed importing from:
                      </P>
                    </div>
                    <ul>
                      {failedImports.map((result) => (
                        <li
                          className="text-brandGray900"
                          key={result.externalEhr.id}
                        >
                          {result.externalEhr.domain}
                        </li>
                      ))}
                      {failedImportErrorMessage && (
                        <div>
                          <hr className="my-4 h-px border-0 bg-brandGray300" />
                          <P className="text-brandGray900">
                            {failedImportErrorMessage}
                          </P>
                        </div>
                      )}
                    </ul>
                  </>
                )}
              </>
            ),
          });
        }
      } else {
        const isUnhandledError = Boolean(
          failedImports.find(
            (failed) =>
              getHumanFriendlyError(failed.error).title === 'GENERIC_ERROR',
          ),
        );

        sendLog(
          isUnhandledError ? 'error' : 'warn',
          'Failed to import data from EHR',
          {
            externalEhrs: JSON.stringify(externalEhrs),
            errors: JSON.stringify(
              failedImports?.map((failed) => failed.error),
            ),
            data: JSON.stringify(failedImports?.map((failed) => failed.data)),
          },
        );
        if (showToast) {
          toast({
            duration: errorToastDuration,
            title: 'Failed to import data',
            description: (
              <>
                <P className="text-gray-12">
                  Failed importing patients and appointments from:
                </P>
                <ul>
                  {failedImports.map((result) => (
                    <li key={result.externalEhr.id}>
                      {result.externalEhr.domain}
                    </li>
                  ))}
                </ul>

                {Boolean(failedImportErrorMessage) && (
                  <div className="mt-4 flex flex-row items-center gap-2 text-gray-12">
                    <P className="text-white">{failedImportErrorMessage}</P>
                  </div>
                )}
              </>
            ),
            variant: 'destructive',
          });
        }
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Refresh the page and try again.';

      if (showToast) {
        toast({
          duration: errorToastDuration,
          title: 'Failed to import data',
          description: errorMessage,
          variant: 'destructive',
        });
      }
      sendLog('error', 'Import EHR data - FAILED UNEXPECTEDLY', {
        externalEhrs: JSON.stringify(externalEhrs),
        errorMessage: (error as Error)?.message,
        error,
      });
    } finally {
      setIsDataImporting(false);
    }
  };

  return {
    importResults,
    importDataFromEhr,
    isDataImporting: Boolean(isDataImporting),
    isImportAllowed,
    isSupportedBrowser,
  };
};
