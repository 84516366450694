import { graphql } from 'gql.tada';

import { tenantPendingApprovalFragment } from './TenantApprovalToggle';

export const getAdminTenantsSummaryQuery = graphql(
  `
    query getAdminTenantSummary {
      tenants(where: { deletedAt: { _isNull: true } }) {
        __typename
        id
        name
        createdAt
        ...TenantPendingApproval
        appointmentsAggregate {
          aggregate {
            count
          }
        }
        tenantUsersAggregate {
          aggregate {
            count
          }
        }
      }
    }
  `,
  [tenantPendingApprovalFragment],
);
