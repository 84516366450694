import { useSuspenseQuery } from '@apollo/client';
import { type SyncPrerecordedResponse } from '@deepgram/sdk';
import { useEffect, useState } from 'react';
import { z } from 'zod';

import { useApiClient } from '@eluve/api-client-provider';
import { graphql } from '@eluve/graphql.tada';

const rawMetadataSchema = z.object({
  results: z.object({
    channels: z.array(
      z.object({
        alternatives: z.array(z.any()),
      }),
    ),
  }),
});

const getAppointmentDetails = graphql(`
  query getAppointmentDetails($tenantId: uuid!, $appointmentId: uuid!) {
    appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
      __typename
      id
      appointmentFiles {
        __typename
        appointmentId
        fileId
        fileType
        userUpload {
          __typename
          id
          status
          fileUrl
          originalFileName
        }
      }
      segments {
        __typename
        id
        transcript
        recordingStartedAt
        recordingDuration
        raw_transcript(where: { _and: [{ transcriptType: { _eq: BATCH } }] }) {
          __typename
          id
          user_upload {
            __typename
            id
            fileUrl
          }
        }
      }
    }
  }
`);

type ProcessedTranscriptParagraph = {
  sentences: {
    words: {
      word: string;
      punctuated_word?: string;
      start: number;
      end: number;
      confidence: number;
    }[];
  }[];
};

type SegmentAudioContext = {
  audioUrl: string | null;
  segmentId: string;
  transcript: string | null;
  startedAt: string;
  pausedAt?: string;
  paragraphs: ProcessedTranscriptParagraph[];
  seekTo?: number;
};

export const useTranscriptAudioAnalysisData = ({
  tenantId,
  appointmentId,
}: {
  tenantId: string;
  appointmentId: string;
}) => {
  const api = useApiClient();
  const [audioContexts, setAudioContexts] = useState<SegmentAudioContext[]>([]);

  const seekToTimestamp = (segmentId: string, seekTo: number) => {
    setAudioContexts((prev) => {
      return prev.map((ctx) => {
        if (ctx.segmentId === segmentId) {
          return { ...ctx, seekTo };
        }
        return ctx;
      });
    });
  };

  const { data } = useSuspenseQuery(getAppointmentDetails, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const { appointmentsByPk: appointment } = data;
  const segments = appointment?.segments ?? [];
  const appointmentFiles = appointment?.appointmentFiles ?? [];

  useEffect(() => {
    const processAudio = async () => {
      const promises = segments.map<Promise<SegmentAudioContext>>(
        async (seg) => {
          let audioUrl: string | null = null;
          const paragraphs: ProcessedTranscriptParagraph[] = [];

          const file = seg.raw_transcript?.[0]?.user_upload;
          if (file) {
            const fileId = file.id;
            const result = await api.reviewer.getAppointmentFileSignedUrl({
              params: { tenantId, appointmentId, fileId: fileId! },
            });

            const rawMetadataFile = appointmentFiles.find(
              (af) =>
                af.userUpload.fileUrl?.startsWith(file.fileUrl ?? 'missing') &&
                af.fileType === 'RAW_TRANSCRIPT_METADATA',
            );

            if (rawMetadataFile) {
              const metaDataResult =
                await api.reviewer.getAppointmentFileSignedUrl({
                  params: {
                    tenantId,
                    appointmentId,
                    fileId: rawMetadataFile.fileId,
                  },
                });

              if (
                metaDataResult.status === 200 &&
                metaDataResult.body.presignedUrl
              ) {
                // We need to download the raw metadata and make the JSON available
                const meta = await fetch(metaDataResult.body.presignedUrl);
                const json = await meta.json();
                // We currently only support the Deepgram metadata format
                const parsed = rawMetadataSchema.safeParse(json);
                if (parsed.success) {
                  // We want to turn this into a more usable data structure so that we can easily
                  // show confidence per word in the transcript while maintaining grouping of paragraphs
                  // and sentences
                  const prerecordedResponse =
                    parsed.data as SyncPrerecordedResponse;

                  const alternative =
                    prerecordedResponse.results!.channels[0]!.alternatives[0]!;

                  const rawParagraphs =
                    alternative.paragraphs?.paragraphs ?? [];
                  for (const p of rawParagraphs) {
                    const paragraph: ProcessedTranscriptParagraph = {
                      sentences: p.sentences.map((s) => {
                        return {
                          words: alternative.words.filter(
                            (w) => w.start >= s.start && w.end <= s.end,
                          ),
                        };
                      }),
                    };

                    paragraphs.push(paragraph);
                  }
                }
              }
            }

            if (result.status === 200 && result.body.presignedUrl) {
              audioUrl = result.body.presignedUrl;
            }
          }

          return {
            audioUrl,
            segmentId: seg.id,
            transcript: seg.transcript,
            startedAt: seg.recordingStartedAt,
            pausedAt: seg.recordingDuration
              ? new Date(
                  new Date(seg.recordingStartedAt).getTime() +
                    seg.recordingDuration,
                ).toISOString()
              : undefined,
            paragraphs,
          };
        },
      );

      const results = await Promise.all(promises);

      setAudioContexts(results);
    };

    processAudio();
  }, [segments, appointmentFiles, api, appointmentId, tenantId]);

  return {
    audioContexts,
    seekToTimestamp,
  };
};
