import React from 'react';
import { VariantProps } from 'tailwind-variants';

import { tv } from './cn';
import { textStyles } from './textStyles';

const styles = tv({
  base: 'inline-flex items-center justify-center whitespace-nowrap',
  variants: {
    // TODO(jesse)[ELU-2647] - Fix border radius
    size: {
      xs: textStyles.label({
        size: 's',
        weight: 'medium',
        className: 'h-6 gap-1 rounded-[6px] px-2',
      }),
      s: textStyles.body({
        size: 's',
        className: 'h-7 gap-1.5 rounded-[6px] px-2.5',
      }),
      m: textStyles.body({
        size: 'm',
        className: 'h-[30px] gap-2 rounded-[8px] p-3',
      }),
      l: textStyles.body({
        size: 'l',
        className: 'h-[34px] gap-3 rounded-[8px] p-3',
      }),
    },
    pill: {
      true: 'rounded-full',
    },
    color: {
      yellow: 'bg-yellow text-yellowContrast',
      orange: 'bg-orange text-orangeContrast',
      teal: 'bg-teal text-tealContrast',
      purple: 'bg-purple text-purpleContrast',
      green: 'bg-green text-greenContrast',
      magenta: 'bg-magenta text-magentaContrast',
      negative: 'bg-negative400 text-negative950',
      default: 'bg-borderPrimary text-contentSecondary',
    },
  },
  defaultVariants: {
    color: 'default',
    size: 'm',
  },
});

export type TagProps = VariantProps<typeof styles> & {
  label: string;
  className?: string;
  children?: React.ReactNode;
};

export const Tag = React.forwardRef<HTMLDivElement, TagProps>(
  ({ pill, size, color, label, className, children }, ref) => {
    const classes = styles({ pill, size, color, className });

    return (
      <div className={classes} ref={ref}>
        <p>{label}</p>
        {children}
      </div>
    );
  },
);
