export type SupportedAudioFormat = {
  encoding: 'opus' | 'aac' | 'mp3' | 'mp4' | 'wav' | 'm4a';
  mimeType: string;
  extension: 'webm' | 'mp4' | 'mp3' | 'wav' | 'm4a';
};

export const supportedAudioFormats: SupportedAudioFormat[] = [
  {
    mimeType: 'audio/webm;opus',
    encoding: 'opus',
    extension: 'webm',
  },
  {
    mimeType: 'audio/mp4;codecs="mp4a.40.2"',
    encoding: 'aac',
    extension: 'mp4',
  },
  {
    mimeType: 'audio/mpeg',
    encoding: 'mp3',
    extension: 'mp3',
  },
  {
    mimeType: 'audio/mp4',
    encoding: 'mp4',
    extension: 'mp4',
  },

  {
    mimeType: 'audio/wav',
    encoding: 'wav',
    extension: 'wav',
  },

  {
    mimeType: 'audio/x-m4a',
    encoding: 'aac',
    extension: 'm4a',
  },
];

export const getSupportedAudioFormat = () => {
  return supportedAudioFormats.find((audioFormat) =>
    MediaRecorder.isTypeSupported(audioFormat.mimeType),
  );
};
