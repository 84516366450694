import { ApolloProvider } from '@apollo/client';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet';

import {
  BrowserSessionProvider,
  apolloClientFactory,
  defaultCache,
  sessionAccessTokenProvider,
} from '@eluve/apollo-client';
import { ArtifactDetailsPage } from '@eluve/frontend-feature-eval';

import { appConfig } from '../../../config';

import { EvalGuard } from './EvalGuard';
import { EvalLayout } from './EvalLayout';
import { SourceArtifactPendingReviewListPage } from './SourceArtifactPendingReviewListPage';

const evalApolloClient = apolloClientFactory({
  uri: appConfig.VITE_APOLLO_CLIENT_URI,
  desiredRole: 'eval-user',
  cacheInstance: defaultCache,
  accessTokenProvider: sessionAccessTokenProvider(new BrowserSessionProvider()),
});

export const createEvalRoutes = (path: string) => {
  return [
    {
      path,
      element: (
        <Suspense fallback={'Loading...'}>
          <EvalGuard>
            <Helmet>
              <title>Eval | Eluve</title>
            </Helmet>
            <ApolloProvider client={evalApolloClient}>
              <EvalLayout />
            </ApolloProvider>
          </EvalGuard>
        </Suspense>
      ),
      children: [
        {
          path: 'source-artifact-review',
          element: <SourceArtifactPendingReviewListPage />,
        },
        {
          path: 'source-artifact-review/:artifactId',
          element: <ArtifactDetailsPage />,
        },
      ],
    },
  ];
};
