import { NewButton } from '@eluve/components';

import { useSummarizeAppointment } from '../../hooks/useSummarizeAppointment';

interface RegenerateSummaryProps {
  disabled?: boolean;
  tenantId: string;
  appointmentId: string;
}

export const RegenerateSummary: React.FC<RegenerateSummaryProps> = ({
  disabled,
  tenantId,
  appointmentId,
}) => {
  const regenerateSummary = useSummarizeAppointment();

  return (
    <NewButton
      disabled={disabled}
      icon={{ name: 'RotateCw' }}
      text="Regenerate Summary"
      type="outline"
      onClick={() => regenerateSummary(tenantId, appointmentId)}
    />
  );
};
