import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo } from 'react';
import {
  MultiValueGenericProps,
  OptionProps,
  PlaceholderProps,
  components,
} from 'react-select';
import AsyncSelect from 'react-select/async';

import { MedicalCode, MedicalCodeTag } from '@eluve/blocks';
import { HStack, Icon } from '@eluve/components';
import { MedicalCodeTypesEnum } from '@eluve/graphql-types';
import { ResultOf } from '@eluve/graphql.tada';

import {
  EmotionCacheProvider,
  MultiValueRemove,
  searchableStyle,
} from './Searchable.styles';
import { searchBillingCodesQuery } from './SearchableBillingCodes.operations';

export type BillingCodeOption = Omit<
  ResultOf<typeof searchBillingCodesQuery>['searchMedicalCodes'][0],
  '__typename'
>;

const Placeholder = (props: PlaceholderProps<BillingCodeOption>) => {
  return (
    <HStack className="col-start-1 col-end-3 row-start-1 row-end-2 inline-flex gap-3 pl-2">
      <Icon size="xs" name="Search" />
      <components.Placeholder {...props} />
    </HStack>
  );
};

// We'll let the container take care of rendering the whole billing code
// so we don't need an actual label
const MultiValueLabel = (_props: MultiValueGenericProps<BillingCodeOption>) => {
  return null;
};

const MultiValueContainer = (
  props: MultiValueGenericProps<BillingCodeOption>,
) => {
  const { data, selectProps } = props;
  const code = data as BillingCodeOption;

  return (
    <MedicalCode
      code={code.code}
      codeType={code.codeType}
      includeDescription={true}
      description={code.description ?? ''}
      endAdornment={
        selectProps?.isDisabled ? null : (
          <components.MultiValueRemove {...props} />
        )
      }
    />
  );
};

const Option = (props: OptionProps<BillingCodeOption>) => {
  const { data } = props;
  const code = data as BillingCodeOption;

  return (
    <components.Option {...props} className="flex items-center gap-3">
      <MedicalCodeTag code={code.code} codeType={code.codeType} />
      <div>{code.description}</div>
    </components.Option>
  );
};

export interface SearchableBillingCodesProps {
  codeTypes?: MedicalCodeTypesEnum[];
  disabled?: boolean;
  onCodeAdded?: (option: BillingCodeOption) => void | Promise<void>;
  onCodeRemoved?: (option: BillingCodeOption) => void | Promise<void>;
  selectedCodes?: BillingCodeOption[];
  shouldRenderValue?: boolean;
}

const defaultCodeTypes: MedicalCodeTypesEnum[] = ['CPT', 'ICD_10', 'SNOMED'];

export const SearchableBillingCodes: React.FC<SearchableBillingCodesProps> = ({
  codeTypes = [],
  disabled,
  onCodeAdded,
  onCodeRemoved,
  selectedCodes,
  shouldRenderValue,
}) => {
  const [search] = useLazyQuery(searchBillingCodesQuery);

  const _searchCodes = useCallback(
    (query: string, callback: (codes: BillingCodeOption[]) => void) => {
      const types = codeTypes.length ? codeTypes : defaultCodeTypes;
      search({ variables: { query, limit: 25, types } }).then((result) => {
        callback(result.data?.searchMedicalCodes ?? []);
      });
    },
    [codeTypes, search],
  );

  const searchCodes = useMemo(
    () => debounce(_searchCodes, 250),
    [_searchCodes],
  );

  return (
    <EmotionCacheProvider>
      <AsyncSelect<BillingCodeOption, true>
        isDisabled={disabled}
        controlShouldRenderValue={shouldRenderValue}
        backspaceRemovesValue={!shouldRenderValue}
        placeholder="Choose a code you would like to add"
        noOptionsMessage={({ inputValue }) =>
          inputValue
            ? `No results for "${inputValue}"`
            : 'Enter code or search term'
        }
        loadingMessage={() => 'Searching...'}
        isMulti
        value={selectedCodes}
        isClearable={false}
        classNames={{
          container: () => searchableStyle.container(),
          control: ({ isDisabled }) =>
            searchableStyle.control({ disabled: isDisabled }),
          valueContainer: () => searchableStyle.valueContainer(),
          menu: () => searchableStyle.menu(),
          menuList: () => searchableStyle.menuList(),
          placeholder: () => searchableStyle.placeholder(),
          multiValue: () => searchableStyle.multiValue(),
          indicatorSeparator: () => searchableStyle.indicatorSeparator(),
          dropdownIndicator: () => searchableStyle.dropdownIndicator(),
          loadingIndicator: () => searchableStyle.loadingIndicator(),
          indicatorsContainer: () => searchableStyle.indicatorsContainer(),
          multiValueRemove: () => searchableStyle.multiValueRemove(),
          option: ({ isFocused }) =>
            searchableStyle.option({ optionFocused: isFocused }),
          noOptionsMessage: () => searchableStyle.noOptionsMessage(),
        }}
        onChange={async (input) => {
          const codes = selectedCodes ?? [];
          const removed = codes.filter((f) => !input.includes(f));
          const added = input.filter((f) => !codes.includes(f));

          if (removed.length) {
            const [toRemove] = removed;
            await onCodeRemoved?.(toRemove!);
          }

          if (added.length) {
            const [toAdd] = added;
            await onCodeAdded?.(toAdd!);
          }
        }}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.code}
        components={{
          MultiValueContainer,
          MultiValueLabel,
          MultiValueRemove,
          Option,
          Placeholder,
        }}
        loadOptions={searchCodes}
      />
    </EmotionCacheProvider>
  );
};
