import { Loader2 } from 'lucide-react';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Passwordless from 'supertokens-auth-react/recipe/passwordless';

import { useNamedLogger } from '@eluve/logger';

export const MagicLinkVerifyPage = () => {
  const logger = useNamedLogger('MagicLinkVerify');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyMagicLink = async () => {
      try {
        const response = await Passwordless.consumeCode();

        if (response.status === 'OK') {
          const params = new URLSearchParams(location.search);
          let redirectTo = params.get('redirectTo') ?? '/';

          if (!redirectTo.startsWith('/')) {
            redirectTo = `/${redirectTo}`;
          }

          logger.info('Login successful, redirecting to:', { redirectTo });
          navigate(redirectTo);
        } else {
          logger.error('Something went wrong. Please try again.', { response });
        }
      } catch (error) {
        logger.error('Error consuming magic link', { error });
      }
    };

    verifyMagicLink();
  }, [navigate, logger, location]);

  return (
    <div className="grid h-full place-items-center">
      <Loader2 className="size-8 animate-spin text-brand-8" />
    </div>
  );
};
