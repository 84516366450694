import { Label } from '@radix-ui/react-context-menu';
import React, { useState } from 'react';
import { toast } from 'sonner';
import { VariantProps } from 'tailwind-variants';
import { match } from 'ts-pattern';

import { AudioPlayer } from '@eluve/audio-player';
import {
  TooltipLabel,
  TranscriptText,
  TranscriptTimestampDivider,
} from '@eluve/blocks';
import {
  Box,
  Card,
  EluveCardContent,
  EluveCardHeader,
  HStack,
  NewButton,
  P,
  Switch,
  VStack,
  textStyles,
  tv,
} from '@eluve/components';
import { Translator } from '@eluve/translation';
import { toSentences } from '@eluve/utils';

import { useAppointmentParams } from './useAppointmentParams';
import { useTranscriptAudioAnalysisData } from './useTranscriptAudioAnalysisData';

const transcriptConfidenceStyles = tv({
  base: '',
  variants: {
    confidence: {
      10: 'opacity-100',
      9: 'opacity-90',
      8: 'opacity-80',
      7: 'opacity-70',
      6: 'opacity-60',
      5: 'opacity-50',
      4: 'opacity-40',
      3: 'opacity-30',
      2: 'opacity-20',
    },
  },
});
type TranscriptConfidenceStyleVariantProps = VariantProps<
  typeof transcriptConfidenceStyles
>;

export interface AppointmentTranscriptsProps {}

export const AppointmentTranscripts: React.FC<
  AppointmentTranscriptsProps
> = () => {
  const { tenantId, appointmentId } = useAppointmentParams();
  const [showConfidenceStyles, setShowConfidenceStyles] = useState(true);
  const [translatedContent, setTranslatedContent] = useState<string | null>(
    null,
  );

  const { audioContexts, seekToTimestamp } = useTranscriptAudioAnalysisData({
    tenantId,
    appointmentId,
  });

  const transcripts = audioContexts.map((ctx) => ({
    transcript: ctx.transcript,
    segmentId: ctx.segmentId,
  }));

  const fullTranscript = transcripts.map((t) => t.transcript).join('\n');

  const parsedTranslatedContent: typeof transcripts = translatedContent
    ? JSON.parse(translatedContent)
    : null;

  return (
    <div>
      <Box className="w-1/6 p-2">
        <Label>Need translation?</Label>
        <Translator
          setTranslatedContent={setTranslatedContent}
          originalContent={JSON.stringify(transcripts)}
        />
      </Box>
      <HStack align="start">
        <Card className="w-full">
          <EluveCardHeader>
            <HStack justify="between" wFull>
              <P className="whitespace-nowrap">
                Click on the text to jump to that portion of the audio
              </P>
              <HStack wFull={false}>
                <P className="whitespace-nowrap">Show confidence styles</P>
                <Switch
                  checked={showConfidenceStyles}
                  onCheckedChange={(checked) =>
                    setShowConfidenceStyles(checked)
                  }
                />
              </HStack>
            </HStack>
          </EluveCardHeader>
          <EluveCardContent>
            <VStack gap={5}>
              <NewButton
                size="s"
                text="Copy Transcript"
                icon={{
                  name: 'Copy',
                }}
                onClick={async () => {
                  await navigator.clipboard.writeText(fullTranscript);
                  toast.success('Transcript copied to clipboard');
                }}
              />
              {audioContexts.map((ctx, i) => {
                return (
                  <VStack key={ctx.segmentId}>
                    <TranscriptTimestampDivider
                      timestamp={ctx.startedAt}
                      action={i === 0 ? 'started' : 'resumed'}
                    />

                    {ctx.audioUrl && (
                      <AudioPlayer
                        audioUrl={ctx.audioUrl}
                        seekTo={ctx.seekTo}
                      />
                    )}
                    <VStack>
                      {match(Boolean(ctx.paragraphs.length))
                        .with(true, () => {
                          return (
                            <>
                              {ctx.paragraphs.map((p, pIdx) => {
                                return (
                                  <VStack key={pIdx}>
                                    {p.sentences.map((s, sIdx) => (
                                      <HStack gap={1} key={sIdx} wrap>
                                        {s.words.map((w, wIdx) => (
                                          <TooltipLabel
                                            key={wIdx}
                                            label={`Word: "${w.punctuated_word ?? w.word}", Confidence: ${w.confidence}`}
                                          >
                                            <span
                                              key={wIdx}
                                              onClick={() => {
                                                seekToTimestamp(
                                                  ctx.segmentId,
                                                  w.start,
                                                );
                                              }}
                                              className={textStyles.body({
                                                className: [
                                                  'inline-flex cursor-pointer hover:underline',
                                                  showConfidenceStyles
                                                    ? transcriptConfidenceStyles(
                                                        {
                                                          confidence:
                                                            Math.floor(
                                                              w.confidence * 10,
                                                            ) as TranscriptConfidenceStyleVariantProps['confidence'],
                                                        },
                                                      )
                                                    : '',
                                                ],
                                              })}
                                            >
                                              {w.punctuated_word ?? w.word}
                                            </span>
                                          </TooltipLabel>
                                        ))}
                                      </HStack>
                                    ))}
                                  </VStack>
                                );
                              })}
                            </>
                          );
                        })
                        .otherwise(() => (
                          <TranscriptText>
                            {toSentences(ctx.transcript ?? '')}
                          </TranscriptText>
                        ))}
                    </VStack>
                    {ctx.pausedAt && (
                      <TranscriptTimestampDivider
                        timestamp={ctx.pausedAt}
                        action="paused"
                      />
                    )}
                  </VStack>
                );
              })}
            </VStack>
          </EluveCardContent>
        </Card>
        {Boolean(parsedTranslatedContent?.length) && (
          <Card className="w-full">
            <EluveCardHeader>
              <HStack justify="between" wFull>
                <P className="whitespace-nowrap">Translation</P>
              </HStack>
            </EluveCardHeader>
            <EluveCardContent>
              <VStack gap={5}>
                {audioContexts.map((ctx, i) => {
                  return (
                    <VStack key={ctx.segmentId}>
                      <TranscriptTimestampDivider
                        timestamp={ctx.startedAt}
                        action={i === 0 ? 'started' : 'resumed'}
                      />
                      <TranscriptText>
                        {toSentences(
                          parsedTranslatedContent.find(
                            (t) => t.segmentId === ctx.segmentId,
                          )?.transcript ?? '',
                        )}
                      </TranscriptText>
                      {ctx.pausedAt && (
                        <TranscriptTimestampDivider
                          timestamp={ctx.pausedAt}
                          action="paused"
                        />
                      )}
                    </VStack>
                  );
                })}
              </VStack>
            </EluveCardContent>
          </Card>
        )}
      </HStack>
    </div>
  );
};
