import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'sonner';

import {
  eluveAdminHasuraContext,
  useCompleteFragment,
} from '@eluve/apollo-client';
import { Switch } from '@eluve/components';
import { LlmOutputTypesEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';

export const tenantLlmOutputFragment = graphql(`
  fragment TenantLlmOutput on TenantLlmOutputs @_unmask {
    __typename
    tenantId
    llmOutputName
    isEnabled
  }
`);

const setTenantLlmOutputMutation = graphql(
  `
    mutation setTenantLlmOutput(
      $tenantId: uuid!
      $outputType: LlmOutputTypesEnum!
      $isEnabled: Boolean!
    ) {
      insertTenantLlmOutputsOne(
        object: {
          tenantId: $tenantId
          llmOutputName: $outputType
          isEnabled: $isEnabled
        }
        onConflict: {
          constraint: tenant_llm_outputs_pkey
          updateColumns: [isEnabled]
        }
      ) {
        ...TenantLlmOutput
      }
    }
  `,
  [tenantLlmOutputFragment],
);

export interface TenantLlmOutputToggleProps {
  tenantId: string;
  outputType: LlmOutputTypesEnum;
}

export const TenantLlmOutputToggle: React.FC<TenantLlmOutputToggleProps> = ({
  tenantId,
  outputType,
}) => {
  const [updateFeatureFlag] = useMutation(setTenantLlmOutputMutation, {
    onError: () =>
      toast.error(`Failed to update access to output type ${outputType}`),
    optimisticResponse: (data) => ({
      insertTenantLlmOutputsOne: {
        __typename: 'TenantLlmOutputs' as const,
        isEnabled: data.isEnabled,
        llmOutputName: data.outputType,
        tenantId: data.tenantId,
      },
    }),
  });

  const submit = async (isEnabled: boolean) => {
    await updateFeatureFlag({
      context: eluveAdminHasuraContext,
      variables: {
        tenantId,
        outputType,
        isEnabled,
      },
    });
  };

  const data = useCompleteFragment({
    fragment: tenantLlmOutputFragment,
    key: {
      llmOutputName: outputType,
      tenantId,
    },
    strict: false,
  });

  return (
    <Switch
      isCompact
      onCheckedChange={(isChecked) => submit(isChecked)}
      checked={data?.isEnabled ?? false}
    />
  );
};
