import React from 'react';

import { cn } from './cn';

export interface LinearLogoProps {
  className?: string;
}

export const LinearLogo: React.FC<LinearLogoProps> = ({ className }) => {
  return (
    <svg
      className={cn('size-5', className)}
      aria-hidden="true"
      viewBox="0 0 24 24"
    >
      <path
        d="M3.03509 12.9431C3.24245 14.9227 4.10472 16.8468 5.62188 18.364C7.13904 19.8811 9.0631 20.7434 11.0428 20.9508L3.03509 12.9431Z"
        fill="#000000"
      />
      <path
        d="M3 11.4938L12.4921 20.9858C13.2976 20.9407 14.0981 20.7879 14.8704 20.5273L3.4585 9.11548C3.19793 9.88771 3.0451 10.6883 3 11.4938Z"
        fill="#000000"
      />
      <path
        d="M3.86722 8.10999L15.8758 20.1186C16.4988 19.8201 17.0946 19.4458 17.6493 18.9956L4.99021 6.33659C4.54006 6.89125 4.16573 7.487 3.86722 8.10999Z"
        fill="#000000"
      />
      <path
        d="M5.66301 5.59517C9.18091 2.12137 14.8488 2.135 18.3498 5.63604C21.8508 9.13708 21.8645 14.8049 18.3907 18.3228L5.66301 5.59517Z"
        fill="#000000"
      />
    </svg>
  );
};
