import { useMutation } from '@apollo/client';
import { PackageMinus, PackagePlus } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import { match } from 'ts-pattern';

import { useCompleteFragment } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertTitle,
  Button,
  P,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { promptTemplateSettingsFragment } from './prompt.operations';

export interface TogglePromptTemplateActiveActionProps {
  promptTemplateId: string;
}

const togglePromptTemplateActiveMutation = graphql(`
  mutation togglePromptTemplateActive($id: uuid!, $isActive: Boolean!) {
    updatePromptTemplatesByPk(
      pkColumns: { id: $id }
      _set: { isActive: $isActive }
    ) {
      __typename
      id
      isActive
    }
  }
`);

const deactivePromptTemplateWithUserDefaultsMutation = graphql(`
  mutation deactivePromptTemplateWithUserDefaults($id: uuid!) {
    updateUserSettings(
      where: { promptTemplateId: { _eq: $id } }
      _set: { promptTemplateId: null }
    ) {
      affectedRows
    }
    updatePromptTemplatesByPk(
      pkColumns: { id: $id }
      _set: { isActive: false }
    ) {
      __typename
      id
      isActive
      userSettingsAggregate {
        __typename
        aggregate {
          count
        }
      }
    }
  }
`);

export const TogglePromptTemplateActiveAction: React.FC<
  TogglePromptTemplateActiveActionProps
> = ({ promptTemplateId }) => {
  const [updatePromptTemplateIsActive] = useMutation(
    togglePromptTemplateActiveMutation,
    {
      optimisticResponse: (data) => ({
        updatePromptTemplatesByPk: {
          __typename: 'PromptTemplates' as const,
          id: data.id,
          isActive: data.isActive,
        },
      }),
      onError: () => {
        toast.error('Failed to update prompt template');
      },
    },
  );

  const [deactivePromptTemplateWithUserDefaults] = useMutation(
    deactivePromptTemplateWithUserDefaultsMutation,
    {
      optimisticResponse: (data) => ({
        updateUserSettings: {
          affectedRows: 1,
        },
        updatePromptTemplatesByPk: {
          __typename: 'PromptTemplates' as const,
          id: data.id,
          isActive: false,
          userSettingsAggregate: {
            __typename: 'UserSettingsAggregate' as const,
            aggregate: {
              count: 0,
            },
          },
        },
      }),
      onError: () => {
        toast.error('Failed to update prompt template');
      },
    },
  );

  const promptTemplateSettings = useCompleteFragment({
    fragment: promptTemplateSettingsFragment,
    key: {
      id: promptTemplateId,
    },
  });

  const usersUsingThisPromptTemplate =
    promptTemplateSettings?.userSettingsAggregate?.aggregate?.count ?? 0;

  const toggleActive = async () => {
    if (usersUsingThisPromptTemplate > 0) {
      deactivePromptTemplateWithUserDefaults({
        variables: {
          id: promptTemplateId,
        },
      });
    } else {
      updatePromptTemplateIsActive({
        variables: {
          id: promptTemplateId,
          isActive: !promptTemplateSettings.isActive,
        },
      });
    }
  };

  const { label, description, action, Component } = match(
    promptTemplateSettings.isActive,
  )
    .with(true, () => {
      const description = (
        <div>
          <P>
            Marking this prompt template as inactive will mean users can no
            longer use it for generating LLM outputs
          </P>
          {usersUsingThisPromptTemplate >= 1 && (
            <Alert className="mt-4" variant="warning">
              <AlertTitle>Currently in use</AlertTitle>
              <AlertDescription>
                This will impact{' '}
                <Link className="font-bold underline" to={promptTemplateId}>
                  {usersUsingThisPromptTemplate} users
                </Link>{' '}
                who have specifically chosen this prompt as their default.{' '}
                <br />
                <br /> If you continue, their default setting will be removed.
              </AlertDescription>
            </Alert>
          )}
        </div>
      );

      return {
        label: 'Mark this prompt template as inactive',
        description,
        action: 'Deactivate',
        Component: <PackageMinus className="text-orange" />,
      };
    })
    .otherwise(() => ({
      label: 'Mark this prompt template as active',
      description: (
        <P>
          Marking this prompt template as active will make it available for
          users
        </P>
      ),
      action: 'Activate',
      Component: <PackagePlus className="text-green" />,
    }));

  return (
    <AlertDialog>
      <TooltipLabel label={label}>
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline">
            {Component}
          </Button>
        </AlertDialogTrigger>
      </TooltipLabel>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-gray-11">
            Are you sure?
          </AlertDialogTitle>
          <AlertDialogDescription asChild>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel autoFocus>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            onClick={toggleActive}
          >
            {action}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
