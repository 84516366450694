import { useEffect } from 'react';

import { useNamedLogger } from '@eluve/logger';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import { usePromptTemplatesForTenant } from './usePromptTemplatesForTenant';

export const usePromptTemplates = () => {
  const tenantId = useAssignedTenantIdFromParams();
  const userId = useUserIdFromSession();
  const logger = useNamedLogger('usePromptTemplates');

  const {
    activeSOAPPromptTemplates,
    defaultPromptTemplateId,
    defaultOutputTemplateId,
    userSettingsPromptTemplateId,
    activeOutputTemplates,
    userSettingsOutputTemplateId,
  } = usePromptTemplatesForTenant({
    tenantId,
    userId,
  });

  useEffect(() => {
    if (!activeOutputTemplates.length) {
      logger.warn('No output templates available for user');
    }
  }, [activeOutputTemplates, logger]);

  useEffect(() => {
    if (!activeSOAPPromptTemplates.length) {
      logger.warn('No prompt templates available for user');
    }
  }, [activeSOAPPromptTemplates, logger]);

  return {
    activeSOAPPromptTemplates,
    defaultPromptTemplateId,
    defaultOutputTemplateId,
    userSettingsPromptTemplateId,
    activeOutputTemplates,
    userSettingsOutputTemplateId,
  };
};
