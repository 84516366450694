/**
 * Used for configuring a GraphQL operation to be executed in the context of an eluve-admin
 * super user account
 */
export const eluveAdminHasuraContext = {
  headers: {
    'x-hasura-role': 'eluve-admin',
  },
};

/**
 * Used for configuring a GraphQL operation to be executed in the context of an reviewer
 * super user account
 */
export const reviewerHasuraContext = {
  headers: {
    'x-hasura-role': 'reviewer',
  },
};
