const TuberCulosisConversation = `Good morning.
What brings you in today?
Hi, doc.
I've been having this persistent cough for a few weeks now.
It started off mild, but now I've been coughing up some blood.
I'm sorry to hear that.
Coughing up blood can be concerning.
Can you tell me more about any other symptoms you've been experiencing?
Yeah.
I've also had some night sweats, weight loss, and I feel tired all the time.
And, strangely, I've been having some pain in my left toe and occasional heartburn.
How long have you been experiencing the fatigue and night sweats?
I'd say about a month now.
And the weight loss, maybe over the last couple of months, I thought it was just due to my new diet.
Have you had any exposure to anyone diagnosed with tuberculosis, or have you recently traveled to areas where tuberculosis is common?
Now that you mentioned it, I did visit my cousin 3 months ago.
He was diagnosed with TB a few weeks after I came back, and I've been to South Africa for work about 5 months ago.
That's an important piece of information.
Tuberculosis is an infectious disease that can spread from person to person, especially in close quarters.
It's also more common in certain parts of the world, including parts of South Africa.
Oh, I see.
I didn't think much of the toe pain and heartburn, but I thought I'd mention it since I was here.
You did the right thing by mentioning all your symptoms.
Now given your exposure history and your current symptoms, I'm concerned about the possibility of you having tuberculosis.
We'll need to run some tests to confirm, including a chest X-ray and sputum test.
In the meantime, it's essential to ensure you practice good hygiene and avoid close contact with others, especially infants and those with weakened immune systems.
Alright.
I understand.
I'm just hoping we can get to the bottom of this soon.
We'll work together to ensure you get the right care and treatment.
Let's start with the tests and go from there.`;

const DiabetesConversation = `Good afternoon.
What brings you in today?
Hi, doctor.
I've been feeling extremely thirsty all the time, and no matter how much water I drink, it just doesn't seem to help.
I've also been going to the bathroom a lot more than usual, even during the night.
That sounds uncomfortable. How long have you been experiencing these symptoms?
It's been going on for about two months now.
At first, I thought it might just be because of the weather or stress, but it's getting worse.
I see. Aside from the thirst and frequent urination, have you noticed any other symptoms?
Yes, actually. I've been feeling really tired, even after a full night’s sleep. And recently, I've noticed some blurry vision, especially when I'm trying to read or use my phone.
That can be concerning. Have you experienced any unexplained weight loss or noticed any changes in your appetite?
Well, now that you mention it, I have lost some weight, but I haven’t been dieting or exercising more than usual. My appetite has been a bit strange too—sometimes I feel extremely hungry, even right after eating.
These are important symptoms. Excessive thirst, frequent urination, fatigue, unexplained weight loss, and blurry vision can all be signs of high blood sugar levels.
Have you or anyone in your family been diagnosed with diabetes before?
Yes, my father was diagnosed with type 2 diabetes a few years ago. I never thought it would affect me, though, since I try to stay active.
Family history can increase the risk of developing diabetes, especially type 2 diabetes. Given your symptoms and family history, I’d like to run a few tests to check your blood sugar levels.
What kind of tests are we talking about?
We'll start with a fasting blood glucose test and an A1C test, which will give us a better picture of your average blood sugar over the past few months. Depending on the results, we may need to explore further.
That makes sense. Is it something I should be really worried about?
Diabetes is a serious condition, but if it turns out you have it, there are many ways we can manage it through lifestyle changes, medication, or insulin therapy if needed. For now, I’d like to prescribe something to help manage your symptoms in the short term. For example, you can take 500mg of metformin once a day with your evening meal, which helps control blood sugar levels. Additionally, I’d recommend paracetamol (1000mg) at night to help with fatigue and any discomfort.
I see. I’ve been worried something might be wrong, but I didn’t realize it could be diabetes.
It’s good that you came in to get checked. Early diagnosis and management are key to living a healthy life with diabetes. We’ll take this one step at a time, starting with the tests.
Alright, I’m ready to do whatever it takes. I just want to feel better.
You’re doing the right thing by addressing it early. Let’s get these tests done, and we’ll go over the results together to figure out the next steps. In the meantime, take the medications I’ve prescribed, and let me know if your symptoms worsen or if you have any side effects.`;

export const TestTranscripts = {
  tuberculosis: TuberCulosisConversation,
  diabetesWithMedicine: DiabetesConversation,
};
