import { useMutation, useSuspenseQuery } from '@apollo/client';
import { produce } from 'immer';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'sonner';

import { cacheUtils } from '@eluve/apollo-client';
import { MedicalCodeTag } from '@eluve/blocks';
import {
  ColDefBuilder,
  CurrencyInput,
  DataTable,
  Dialog,
  DialogContent,
  DialogFooter,
  HStack,
  NewButton,
  P,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  VStack,
} from '@eluve/components';
import { MedicalCodeTypesEnum } from '@eluve/graphql-types';

import {
  addBillingCodePriceMutation,
  getBillingCodePrices,
  locationBillingCodePricesFragment,
  removeBillingCodePriceMutation,
  updateBillingCodePriceMutation,
} from './LocationBillingCodes.operations';
import {
  BillingCodeOption,
  SearchableBillingCodes,
} from './SearchableBillingCodes';

// Types

type LocationPriceRow = {
  billingCodeId: string;
  code: string;
  codeType: MedicalCodeTypesEnum;
  description: string | null;
  locationId: string;
  price: number;
  tenantId: string;
};

// Private Components

type AddBillingCodeModalProps = {
  locationId: string;
  onOpenChange: (open: boolean) => void;
  onSubmit: (row: LocationPriceRow) => void;
  open: boolean;
  selectedCode?: BillingCodeOption;
  tenantId: string;
};

const AddBillingCodeModal: React.FC<AddBillingCodeModalProps> = ({
  locationId,
  onOpenChange,
  onSubmit,
  open,
  selectedCode,
  tenantId,
}) => {
  const [price, setPrice] = useState<string | undefined>(undefined);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (selectedCode == null || price == null || isNaN(Number(price))) {
      event.preventDefault();
      return;
    }

    const row = {
      tenantId,
      locationId,
      billingCodeId: selectedCode.id,
      code: selectedCode.code,
      codeType: selectedCode.codeType,
      description: selectedCode.description,
      price: Math.floor(Number(price) * 100),
    };
    onSubmit(row);
    setPrice(undefined);
    event.preventDefault();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-sm pt-9">
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          {selectedCode != null && (
            <VStack align="center">
              <MedicalCodeTag
                code={selectedCode.code}
                codeType={selectedCode.codeType}
              />
              <P className="text-center">{selectedCode.description}</P>
            </VStack>
          )}
          <CurrencyInput onChange={setPrice} value={price} />
          <DialogFooter className="flex w-full flex-col sm:justify-center">
            <NewButton submit text="Add Billing Code" />
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

type PriceCellProps = {
  billingCodeId: string;
  locationId: string;
  tenantId: string;
  value: string;
};

const PriceCell: React.FC<PriceCellProps> = ({
  billingCodeId,
  locationId,
  tenantId,
  value,
}) => {
  const [originalValue, setOriginalValue] = useState<string | undefined>(value);
  const [currentValue, setCurrentValue] = useState<string | undefined>(value);
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const isDirty = currentValue !== originalValue;

  useEffect(() => {
    setCurrentValue(value);
    setOriginalValue(value);
  }, [value]);

  const [updateBillingCodePrice] = useMutation(updateBillingCodePriceMutation, {
    onError: () => {
      toast.error('We could not update the price.');
    },
    optimisticResponse: ({ billingCodeId, locationId, price, tenantId }) => {
      return {
        updateBillingCodeLocationPricesByPk: {
          __typename: 'BillingCodeLocationPrices' as const,
          billingCodeId,
          locationId,
          tenantId,
          price,
        },
      };
    },
  });

  const updatePrice = () => {
    updateBillingCodePrice({
      variables: {
        billingCodeId,
        locationId,
        price: Math.floor(Number(currentValue) * 100),
        tenantId,
      },
    });
  };

  const handleBlur = () => {
    if (isDirty && formRef.current?.reportValidity()) {
      updatePrice();
    }
  };

  const handleChange = (newValue: string) => {
    if (currentValue === originalValue) {
      setOriginalValue(currentValue);
    }
    setCurrentValue(newValue);
  };

  const handleRevert = () => {
    setCurrentValue(originalValue);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    inputRef.current?.blur();
    event.preventDefault();
  };

  return (
    <div className="relative">
      <form className="w-full" onSubmit={handleSubmit} ref={formRef}>
        <CurrencyInput
          onBlur={handleBlur}
          onChange={handleChange}
          ref={inputRef}
          value={currentValue}
        />
      </form>
      {isDirty && !inputRef.current?.validity.valid && (
        <div className="absolute right-0 top-0 flex">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <NewButton
                  icon={{ name: 'RotateCcw' }}
                  onClick={handleRevert}
                  type="ghost"
                />
              </TooltipTrigger>
              <TooltipContent sideOffset={4}>Revert changes</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}
    </div>
  );
};

type RemoveBillingCodeButtonProps = {
  billingCodeId: string;
  locationId: string;
  tenantId: string;
};

const RemoveBillingCodeButton: React.FC<RemoveBillingCodeButtonProps> = ({
  billingCodeId,
  locationId,
  tenantId,
}) => {
  const [removeBillingCode] = useMutation(removeBillingCodePriceMutation, {
    variables: {
      billingCodeId,
      locationId,
      tenantId,
    },
    onError: () => toast.error('We could not remove the billing code.'),
    optimisticResponse: ({ billingCodeId, locationId, tenantId }) => {
      return {
        deleteBillingCodeLocationPricesByPk: {
          __typename: 'BillingCodeLocationPrices' as const,
          billingCodeId,
          locationId,
          tenantId,
        },
      };
    },
    update: (_cache) => {
      cacheUtils.updateFragment(
        {
          fragment: locationBillingCodePricesFragment,
          key: {
            id: locationId,
          },
        },
        (existing) => {
          if (!existing) {
            return existing;
          }

          return produce(existing, (draft) => {
            const index = draft.billing_code_prices.findIndex(
              (billingCodePrice) =>
                billingCodePrice.billingCodeId === billingCodeId,
            );
            if (index !== -1) {
              draft.billing_code_prices.splice(index, 1);
            }
          });
        },
      );
    },
  });

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <NewButton
            icon={{ name: 'Trash' }}
            onClick={() => removeBillingCode()}
            type="outline"
          />
        </TooltipTrigger>
        <TooltipContent>Remove code</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

// Constants

const cols = new ColDefBuilder<LocationPriceRow>()
  .defaultSortable('code', {
    cellRenderer: (row) => (
      <div className="ml-2">
        <MedicalCodeTag code={row.code} codeType={row.codeType} />
      </div>
    ),
  })
  .defaultSortable('description', 'Description')
  .defaultSortable('price', {
    cellRenderer: (row) => (
      <PriceCell
        billingCodeId={row.billingCodeId}
        locationId={row.locationId}
        tenantId={row.tenantId}
        value={(row.price / 100).toFixed(2)}
      />
    ),
  })
  .colDef({
    id: 'actions',
    cell: ({ row }) => (
      <HStack justify="end" wFull>
        <RemoveBillingCodeButton
          billingCodeId={row.original.billingCodeId}
          locationId={row.original.locationId}
          tenantId={row.original.tenantId}
        />
      </HStack>
    ),
  })
  .build();

// Public Components

type LocationBillingCodesProps = {
  locationId: string;
  tenantId: string;
};

export const LocationBillingCodes: React.FC<LocationBillingCodesProps> = ({
  locationId,
  tenantId,
}) => {
  const [isAddingCode, setIsAddingCode] = useState(false);
  const [selectedCode, setSelectedCode] = useState<
    BillingCodeOption | undefined
  >(undefined);

  const { data } = useSuspenseQuery(getBillingCodePrices, {
    variables: { tenantId, locationId },
  });

  const billingCodePrices = useMemo(
    () => data?.locationsByPk?.billing_code_prices ?? [],
    [data],
  );

  const billingCodes = useMemo(
    () =>
      billingCodePrices.map<BillingCodeOption>((billingCodePrice) => {
        const billingCode = billingCodePrice.medical_code;
        return {
          code: billingCode.code,
          codeType: billingCode.codeType,
          description: billingCode.description,
          id: billingCode.id,
        };
      }),
    [billingCodePrices],
  );

  const rows = useMemo(
    () =>
      billingCodePrices.map<LocationPriceRow>((billingCodePrice) => {
        const billingCode = billingCodePrice.medical_code;
        return {
          tenantId,
          locationId,
          billingCodeId: billingCode.id,
          code: billingCode.code,
          codeType: billingCode.codeType,
          description: billingCode.description,
          price: billingCodePrice.price,
        };
      }),
    [billingCodePrices, locationId, tenantId],
  );

  const [addBillingCode] = useMutation(addBillingCodePriceMutation, {
    onError: () => {
      toast.error('We could not add the billing code.');
    },
  });

  const handleCodeSubmitted = (row: LocationPriceRow) => {
    if (selectedCode == null) {
      return;
    }

    addBillingCode({
      variables: {
        billingCodeId: row.billingCodeId,
        locationId,
        price: row.price,
      },
      optimisticResponse: ({ billingCodeId, locationId, price }) => {
        return {
          insertBillingCodeLocationPricesOne: {
            __typename: 'BillingCodeLocationPrices' as const,
            tenantId,
            locationId,
            billingCodeId,
            price,
            medical_code: {
              __typename: 'MedicalCodes' as const,
              id: row.billingCodeId,
              code: row.code,
              codeType: row.codeType,
              description: row.description,
            },
          },
        };
      },
      update: (_cache) => {
        cacheUtils.updateFragment(
          {
            fragment: locationBillingCodePricesFragment,
            key: {
              id: locationId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }

            return produce(existing, (draft) => {
              draft.billing_code_prices.push({
                __typename: 'BillingCodeLocationPrices',
                tenantId,
                locationId,
                billingCodeId: row.billingCodeId,
                price: row.price,
                medical_code: {
                  __typename: 'MedicalCodes',
                  id: row.billingCodeId,
                  code: row.code,
                  codeType: row.codeType,
                  description: row.description,
                },
              });
            });
          },
        );
      },
    });

    setSelectedCode(undefined);
    setIsAddingCode(false);
  };

  const handleCodeSelected = (code: BillingCodeOption) => {
    setSelectedCode(code);
    setIsAddingCode(true);
  };

  return (
    <VStack gap={5}>
      <SearchableBillingCodes
        codeTypes={['CPT']}
        onCodeAdded={handleCodeSelected}
        selectedCodes={billingCodes}
        shouldRenderValue={false}
      />
      <AddBillingCodeModal
        locationId={locationId}
        onOpenChange={setIsAddingCode}
        onSubmit={handleCodeSubmitted}
        open={isAddingCode}
        selectedCode={selectedCode}
        tenantId={tenantId}
      />
      <DataTable
        columns={cols}
        data={rows}
        isCompact
        placeholder="No billing codes added yet."
      />
    </VStack>
  );
};
