import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { toast } from 'sonner';
import { match } from 'ts-pattern';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Switch,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export const tenantPendingApprovalFragment = graphql(`
  fragment TenantPendingApproval on Tenants @_unmask {
    __typename
    id
    isPendingApproval
  }
`);

const setTenantPendingApprovalMutation = graphql(
  `
    mutation setTenantPendingApproval(
      $tenantId: uuid!
      $isPendingApproval: Boolean!
    ) {
      updateTenantsByPk(
        pkColumns: { id: $tenantId }
        _set: { isPendingApproval: $isPendingApproval }
      ) {
        ...TenantPendingApproval
      }
    }
  `,
  [tenantPendingApprovalFragment],
);

export interface TenantApprovalToggleProps {
  tenantId: string;
}

export const TenantApprovalToggle: React.FC<TenantApprovalToggleProps> = ({
  tenantId,
}) => {
  const [nextPendingState, setNextPendingState] = useState<boolean | undefined>(
    undefined,
  );

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [updateTenantApproval] = useMutation(setTenantPendingApprovalMutation, {
    onError: () => toast.error('Failed to update tenant approval status'),
    optimisticResponse: (data) => ({
      updateTenantsByPk: {
        __typename: 'Tenants' as const,
        id: tenantId,
        isPendingApproval: data.isPendingApproval,
      },
    }),
  });

  const handleSave = () => {
    if (nextPendingState === undefined) {
      return;
    }

    updateTenantApproval({
      variables: {
        tenantId,
        isPendingApproval: nextPendingState,
      },
    });

    setIsConfirmationDialogOpen(false);
    setNextPendingState(undefined);
  };

  const tenant = useCompleteFragment({
    fragment: tenantPendingApprovalFragment,
    key: {
      id: tenantId,
    },
  });

  const cancelApprovalChange = () => {
    setIsConfirmationDialogOpen(false);
    setNextPendingState(undefined);
  };

  return (
    <>
      <Dialog
        open={isConfirmationDialogOpen}
        onOpenChange={(open) => {
          if (open === false) {
            cancelApprovalChange();
          }
        }}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {match(nextPendingState)
                .with(true, () => 'Remove Tenant Approval')
                .otherwise(() => 'Approve Tenant')}
            </DialogTitle>
            <DialogDescription>
              {match(nextPendingState)
                .with(
                  true,
                  () => 'Users will no longer be able to access this tenant.',
                )
                .otherwise(() => 'This tenant will be enabled for its users.')}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={cancelApprovalChange}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Okay</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Switch
        onCheckedChange={(checked) => {
          setNextPendingState(!checked);
          setIsConfirmationDialogOpen(true);
        }}
        checked={!tenant.isPendingApproval}
      />
    </>
  );
};
