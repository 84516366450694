import SparkMD5 from 'spark-md5';

export const calculateFileMd5 = async (file: File) => {
  const arrayBuffer = await file.arrayBuffer();
  const md5 = SparkMD5.ArrayBuffer.hash(arrayBuffer);
  return md5;
};

export const calculateFileSha256 = async (file: File) => {
  const arrayBuffer = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

export const base64Encode = (input: string): string => {
  return Buffer.from(input).toString('base64');
};
