import { Navigate } from 'react-router-dom';
import { toast } from 'sonner';

import { FCC } from '@eluve/components';
import { useSession } from '@eluve/session-helpers';

export const ReviewDashGuard: FCC = ({ children }) => {
  const session = useSession();
  if (!session.isReviewer) {
    toast.error('Access denied');
    return <Navigate to="/" replace />;
  }

  return children;
};
