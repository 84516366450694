import { useSubscription } from '@apollo/client';
import { produce } from 'immer';
import { useMemo } from 'react';

import { cacheUtils } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { useAppointmentId } from './appointment.context';
import {
  appointmentSegmentsFragment,
  appointmentTranscriptAndSegmentsFragment,
} from './operations';

const listenCompletedBatchSegmentsStreamSubscription = graphql(
  `
    subscription listenBatchTranscriptionSegmentStream(
      $tenantId: uuid!
      $appointmentId: uuid!
      $initialValue: timestamptz!
    ) {
      appointmentSegmentsStream(
        where: {
          tenantId: { _eq: $tenantId }
          appointmentId: { _eq: $appointmentId }
          raw_transcript: {
            transcriptType: { _eq: BATCH }
            transcriptionStatus: { _eq: COMPLETED }
          }
        }
        batchSize: 1
        cursor: { initialValue: { updatedAt: $initialValue }, ordering: ASC }
      ) {
        __typename
        id
        transcript
        updatedAt
        recordingDuration
        recordingStartedAt
      }
    }
  `,
  [appointmentTranscriptAndSegmentsFragment],
);

/**
 * Syncs completed batch transcription segments for the appointment.
 */
export const useAppointmentTranscriptionSync = (
  startingTimestamp?: string | null,
) => {
  const tenantId = useTenantIdFromParams();
  const appointmentId = useAppointmentId();

  const initialValue = useMemo(
    () => startingTimestamp ?? new Date(0).toISOString(),
    [startingTimestamp],
  );

  const { data } = useSubscription(
    listenCompletedBatchSegmentsStreamSubscription,
    {
      variables: {
        tenantId: tenantId!,
        appointmentId,
        initialValue,
      },
    },
  );

  if (data?.appointmentSegmentsStream.length === 1) {
    const latestSegment = data.appointmentSegmentsStream[0]!;

    cacheUtils.updateFragment(
      {
        fragment: appointmentSegmentsFragment,
        key: { id: appointmentId },
      },
      (existing) => {
        const existingSegments = existing?.segments ?? [];

        if (existingSegments.some((s) => s.id === latestSegment.id)) {
          return existing;
        }

        return produce(existing, (draft) => {
          draft?.segments.push(latestSegment);
        });
      },
    );
  }
};
