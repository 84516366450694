import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';

import { SubmittablePromptSchema } from '@eluve/api-contract';
import { PageTitle } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { CreatePromptTemplateForm } from './CreatePromptTemplateForm';

const getExistingPromptTemplateQuery = graphql(`
  query getExistingPromptTemplate($id: uuid!) {
    promptTemplatesByPk(id: $id) {
      __typename
      id
      name
      description
      prompt_template_variants(where: { isCurrentDefault: { _eq: true } }) {
        template
      }
      outputType
    }
  }
`);

export const ClonePromptPage: React.FC = () => {
  const { promptTemplateId } = useParams() as { promptTemplateId: string };
  const {
    data: { promptTemplatesByPk: promptTemplate },
  } = useSuspenseQuery(getExistingPromptTemplateQuery, {
    variables: {
      id: promptTemplateId,
    },
  });

  const template = SubmittablePromptSchema.parse(
    promptTemplate?.prompt_template_variants?.[0]?.template,
  );

  return (
    <>
      <PageTitle subtitle={`Cloning from '${promptTemplate?.name}'`}>
        Create Prompt
      </PageTitle>
      <CreatePromptTemplateForm
        initialFormValues={{
          name: `${promptTemplate?.name} (Copy)`,
          description: promptTemplate?.description ?? undefined,
          template,
          outputType: promptTemplate?.outputType,
        }}
      />
    </>
  );
};
