import { LinkButton } from '@eluve/components';

type OpenInAdminProps = {
  appointmentId: string;
  tenantId: string;
};

export const OpenInAdmin: React.FC<OpenInAdminProps> = ({
  appointmentId,
  tenantId,
}) => {
  const url = `/admin/tenants/${tenantId}/appointments/${appointmentId}/details`;

  return (
    <LinkButton
      icon={{ name: 'ShieldCheck' }}
      text="Open in Admin Dashboard"
      to={url}
      type="outline"
    />
  );
};
