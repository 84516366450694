import { useApolloClient, useQuery } from '@apollo/client';
import {
  ArrowRightLeft,
  CalendarSearch,
  CircleHelpIcon,
  LayoutTemplate,
  Settings,
  Users,
} from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useMemo } from 'react';
import { Link, NavLink, Outlet, useParams } from 'react-router-dom';

import { PrivacyToggleProvider } from '@eluve/blocks';
import {
  EluveLogo,
  ReskinLayout,
  Sidebar,
  SidebarNav,
  SidebarNavItem,
  SidebarNavItemProps,
  SidebarNavSpacer,
  tv,
  useSidebarTrigger,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { useUserIdFromSession } from '@eluve/session-helpers';
import {
  EluveAdminOnly,
  FeatureFlaggedComponent,
  useTenantName,
} from '@eluve/smart-blocks';

import { TenantUserSettings } from '../../TenantUserSettings';
import { UserHeaderDropdownMenu } from '../components/UserHeaderDropdownMenu';
import { GetTenantsList } from '../operations';

const navItemVariants = tv({
  base: 'transition-colors duration-200 ease-linear',
  variants: {
    isActive: {
      true: 'border-y-2 bg-surface1',
    },
  },
});

/**
 * Wraps a sidebar nav item so that navigating will also
 * close the drawer and remove the blur
 */
const SidebarNavWithDrawerDismiss: React.FC<SidebarNavItemProps> = (props) => {
  const { setSidebarOpen } = useSidebarTrigger();
  return <SidebarNavItem {...props} onClick={() => setSidebarOpen(false)} />;
};

export const TenantLayout: React.FC = () => {
  const logger = useNamedLogger('TenantLayout');
  const userId = useUserIdFromSession();
  const { tenantId } = useParams() as { tenantId: string };
  const client = useApolloClient();
  const postHog = usePostHog();
  const localStorageKey = `last-tenant-id-for-user-${userId}`;

  const lastTenantId = useMemo(
    () => localStorage.getItem(localStorageKey),
    [localStorageKey],
  );

  useEffect(
    function trackLastAccessedTenantId() {
      if (tenantId !== lastTenantId) {
        localStorage.setItem(localStorageKey, tenantId);
      }
    },
    [tenantId, lastTenantId, client, logger, localStorageKey],
  );

  const { data: userTenants } = useQuery(GetTenantsList);
  const tenantName = useTenantName(tenantId);

  useEffect(() => {
    postHog.group('tenant', tenantId, {
      name: tenantName,
    });
  }, [postHog, tenantId, tenantName]);

  return (
    <ReskinLayout>
      <Sidebar>
        <SidebarNav>
          <Link to={`/tenants/${tenantId}/home`}>
            <SidebarNavWithDrawerDismiss className="mb-2" icon={<EluveLogo />}>
              Eluve Inc.
            </SidebarNavWithDrawerDismiss>
          </Link>
          {(userTenants?.assignedTenantUsers?.length ?? 0) > 1 && (
            <Link to="/tenants">
              <SidebarNavWithDrawerDismiss icon={<ArrowRightLeft />}>
                {tenantName}
              </SidebarNavWithDrawerDismiss>
            </Link>
          )}

          <FeatureFlaggedComponent flag="APPOINTMENTS_VIEW">
            <NavLink
              to={`/tenants/${tenantId}/appointments`}
              className={({ isActive }) => navItemVariants({ isActive })}
            >
              <SidebarNavWithDrawerDismiss icon={<CalendarSearch />}>
                Appointments
              </SidebarNavWithDrawerDismiss>
            </NavLink>
          </FeatureFlaggedComponent>
          <FeatureFlaggedComponent flag="PATIENTS_VIEW">
            <NavLink
              to={`/tenants/${tenantId}/patients`}
              className={({ isActive }) => navItemVariants({ isActive })}
            >
              <SidebarNavWithDrawerDismiss icon={<Users />}>
                Patients
              </SidebarNavWithDrawerDismiss>
            </NavLink>
          </FeatureFlaggedComponent>
          <EluveAdminOnly>
            <NavLink
              to={`/tenants/${tenantId}/output-templates`}
              className={({ isActive }) => navItemVariants({ isActive })}
            >
              <SidebarNavWithDrawerDismiss icon={<LayoutTemplate />}>
                Templates
              </SidebarNavWithDrawerDismiss>
            </NavLink>
          </EluveAdminOnly>
        </SidebarNav>
        <SidebarNavSpacer />
        <NavLink
          to={`/tenants/${tenantId}/settings`}
          className={({ isActive }) => navItemVariants({ isActive })}
        >
          <SidebarNavWithDrawerDismiss icon={<Settings />}>
            Settings
          </SidebarNavWithDrawerDismiss>
        </NavLink>
        <SidebarNav>
          <a
            href="https://eluveinc.notion.site/Eluve-Support-e7de10ae63384ede8e48a062467bee81"
            target="_blank"
            rel="noreferrer"
          >
            <SidebarNavWithDrawerDismiss icon={<CircleHelpIcon />}>
              Support
            </SidebarNavWithDrawerDismiss>
          </a>
          <SidebarNavItem icon={<UserHeaderDropdownMenu />}>
            User
          </SidebarNavItem>
        </SidebarNav>
      </Sidebar>
      <PrivacyToggleProvider>
        <TenantUserSettings>
          <Outlet />
        </TenantUserSettings>
      </PrivacyToggleProvider>
    </ReskinLayout>
  );
};
