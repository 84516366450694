import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'sonner';

import { LanguageSelectorComboBox } from '@eluve/blocks';
import { HStack, VStack, textStyles } from '@eluve/components';
import { DEFAULT_LANGUAGE } from '@eluve/language-utils';
import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import {
  updateInputLanguageMutation,
  updateOutputLanguageMutation,
} from './operations';
import { useTenantUserSettings } from './useTenantUserSettings';

export const LanguageSettings: React.FC = () => {
  const userId = useUserIdFromSession();
  const tenantId = useTenantIdFromParams()!;
  const { settings } = useTenantUserSettings();

  const inputLanguageCode = settings?.inputLanguage ?? DEFAULT_LANGUAGE;
  const outputLanguageCode = settings?.outputLanguage ?? DEFAULT_LANGUAGE;

  const [updateInputLanguage] = useMutation(updateInputLanguageMutation, {
    onError: () => toast.error('Failed to update language'),
  });
  const [updateOutputLanguage] = useMutation(updateOutputLanguageMutation, {
    onError: () => toast.error('Failed to update language'),
  });

  const handleInputLanguageSelected = async (languageCode: string) => {
    await updateInputLanguage({
      variables: {
        userId,
        tenantId,
        inputLanguage: languageCode,
      },
      optimisticResponse: () => ({
        updateTenantUserSettingsByPk: {
          __typename: 'TenantUserSettings',
          userId,
          tenantId,
          inputLanguage: languageCode,
        },
      }),
    });
  };
  const handleOutputLanguageSelected = async (languageCode: string) => {
    await updateOutputLanguage({
      variables: {
        userId,
        tenantId,
        outputLanguage: languageCode,
      },
      optimisticResponse: () => ({
        updateTenantUserSettingsByPk: {
          __typename: 'TenantUserSettings',
          userId,
          tenantId,
          outputLanguage: languageCode,
        },
      }),
    });
  };

  const preferredLanguages = [];
  if (settings?.inputLanguage) {
    preferredLanguages.push(settings.inputLanguage);
  }
  if (settings?.outputLanguage) {
    preferredLanguages.push(settings.outputLanguage);
  }

  return (
    <HStack className="w-full flex-wrap gap-5 md:flex-nowrap">
      <VStack className="gap-2">
        <h4 className={textStyles.body({ size: 's', weight: 'semibold' })}>
          Select the language you speak most in session
        </h4>

        <LanguageSelectorComboBox
          selectButtonStyles="w-full"
          selectedLanguageCode={inputLanguageCode}
          onSelectedLanguageCode={handleInputLanguageSelected}
          preferredLanguages={preferredLanguages}
        />
      </VStack>
      <VStack className="gap-2">
        <h4 className={textStyles.body({ size: 's', weight: 'semibold' })}>
          Select the language you'd like your notes to be in
        </h4>

        <LanguageSelectorComboBox
          selectButtonStyles="w-full"
          selectedLanguageCode={outputLanguageCode}
          onSelectedLanguageCode={handleOutputLanguageSelected}
          preferredLanguages={preferredLanguages}
        />
      </VStack>
    </HStack>
  );
};
