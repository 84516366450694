import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const translationContract = c.router(
  {
    translate: {
      method: 'POST',
      path: '/',
      responses: {
        200: z.object({
          content: z.unknown(),
        }),
      },
      body: z.object({
        inputLanguage: z.string().optional(),
        outputLanguage: z.string(),
        content: z.string(),
      }),
      summary: 'Translates content into desired language',
    },
  },
  {
    pathPrefix: '/translation',
  },
);
