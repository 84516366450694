import { z } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import { VendorTypesEnum } from './types';

type VendorIntegrationTypes = 'API' | 'WEB';

export const VendorTypesLookup = {
  JANE: 'JANE',
  EXPERITY: 'EXPERITY',
  TEBRA: 'TEBRA',
  MOVEMENT_X: 'MOVEMENT_X',
  BLVD: 'BLVD',
  SILOAM: 'SILOAM',
} as const satisfies MatchingRecord<VendorTypesEnum>;

export const supportedVendorsSchema = z.nativeEnum(VendorTypesLookup);

export type SupportedVendors = z.infer<typeof supportedVendorsSchema>;

export const VendorIntegrationMapping: Record<
  VendorTypesEnum,
  VendorIntegrationTypes
> = {
  JANE: 'WEB',
  EXPERITY: 'WEB',
  TEBRA: 'WEB',
  MOVEMENT_X: 'WEB',
  BLVD: 'API',
  SILOAM: 'API',
} as const satisfies Record<VendorTypesEnum, VendorIntegrationTypes>;

export const supportedApiBasedVendorsSchema = z.enum(['BLVD', 'SILOAM']);
export const supportedWebBasedVendorsSchema = z.enum([
  'JANE',
  'EXPERITY',
  'MOVEMENT_X',
  'TEBRA',
]);

export type WebBasedSupportedVendors = z.infer<
  typeof supportedWebBasedVendorsSchema
>;
export type ApiBasedSupportedVendors = z.infer<
  typeof supportedApiBasedVendorsSchema
>;

export const isVendorWebBased = (
  vendor: VendorTypesEnum | string,
): vendor is WebBasedSupportedVendors =>
  VendorIntegrationMapping[vendor as VendorTypesEnum] === 'WEB';

export const isVendorApiBased = (
  vendor: VendorTypesEnum | string,
): vendor is WebBasedSupportedVendors =>
  VendorIntegrationMapping[vendor as VendorTypesEnum] === 'API';
