import { Slot } from '@radix-ui/react-slot';
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { VariantProps } from 'tailwind-variants';

import { OnlyStringKeys } from '@eluve/utils';

import { Icon, IconProps } from './Icon';
import { tv } from './cn';
import { textStyles } from './textStyles';

export const newButtonStyles = tv({
  base: 'inline-flex cursor-pointer select-none items-center justify-center text-nowrap',
  variants: {
    disabled: {
      true: 'cursor-not-allowed',
    },
    iconOnly: {
      true: 'aspect-square !px-0',
    },
    // TODO(jesse)[ELU-2647] - Fix border radius
    size: {
      s: textStyles.body({
        size: 's',
        className: 'gap-1.5 rounded-[6px] px-2',
      }),
      m: textStyles.body({ size: 'm', className: 'gap-2 rounded-[8px] px-3' }),
      l: textStyles.body({ size: 'l', className: 'gap-3 rounded-[10px] px-4' }),
    },
    spacing: {
      default: '',
      compact: '',
    },
    type: {
      primary:
        'bg-brandGray900 text-brandGray100 hover:bg-brandGray800 hover:text-brandGray200',
      subtle:
        'bg-brandGray100 text-brandGray700 hover:bg-brandGray200 hover:text-brandGray800',
      outline:
        'border border-brandGray200 bg-white text-brandGray800 hover:border-brandGray100 hover:bg-white hover:text-brandGray700',
      outlineSubtle:
        'border border-brandGray200 bg-white text-brandGray600 hover:border-brandGray300 hover:bg-white hover:text-brandGray700',
      outlineFilled:
        'border border-brandGray200 bg-brandGray50 text-brandGray600 hover:bg-brandGray100 hover:text-brandGray700',
      ghost: 'text-brandGray800 hover:bg-brandGray100 hover:text-brandGray700',
    },
    wFull: {
      true: 'w-full justify-center',
    },
  },
  compoundVariants: [
    {
      disabled: true,
      type: 'primary',
      className:
        'bg-brandGray50 text-brandGray500 hover:bg-brandGray50 hover:text-brandGray500',
    },
    {
      disabled: true,
      type: 'subtle',
      className:
        'bg-brandGray50 text-brandGray500 hover:bg-brandGray50 hover:text-brandGray500',
    },
    {
      disabled: true,
      type: 'outline',
      className:
        'border-brandGray100 bg-white text-brandGray400 hover:border-brandGray100 hover:bg-white hover:text-brandGray400',
    },
    {
      disabled: true,
      type: 'outlineSubtle',
      className:
        'border-brandGray100 bg-white text-brandGray400 hover:border-brandGray100 hover:bg-white hover:text-brandGray400',
    },
    {
      disabled: true,
      type: 'outlineFilled',
      className:
        'border-brandGray100 bg-brandGray50 text-brandGray500 hover:border-brandGray100 hover:bg-brandGray50 hover:text-brandGray500',
    },
    {
      disabled: true,
      type: 'ghost',
      className:
        'text-brandGray400 hover:bg-transparent hover:text-brandGray400',
    },
    {
      spacing: 'default',
      size: 's',
      className: 'h-[30px] py-1.5',
    },
    {
      spacing: 'default',
      size: 'm',
      className: 'h-[40px] py-2',
    },
    {
      spacing: 'default',
      size: 'l',
      className: 'h-[48px] py-2.5',
    },
    {
      spacing: 'compact',
      size: 's',
      className: 'h-[26px] py-1',
    },
    {
      spacing: 'compact',
      size: 'm',
      className: 'h-[32px] py-1.5',
    },
    {
      spacing: 'compact',
      size: 'l',
      className: 'h-[44px] py-2',
    },
  ],
  defaultVariants: {
    type: 'primary',
    spacing: 'default',
    size: 'm',
  },
});

type ButtonSize = OnlyStringKeys<CoreButtonStyleProps['size']>;
type IconSize = OnlyStringKeys<IconProps['size']>;

const IconSizeByButtonSize: { [key in ButtonSize]: IconSize } = {
  s: 'xxs',
  m: 'xs',
  l: 'sm',
};

// Core

type CoreButtonStyleProps = VariantProps<typeof newButtonStyles>;

type CoreButtonContentProps = {
  icon?: {
    name: IconProps['name'];
    position?: 'left' | 'right';
  };
  size?: CoreButtonStyleProps['size'];
  text?: string;
};

type CoreButtonProps = CoreButtonContentProps & CoreButtonStyleProps;

type CoreButtonPropsWithChildren = React.PropsWithChildren<CoreButtonProps>;

export const CoreButtonContent: React.FC<CoreButtonContentProps> = ({
  icon,
  size = 'm',
  text,
}) => {
  if (icon == null) {
    return text;
  }

  const iconProps = {
    className: '-ml-px',
    name: icon.name,
    size: IconSizeByButtonSize[size],
  };

  if (text == null) {
    return <Icon {...iconProps} />;
  }

  const position = icon.position ?? 'left';
  return (
    <>
      {position === 'left' && <Icon {...iconProps} />}
      {text}
      {position === 'right' && <Icon {...iconProps} />}
    </>
  );
};

const CoreButton = forwardRef<HTMLElement, CoreButtonPropsWithChildren>(
  (
    { children, disabled, icon, size, spacing, text, type, wFull, ...props },
    ref,
  ) => {
    const iconOnly = icon != null && text == null;
    const className = newButtonStyles({
      disabled,
      iconOnly,
      size,
      spacing,
      type,
      wFull,
    });
    return (
      <Slot children={children} className={className} ref={ref} {...props} />
    );
  },
);

// Button

export type NewButtonProps = CoreButtonProps & {
  onClick?: () => void;
  submit?: boolean;
};

export const NewButton = forwardRef<HTMLButtonElement, NewButtonProps>(
  ({ onClick, submit, ...props }, ref) => {
    const { disabled, icon, size, text } = props;
    return (
      <CoreButton ref={ref} {...props}>
        <button
          disabled={disabled}
          onClick={!disabled ? onClick : undefined}
          type={submit ? 'submit' : 'button'}
        >
          <CoreButtonContent icon={icon} size={size} text={text} />
        </button>
      </CoreButton>
    );
  },
);

// Link

type AnchorLinkButtonProps = {
  href: string;
  rel?: string;
  target?: string;
  to?: never;
};

type RouterLinkButtonProps = {
  href?: never;
  rel?: never;
  target?: never;
  to: string;
};

export type LinkButtonProps = CoreButtonProps &
  (AnchorLinkButtonProps | RouterLinkButtonProps);

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  ({ href, rel = 'noreferrer', target = '_blank', to, ...props }, ref) => {
    const { disabled, icon, size, text } = props;

    if (disabled) {
      return (
        <CoreButton ref={ref} {...props}>
          <button disabled type="button">
            <CoreButtonContent icon={icon} size={size} text={text} />
          </button>
        </CoreButton>
      );
    }

    return (
      <CoreButton ref={ref} {...props}>
        {to != null ? (
          <Link to={to}>
            <CoreButtonContent icon={icon} size={size} text={text} />
          </Link>
        ) : (
          <a href={href} rel={rel} target={target}>
            <CoreButtonContent icon={icon} size={size} text={text} />
          </a>
        )}
      </CoreButton>
    );
  },
);
