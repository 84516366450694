import { graphql } from '@eluve/graphql.tada';

export const adminTenantFeatureFlagFragment = graphql(`
  fragment AdminTenantFeatureFlag on TenantFeatureFlags @_unmask {
    __typename
    flag
    isEnabled
    tenantId
    updatedAt
    featureFlag {
      __typename
      name
      description
    }
  }
`);

export const userFragment = graphql(`
  fragment User on Users @_unmask {
    __typename
    id
    email
    firstName
    lastName
  }
`);

export const reviewerFragment = graphql(
  `
    fragment Reviewer on TenantReviewers @_unmask {
      __typename
      tenantId
      userId
      createdAt
      user {
        ...User
      }
    }
  `,
  [userFragment],
);

export const tenantReviewerFragment = graphql(
  `
    fragment TenantReviewer on Tenants @_unmask {
      __typename
      id
      reviewers {
        ...Reviewer
      }
    }
  `,
  [reviewerFragment],
);
