import { graphql } from '@eluve/graphql.tada';

// Fragments

const medicalCodeFragment = graphql(`
  fragment MedicalCode on MedicalCodes @_unmask {
    __typename
    id
    code
    codeType
    description
  }
`);

const billingCodePriceFragment = graphql(
  `
    fragment BillingCodePrice on BillingCodeLocationPrices @_unmask {
      __typename
      tenantId
      locationId
      billingCodeId
      price
      medical_code {
        ...MedicalCode
      }
    }
  `,
  [medicalCodeFragment],
);

export const locationBillingCodePricesFragment = graphql(
  `
    fragment LocationBillingCodePrices on Locations @_unmask {
      __typename
      id
      billing_code_prices {
        ...BillingCodePrice
      }
    }
  `,
  [billingCodePriceFragment],
);

// Queries

export const getBillingCodePrices = graphql(
  `
    query getBillingCodePrices($tenantId: uuid!, $locationId: String!) {
      locationsByPk(id: $locationId, tenantId: $tenantId) {
        ...LocationBillingCodePrices
      }
    }
  `,
  [locationBillingCodePricesFragment],
);

// Mutations

export const addBillingCodePriceMutation = graphql(
  `
    mutation addBillingCodePrice(
      $locationId: String!
      $billingCodeId: uuid!
      $price: Int!
    ) {
      insertBillingCodeLocationPricesOne(
        object: {
          locationId: $locationId
          billingCodeId: $billingCodeId
          price: $price
        }
      ) {
        ...BillingCodePrice
      }
    }
  `,
  [billingCodePriceFragment],
);

export const removeBillingCodePriceMutation = graphql(`
  mutation removeBillingCodePrice(
    $tenantId: uuid!
    $locationId: String!
    $billingCodeId: uuid!
  ) {
    deleteBillingCodeLocationPricesByPk(
      tenantId: $tenantId
      locationId: $locationId
      billingCodeId: $billingCodeId
    ) {
      __typename
      tenantId
      locationId
      billingCodeId
    }
  }
`);

export const updateBillingCodePriceMutation = graphql(`
  mutation updateBillingCodePrice(
    $tenantId: uuid!
    $locationId: String!
    $billingCodeId: uuid!
    $price: Int!
  ) {
    updateBillingCodeLocationPricesByPk(
      pkColumns: {
        tenantId: $tenantId
        locationId: $locationId
        billingCodeId: $billingCodeId
      }
      _set: { price: $price }
    ) {
      __typename
      tenantId
      locationId
      billingCodeId
      price
    }
  }
`);
