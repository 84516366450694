import { useSuspenseQuery } from '@apollo/client';
import React from 'react';

import { VStack } from '@eluve/components';
import { H3 } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { AddTenantReviewer } from './components/AddTenantReviewer';
import { TenantReviewersList } from './components/TenantReviewersList';
import { tenantReviewerFragment } from './components/gql-operations';

const getTenantReviewersQuery = graphql(
  `
    query getTenantReviewers($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        ...TenantReviewer
      }
    }
  `,
  [tenantReviewerFragment],
);

export const AdminTenantReviewersPage: React.FC = () => {
  const tenantId = useTenantIdFromParams()!;

  useSuspenseQuery(getTenantReviewersQuery, {
    variables: {
      tenantId,
    },
  });

  return (
    <VStack gap={6}>
      <H3>Reviewers</H3>

      <AddTenantReviewer />
      <TenantReviewersList />
    </VStack>
  );
};
