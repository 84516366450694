import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

const TenantSessionSchema = z.object({});

const AuthAppTypeSchema = z.enum(['mobile', 'web']);
export type AuthAppType = z.infer<typeof AuthAppTypeSchema>;

const TryInitiateLoginResultSchema = z.union([
  z.object({
    type: z.literal('saml'),
    redirectUrl: z.string().url(),
  }),
  z.object({
    type: z.literal('social'),
    thirdPartyId: z.string(),
  }),
  z.object({
    type: z.literal('email'),
    isExistingUser: z.boolean(),
  }),
]);

export type TryInitiateLoginResult = z.infer<
  typeof TryInitiateLoginResultSchema
>;

export const authContract = c.router(
  {
    tryInitiateLogin: {
      method: 'POST',
      path: '/initiate-login',
      responses: {
        200: TryInitiateLoginResultSchema,
      },
      body: z.object({
        appType: AuthAppTypeSchema,
        email: z.string().email(),
      }),
    },
    createTenantSession: {
      method: 'POST',
      path: '/switch-tenant',
      responses: {
        201: TenantSessionSchema,
        403: z.object({
          message: z.string(),
        }),
      },
      body: z.object({
        tenantId: z.string().optional(),
      }),
      summary:
        'Attempts to create a session for a user scoped to a specified tenant',
    },
    refreshAllowedTenants: {
      method: 'POST',
      path: '/refresh-allowed-tenants',
      responses: {
        201: TenantSessionSchema,
        403: z.object({
          message: z.string(),
        }),
      },
      body: z.object({}),
      summary:
        'Refreshes the list of tenants that the user has access to and returns a new session',
    },
    attemptPartnerLogin: {
      method: 'POST',
      path: '/attempt-partner-login',
      responses: {
        200: z.object({
          redirectUrl: z.string().url().optional(),
        }),
      },
      body: z.object({
        appType: AuthAppTypeSchema,
        email: z.string().email(),
      }),
      summary:
        'Attempts to sign in with a white listed partner using an enterprise SSO flow',
    },
    sso: {
      method: 'POST',
      path: '/sso',
      responses: {
        201: TenantSessionSchema,
      },
      body: z.object({
        code: z.string(),
        state: z.string(),
      }),
    },
  },
  {
    pathPrefix: '/auths',
  },
);
