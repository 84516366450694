import { EyeClosedIcon } from '@radix-ui/react-icons';
import { Column, ColumnDef } from '@tanstack/react-table';
import clsx from 'clsx';
import capitalize from 'lodash/capitalize';
import {
  ArrowBigRight,
  ArrowDown,
  ArrowUp,
  ArrowUpDown,
  Check,
} from 'lucide-react';
import { EyeIcon } from 'lucide-react';
import { match } from 'ts-pattern';
import { create } from 'zustand';

import { formatToLocale } from '@eluve/date-utils';
import { OnlyStringKeys } from '@eluve/utils';

import { Box } from './box';
import { Button } from './button';
import { Link } from './link';
import { P } from './typography';

interface SortableHeaderProps<T> {
  column: Column<T>;
  label: React.ReactNode;
}

type ColArgs<T> = {
  label?: string;
  cellRenderer?: (row: T) => React.ReactNode;
  colOptions?: Partial<ColumnDef<T>>;
  isPrivate?: boolean;
};
export const SortableColumnHeader = <T,>(props: SortableHeaderProps<T>) => {
  const { column, label } = props;
  const sorted = column.getIsSorted();

  return (
    <Button
      variant="link"
      className="whitespace-nowrap px-0 text-gray-10 hover:text-gray-11"
      onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
    >
      {label}
      {sorted === false && <ArrowUpDown className="ml-2 h-4 w-4" />}
      {sorted === 'asc' && <ArrowUp className="ml-2 h-4 w-4" />}
      {sorted === 'desc' && <ArrowDown className="ml-2 h-4 w-4" />}
    </Button>
  );
};

/**
 * A utility to create a simple sortable column definition
 * which will render the column value as is
 */
export const makeDefaultSortableColumnDef = <T extends Record<string, any>>(
  col: OnlyStringKeys<keyof T>,
  optionsOrLabel?: string | ColArgs<T>,
) => {
  const options =
    typeof optionsOrLabel === 'string'
      ? { label: optionsOrLabel }
      : optionsOrLabel;

  const { label, cellRenderer, colOptions, isPrivate } = options ?? {};
  const colDef: ColumnDef<T> = {
    ...colOptions,
    accessorKey: col,
    sortUndefined: -1,
    header: ({ column }) => (
      <SortableColumnHeader column={column} label={label ?? capitalize(col)} />
    ),
    cell: ({ row }) => {
      if (cellRenderer) {
        return cellRenderer(row.original);
      }

      const val =
        typeof row.original[col] === 'boolean'
          ? `${row.original[col]}`
          : row.original[col];
      return <P className={clsx({ 'privacy-text': isPrivate })}>{val}</P>;
    },
  };

  return colDef;
};

/**
 * A utility to create a simple sortable column definition
 * which will render the column value as is
 */
export const makeDefaultSortableLinkDef = <T extends Record<string, any>>(
  col: OnlyStringKeys<keyof T>,
  linkFn: (row: T) => string,
  label?: string,
) => {
  const colDef: ColumnDef<T> = {
    accessorKey: col,
    header: ({ column }) => (
      <SortableColumnHeader column={column} label={label ?? capitalize(col)} />
    ),
    cell: ({ row }) => {
      return <Link to={linkFn(row.original)}>{row.original[col]}</Link>;
    },
  };

  return colDef;
};

const makeDetailsLinkColumnDef = <T extends Record<string, any>>(
  linkFn: (row: T) => string,
  label = 'Details',
) => {
  const colDef: ColumnDef<T> = {
    header: label,
    cell: ({ row }) => {
      return (
        <Link to={linkFn(row.original)}>
          <Button variant="outline" size="icon">
            <ArrowBigRight />
          </Button>
        </Link>
      );
    },
  };

  return colDef;
};

/**
 * A utility for creating a sortable column definition for a date column
 * This takes care of consistent formatting of the date as well as sane
 * sorting behavior
 */
export const makeSortableDateColumnDef = <T extends Record<string, any>>(
  col: OnlyStringKeys<keyof T>,
  optionsOrLabel?: string | Omit<ColArgs<T>, 'cellRenderer'>,
) => {
  const options =
    typeof optionsOrLabel === 'string'
      ? { label: optionsOrLabel }
      : optionsOrLabel;

  const { label, colOptions, isPrivate } = options ?? {};

  const colDef: ColumnDef<T> = {
    ...colOptions,
    accessorKey: col,
    accessorFn: (row) => {
      // Convert null to undefined so that we can benefit from the sortUndefined behavior below
      return row[col] ?? undefined;
    },
    sortUndefined: -1,
    header: ({ column }) => (
      <SortableColumnHeader column={column} label={label ?? capitalize(col)} />
    ),
    cell: ({ row }) => {
      const val = row.original[col];
      if (!val) {
        return null;
      }
      return (
        <P className={clsx({ 'privacy-text': isPrivate })}>
          {formatToLocale(row.original[col])}
        </P>
      );
    },
  };

  return colDef;
};

/**
 * A utility for creating a column definition that has a visibility toggle
 * On click of the toggle button, the column will switch between the
 * visible `cell` renderer and the `hiddenCell` renderer
 * @param colDef Additional column definition properties
 * @returns ColumnDef with visibility toggle functionality on the header
 */
export const makeVisibilityToggleColumnDef = <T,>(
  colDef: ColumnDef<T> & {
    hiddenCell: ColumnDef<T>['cell'];
  },
): ColumnDef<T> => {
  const useVisibility = create(() => false);
  const {
    hiddenCell: hiddenCellRender,
    cell: visibleCellRender,
    header,
  } = colDef;

  return {
    ...colDef,
    header: (...args) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const visibility = useVisibility();
      const headerContent =
        typeof header === 'function' ? header(...args) : header;

      return (
        <Box hStack>
          <Button
            onClick={() => useVisibility.setState((v) => !v)}
            size="icon"
            variant="link"
            className="text-gray-11"
          >
            {visibility ? (
              <EyeClosedIcon className="h-5 w-5" />
            ) : (
              <EyeIcon className="h-5 w-5" />
            )}
          </Button>
          {headerContent}
        </Box>
      );
    },
    cell: (...args) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const visibility = useVisibility();

      if (visibility) {
        return typeof visibleCellRender === 'function'
          ? visibleCellRender(...args)
          : visibleCellRender;
      }

      return typeof hiddenCellRender === 'function'
        ? hiddenCellRender(...args)
        : hiddenCellRender;
    },
  } as ColumnDef<T>;
};

export class ColDefBuilder<T extends Record<string, any>> {
  private readonly colDefs: ColumnDef<T>[] = [];

  /**
   * Adds a default sortable column. This will simply render the column value
   */
  defaultSortable<K extends OnlyStringKeys<keyof T>>(
    col: K,
    label?: string,
  ): ColDefBuilder<T>;

  defaultSortable<K extends OnlyStringKeys<keyof T>>(
    col: K,
    options?: ColArgs<T>,
  ): ColDefBuilder<T>;

  defaultSortable<K extends OnlyStringKeys<keyof T>>(
    col: K,
    labelOrOptions?: string | ColArgs<T>,
  ) {
    if (typeof labelOrOptions === 'string') {
      this.colDefs.push(
        makeDefaultSortableColumnDef(col, { label: labelOrOptions }),
      );
    } else {
      this.colDefs.push(makeDefaultSortableColumnDef(col, labelOrOptions));
    }

    return this;
  }

  /**
   * Adds a default sortable column. This will simply render the column value
   */
  defaultBoolean<K extends OnlyStringKeys<keyof T>>(col: K, label?: string) {
    this.colDefs.push(
      makeDefaultSortableColumnDef(col, {
        label,
        cellRenderer: (row) =>
          match(row[col] as boolean)
            .with(true, () => <Check className="text-brand-9" />)
            .otherwise(() => '--'),
      }),
    );
    return this;
  }

  /**
   * Adds a sortable column that renders a link.
   */
  linkSortable<K extends OnlyStringKeys<keyof T>>(
    col: K,
    linkFn: (row: T) => string,
    label?: string,
  ) {
    this.colDefs.push(makeDefaultSortableLinkDef(col, linkFn, label));
    return this;
  }

  detailsLink(linkFn: (row: T) => string, label?: string) {
    this.colDefs.push(makeDetailsLinkColumnDef(linkFn, label));
    return this;
  }

  /**
   * Adds a sortable date column which will format the date automatically
   * and use sane sorting behavior for undefined dates
   */

  dateSortable<K extends OnlyStringKeys<keyof T>>(
    col: K,
    labelOrOptions?: string | Omit<ColArgs<T>, 'cellRenderer'>,
  ) {
    const options =
      typeof labelOrOptions === 'string'
        ? { label: labelOrOptions }
        : labelOrOptions;

    this.colDefs.push(makeSortableDateColumnDef(col, options));
    return this;
  }

  /**
   * Adds a custom column definition
   */
  colDef(colDef: ColumnDef<T>) {
    this.colDefs.push(colDef);
    return this;
  }

  /**
   * Returns the column definitions to be used with a DataGrid
   */
  build() {
    return this.colDefs;
  }
}
