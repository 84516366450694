import { useSuspenseQuery } from '@apollo/client';
import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { VStack } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { TabNav } from '@eluve/smart-blocks';

export const appointmentArtifactsFragment = graphql(`
  fragment appointmentArtifacts on Appointments @_unmask {
    __typename
    id
    transcription {
      __typename
      id
      transcript
    }
    doctor_interaction {
      __typename
      appointmentId
      noteSignedAt
      additionalNotes
      externalChartUrl
      externalEhrSyncStatus
    }
    external_charts {
      __typename
      id
      rawData
      external_ehr {
        __typename
        id
        vendor
        domain
      }
    }
    humanOutputs(
      where: { output: { outputType: { _eq: SOAP_NOTE } } }
      limit: 1
      orderBy: { output: { createdAt: DESC } }
    ) {
      __typename
      humanOutputId
      output {
        __typename
        id
        content
        userId
        llm_output {
          __typename
          id
          content
          outputTemplate {
            __typename
            id
            name
            dynamicArtifactTemplate
          }
          modelPromptTemplate {
            __typename
            id
            template {
              __typename
              id
              name
            }
          }
        }
      }
    }
  }
`);

const appointmentArtifactsQuery = graphql(
  `
    query appointmentArtifacts($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...appointmentArtifacts
      }
    }
  `,
  [appointmentArtifactsFragment],
);

export const AdminAppointmentArtifactsOutlet: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  useSuspenseQuery(appointmentArtifactsQuery, {
    variables: { tenantId, appointmentId },
  });

  return (
    <VStack justify="center" align="center">
      <TabNav
        tabs={[
          {
            name: 'Summary',
            link: 'summary',
          },
          {
            name: 'Doctor Draft',
            link: 'draft',
          },
          {
            name: 'Summary Diff',
            link: 'summary-diff',
          },
          {
            name: 'Transcript',
            link: 'transcript',
          },
          {
            name: 'EHR Diff',
            link: 'ehr-diff',
          },
        ]}
      />
      <Suspense fallback={'Loading...'}>
        <Outlet />
      </Suspense>
    </VStack>
  );
};
