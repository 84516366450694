import { graphql } from '@eluve/graphql.tada';

// Mutations

export const addAppointmentBillingCodeLinkMutation = graphql(`
  mutation insertAppointmentBillingCodeLink(
    $appointmentId: uuid!
    $sourceBillingCodeId: uuid!
    $targetBillingCodeId: uuid!
  ) {
    insertAppointmentBillingCodeLinksOne(
      object: {
        appointmentId: $appointmentId
        sourceAppointmentBillingCodeId: $sourceBillingCodeId
        targetAppointmentBillingCodeId: $targetBillingCodeId
      }
    ) {
      __typename
      id
      sourceAppointmentBillingCodeId
      targetAppointmentBillingCodeId
    }
  }
`);

export const removeAppointmentBillingCodeLinkMutation = graphql(`
  mutation removeAppointmentBillingCodeLink(
    $tenantId: uuid!
    $appointmentId: uuid!
    $sourceBillingCodeId: uuid!
    $targetBillingCodeId: uuid!
  ) {
    deleteAppointmentBillingCodeLinks(
      where: {
        tenantId: { _eq: $tenantId }
        appointmentId: { _eq: $appointmentId }
        sourceAppointmentBillingCodeId: { _eq: $sourceBillingCodeId }
        targetAppointmentBillingCodeId: { _eq: $targetBillingCodeId }
      }
    ) {
      returning {
        __typename
        id
        sourceAppointmentBillingCodeId
        targetAppointmentBillingCodeId
      }
    }
  }
`);

export const updateAppointmentBillingCodePriceMutation = graphql(`
  mutation updateAppointmentBillingCodePrice(
    $billingCodeId: uuid!
    $price: Int!
  ) {
    updateAppointmentBillingCodesByPk(
      pkColumns: { id: $billingCodeId }
      _set: { price: $price }
    ) {
      __typename
      id
      price
    }
  }
`);

export const updateAppointmentBillingCodeQuantityMutation = graphql(`
  mutation updateAppointmentBillingCodeQuantity(
    $billingCodeId: uuid!
    $quantity: Int!
  ) {
    updateAppointmentBillingCodesByPk(
      pkColumns: { id: $billingCodeId }
      _set: { quantity: $quantity }
    ) {
      __typename
      id
      quantity
    }
  }
`);
