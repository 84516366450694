import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { produce } from 'immer';
import React, { useEffect } from 'react';
import { toast } from 'sonner';
import { v4 } from 'uuid';

import { cacheUtils } from '@eluve/apollo-client';
import { MedicalCode } from '@eluve/blocks';
import { H4, HStack, Icon, VStack, textStyles } from '@eluve/components';
import { FragmentOf } from '@eluve/graphql.tada';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';

import { AppointmentBillingCodePrices } from './AppointmentBillingCodePrices';
import { updateAppointmentBillingCodePriceMutation } from './AppointmentBillingCodePrices.operations';
import {
  acceptBillingCodeRecommendationMutation,
  addAppointmentBillingCodeMutation,
  appointmentBillingCodesFragment,
  getAppointmentBillingCodePrice,
  getAppointmentBillingCodes,
  medicalCodeFragment,
  removeBillingCodeMutation,
} from './AppointmentBillingCodes.operations';
import {
  BillingCodeOption,
  SearchableBillingCodes,
} from './SearchableBillingCodes';

type MedicalCodeFragment = FragmentOf<typeof medicalCodeFragment>;

export interface AppointmentBillingCodesProps {
  tenantId: string;
  appointmentId: string;
  isReadonly?: boolean;
  shouldPollForResults?: boolean;
  locationId: string;
}

export const AppointmentBillingCodes: React.FC<
  AppointmentBillingCodesProps
> = ({
  tenantId,
  appointmentId,
  isReadonly = false,
  shouldPollForResults = false,
  locationId,
}) => {
  const { data, loading, startPolling, stopPolling } = useQuery(
    getAppointmentBillingCodes,
    {
      variables: {
        tenantId,
        appointmentId,
      },
    },
  );

  const billingCodes = data?.appointmentsByPk?.billingCodes ?? [];

  const recommendations =
    data?.appointmentsByPk?.billing_code_recommendations ?? [];

  const codeRecommendationsToShow =
    recommendations.filter((c) => !c.isFinal && Boolean(c.medical_code)) ?? [];

  useEffect(() => {
    if (shouldPollForResults && !isReadonly) {
      startPolling(2500);
      return () => {
        stopPolling();
      };
    }
  }, [shouldPollForResults, isReadonly, startPolling, stopPolling]);

  useEffect(() => {
    if (recommendations.length || billingCodes.length) {
      stopPolling();
    }
  }, [recommendations.length, billingCodes.length, stopPolling]);

  const [getBillingCodePrice] = useLazyQuery(getAppointmentBillingCodePrice);

  const [updateAppointmentBillingCodePrice] = useMutation(
    updateAppointmentBillingCodePriceMutation,
  );

  const setInheritedBillingCodePrice = async (
    appointmentBillingCodeId: string,
    billingCodeId: string,
    type: BillingCodeOption['codeType'],
  ) => {
    if (type !== 'CPT') {
      return;
    }

    const result = await getBillingCodePrice({
      variables: { billingCodeId, locationId },
    });
    const price =
      result.data?.inheritedBillingCodeLocationPrices[0]?.price ?? 0;

    updateAppointmentBillingCodePrice({
      variables: {
        billingCodeId: appointmentBillingCodeId,
        price,
      },
      onError: () => toast.error('We could not set the price.'),
      optimisticResponse: ({ billingCodeId: id, price }) => {
        return {
          updateAppointmentBillingCodesByPk: {
            __typename: 'AppointmentBillingCodes' as const,
            id,
            price,
          },
        };
      },
    });
  };

  const [acceptRecommendation] = useMutation(
    acceptBillingCodeRecommendationMutation,
    {
      onError: () => toast.error('Failed to accept recommendation'),
      optimisticResponse: ({ appointmentId, billingCodeId }) => ({
        insertAppointmentBillingCodesOne: {
          __typename: 'AppointmentBillingCodes' as const,
          id: v4(),
        },
        updateAppointmentBillingCodeRecommendations: {
          returning: [
            {
              __typename: 'AppointmentBillingCodeRecommendations' as const,
              appointmentId,
              codeId: billingCodeId,
              isFinal: true,
            },
          ],
        },
      }),
    },
  );

  const [removeBillingCode] = useMutation(removeBillingCodeMutation, {
    onError: () => toast.error('Failed to remove billing code'),
  });

  const [addBillingCode] = useMutation(addAppointmentBillingCodeMutation, {
    onError: () => toast.error('Failed to add billing code'),
    optimisticResponse: () => ({
      insertAppointmentBillingCodesOne: {
        __typename: 'AppointmentBillingCodes' as const,
        id: v4(),
      },
    }),
  });

  const onRemoveBillingCode = (codeId: string) => {
    if (isReadonly) {
      return;
    }

    removeBillingCode({
      variables: {
        appointmentId,
        billingCodeId: codeId,
      },
      optimisticResponse: ({ appointmentId, billingCodeId }) => {
        return {
          deleteAppointmentBillingCodes: {
            returning: [
              {
                __typename: 'AppointmentBillingCodes' as const,
                billingCodeId,
              },
            ],
          },
          updateAppointmentBillingCodeRecommendations: {
            returning: [
              {
                __typename: 'AppointmentBillingCodeRecommendations' as const,
                appointmentId,
                codeId: billingCodeId,
                isFinal: false,
              },
            ],
          },
        };
      },
      update: (_cache) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }
            // Optimistically remove the billing code from the list
            return produce(existing, (draft) => {
              const idx = draft.billingCodes.findIndex(
                (c) => c.medical_code.id === codeId,
              );
              if (idx !== -1) {
                draft.billingCodes.splice(idx, 1);
              }
            });
          },
        );
      },
    });
  };

  const onAddBillingCode = async (code: BillingCodeOption) => {
    if (isReadonly) {
      return;
    }

    addBillingCode({
      variables: {
        appointmentId,
        billingCodeId: code.id,
        price: null,
        quantity: 1,
      },
      onCompleted(data) {
        const id = data.insertAppointmentBillingCodesOne?.id;
        if (!id) {
          return;
        }
        setInheritedBillingCodePrice(id, code.id, code.codeType);
      },
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            const updatedId = data?.insertAppointmentBillingCodesOne?.id;
            if (!existing || !updatedId) {
              return existing;
            }

            // Optimistically add the billing code to the list of accepted billing codes
            return produce(existing, (draft) => {
              draft.billingCodes.push({
                __typename: 'AppointmentBillingCodes',
                id: updatedId,
                price: null,
                quantity: 1,
                linked_codes: [],
                medical_code: {
                  __typename: 'MedicalCodes',
                  ...code,
                },
              });
            });
          },
        );
      },
    });
  };

  const onAcceptRecommendation = async ({
    code: { __typename, ...medicalCode },
  }: {
    code: MedicalCodeFragment;
  }) => {
    if (isReadonly) {
      return;
    }

    acceptRecommendation({
      variables: {
        appointmentId,
        billingCodeId: medicalCode.id,
        price: null,
        quantity: 1,
      },
      onCompleted(data) {
        const id = data.insertAppointmentBillingCodesOne?.id;
        if (!id) {
          return;
        }
        setInheritedBillingCodePrice(id, medicalCode.id, medicalCode.codeType);
      },
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            const updatedId = data?.insertAppointmentBillingCodesOne?.id;
            if (!existing || !updatedId) {
              return existing;
            }

            // Optimistically add the billing code to the list of accepted billing codes
            return produce(existing, (draft) => {
              draft.billingCodes.push({
                __typename: 'AppointmentBillingCodes',
                id: updatedId,
                price: null,
                quantity: 1,
                linked_codes: [],
                medical_code: {
                  __typename: 'MedicalCodes',
                  ...medicalCode,
                },
              });
            });
          },
        );
      },
    });
  };

  if (loading) {
    return null;
  }

  if (isReadonly && billingCodes.length === 0) {
    return null;
  }

  return (
    <VStack gap={4} className="mb-10">
      <HStack gap={3}>
        <H4>Billing Codes</H4>
      </HStack>
      <VStack gap={6}>
        <HStack>
          <SearchableBillingCodes
            codeTypes={['CPT', 'ICD_10']}
            disabled={isReadonly}
            onCodeAdded={onAddBillingCode}
            onCodeRemoved={(code) => onRemoveBillingCode(code.id)}
            selectedCodes={billingCodes.map((c) => c.medical_code)}
          />
        </HStack>
        {Boolean(codeRecommendationsToShow.length && !isReadonly) && (
          <VStack gap={3}>
            <h6
              className={textStyles.body({
                weight: 'semibold',
                size: 's',
                className: 'uppercase text-brandGray600',
              })}
            >
              Recommended Codes
            </h6>
            <HStack gap={3} wrap>
              {codeRecommendationsToShow.map(({ medical_code }) => {
                const {
                  id: codeId,
                  code,
                  codeType,
                  description,
                } = medical_code!;
                return (
                  <MedicalCode
                    onClick={() => {
                      onAcceptRecommendation({
                        code: medical_code!,
                      });
                    }}
                    startAdornment={
                      <div className="pl-1">
                        <Icon
                          size="xs"
                          name="Plus"
                          className="text-brandGray600"
                        />
                      </div>
                    }
                    key={codeId}
                    code={code}
                    codeType={codeType}
                    description={description ?? ''}
                    type="outlineDashed"
                  />
                );
              })}
            </HStack>
          </VStack>
        )}
        <FeatureFlaggedComponent flag="BILLING_CODE_PRICES">
          <AppointmentBillingCodePrices
            appointmentId={appointmentId}
            isReadonly={isReadonly}
            tenantId={tenantId}
          />
        </FeatureFlaggedComponent>
      </VStack>
    </VStack>
  );
};
